import React from 'react';

import Stats from '../../framework-components/stats/Stats';

const StatsExample = () => {
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Stats</h2>
      <Stats
        stats={[
          { label: 'Creators on the platform', value: '8,000+' },
          { label: 'Flat platform fee', value: '3%' },
          { label: 'Uptime guarantee', value: '99.9%' },
          { label: 'Paid out to creators', value: '$70M' },
        ]}
      />
    </div>
  );
};

export default StatsExample;
