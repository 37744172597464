import React, { useCallback } from 'react';
import {
  faCircleCheck,
  faClock,
  faPaperPlane,
  faXmarkCircle,
  faPlane,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import {
  BookingCount,
  ConfirmedBookingTableData,
  ConfirmedBookingTableDataBalanceStatus,
} from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../../utils/dates';
import {
  CombinedFilters,
  TableData,
} from '../../../../../framework-components/table/types';
import ProgressTracker from '../../applicant-profile/ProgressTracker';

const ConfirmedBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getConfirmBookingTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<ConfirmedBookingTableData>>(
        '/api/admin/bookings/confirmed-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback(() => {
    return fetch('/api/admin/bookings/confirmed-bookings-filters/')
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  const balanceStatusToIcon = (
    balanceStatus: ConfirmedBookingTableDataBalanceStatus
  ) => {
    switch (balanceStatus) {
      case ConfirmedBookingTableDataBalanceStatus.PENDING:
        return (
          <FontAwesomeIcon
            icon={faClock}
            className="text-wtw-balance-pending text-lg"
          />
        );
      case ConfirmedBookingTableDataBalanceStatus.OVERDUE:
        return (
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className="text-wtw-balance-overdue text-lg"
          />
        );
      case ConfirmedBookingTableDataBalanceStatus.COMPLETE:
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-wtw-balance-complete text-lg"
          />
        );
      default:
        return null;
    }
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: null,
        },
        {
          label: 'Confirmed',
          href: null,
        },
      ]}
      title="Confirmed"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: false,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: true,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />

        <Table<ConfirmedBookingTableData>
          columnsConfig={{
            applicant: {
              key: 'applicant',
              header: 'Name',
              enableSort: true,
              renderCell: (request) => (
                <a
                  href={`/admin/bookings/${request.id}/applicant-profile/`}
                  title={request.applicant}
                >
                  {request.applicant}
                </a>
              ),
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              filterType: 'remote-dropdown',
              remoteDropDownDataURL: '/api/admin/options/destination/',
              renderCell: (request) => request.destination.name,
            },
            discipline: {
              key: 'discipline',
              header: 'Discipline',
              enableSort: true,
              filterType: 'remote-dropdown',
              remoteDropDownDataURL: '/api/admin/options/discipline/',
              renderCell: (request) => request.discipline.name,
            },
            start_date: {
              key: 'start_date',
              header: 'Arrival Date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => (
                <div>
                  {formatDateToLongDateFormatWithOridinalDay(
                    request.start_date
                  )}
                  {request.start_conflict ? (
                    <FontAwesomeIcon icon={faPlane} />
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            end_date: {
              key: 'end_date',
              header: 'Dept. date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => (
                <div>
                  {formatDateToLongDateFormatWithOridinalDay(request.end_date)}
                  {request.end_conflict ? (
                    <FontAwesomeIcon icon={faPlane} />
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            balance_status: {
              key: 'balance_status',
              header: 'Balance',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => (
                <div className="text-center">
                  {balanceStatusToIcon(request.balance_status)}
                </div>
              ),
            },
            flags: {
              key: 'flags',
              header: 'Flags',
              renderCell: (booking) => (
                <ProgressTracker trackingData={booking.flags} variant="small" />
              ),
            },
            last_reminder_sent_at: {
              key: 'last_reminder_sent_at',
              header: 'Last reminder sent',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) =>
                request.last_reminder_sent_at &&
                formatDateToLongDateFormatWithOridinalDay(
                  request.last_reminder_sent_at
                ),
            },
            send_reminders: {
              key: 'send_reminders',
              header: 'Send reminders',
              renderCell: () => (
                <div className="text-center">
                  <button
                    className="text-wtw-logo-blue"
                    onClick={() => alert('Not yet implemented')}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              ),
            },
          }}
          getData={getConfirmBookingTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            is_active: {
              key: 'is_active',
              label: 'Active/Archived',
              filterType: 'local-dropdown',
            },
            year: {
              key: 'year',
              label: 'Year',
              filterType: 'local-dropdown',
            },
            month: {
              key: 'month',
              label: 'Month',
              filterType: 'local-dropdown',
            },
          }}
        />
      </div>
    </AdminStandardPage>
  );
};

export default ConfirmedBookings;
