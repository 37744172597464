import { QueryParamConfig } from 'use-query-params';

import { Ordering, SelectedFilters } from './types';

export const orderingToString = (orderingValue: Ordering | null) => {
  if (!orderingValue) {
    return '';
  }

  return orderingValue.direction === 'asc'
    ? orderingValue.name
    : `-${orderingValue.name}`;
};

export const orderingStringToObject = (orderingString: string): Ordering => {
  if (orderingString.startsWith('-')) {
    return {
      name: orderingString.split('-')[1],
      direction: 'desc',
    };
  }

  return {
    name: orderingString,
    direction: 'asc',
  };
};

export const ObjectAsURLEncodedParam: QueryParamConfig<SelectedFilters> = {
  encode: (selectedFilters: SelectedFilters) => {
    const json = encodeURI(JSON.stringify(selectedFilters));

    return json;
  },
  decode: (urlParams: string | (string | null)[] | null | undefined) => {
    try {
      const json = JSON.parse(decodeURI(urlParams as string));
      return json;
    } catch (e) {
      return {};
    }
  },
  default: {},
};

export const OrderingParam: QueryParamConfig<Ordering | null> = {
  encode: (param: Ordering | null) => {
    return orderingToString(param);
  },
  decode: (urlParams: string | (string | null)[] | null | undefined) => {
    try {
      return orderingStringToObject(urlParams as string);
    } catch (e) {
      return null;
    }
  },
  default: null,
};
