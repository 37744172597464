import React from 'react';

import BlockTabs from '../blocktabs/BlockTabs';
import { BlockTabVariant } from '../blocktabs/types';
import Card from '../card/Card';

type TabbedWidgetConfig = {
  title: string;
  content: React.ReactNode;
  showWarningIcon?: boolean;
};

interface Props {
  tabs: Array<TabbedWidgetConfig>;
  variant?: BlockTabVariant;
}

const TabbedWidget: React.FC<Props> = ({
  tabs = [],
  variant = 'underlined',
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div>
      <BlockTabs
        tabs={tabs.map((tab, index) => ({
          label: tab.title,
          current: index === activeTab,
          onClick: () => setActiveTab(index),
          showWarningIcon: tab.showWarningIcon,
        }))}
        variant={variant}
      />
      <Card className="p-2xl space-y-7 bg-wtw-gray-2 rounded-lg border border-wtw-gray-5">
        {tabs[activeTab].content}
      </Card>
    </div>
  );
};

export default TabbedWidget;
