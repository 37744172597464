import { Image } from '../../common/ImageGallery/types';
import { images } from './images';
import {
  OccupancyDetails,
  OtherNotes,
  PublicDescription,
  QuickFacts,
  Room,
} from './types';

// TODO: Create actions that call API to get images, set main image, and delete image
export const getImages: () => Promise<Image[]> = async () => {
  return Promise.resolve(images);
};

export const setMainImage = (imageId: string): Promise<Image[]> => {
  return Promise.resolve(
    images.map((image) => ({
      ...image,
      isMain: image.id === imageId,
    }))
  );
};

export const deleteImage = (imageId: string): Promise<Image[]> => {
  const image = images.find((img) => img.id === imageId);
  if (!image) {
    return Promise.resolve(images);
  }
  const imageIdx = images.indexOf(image);
  images.splice(imageIdx, 1);
  return Promise.resolve(images.filter((img) => img.id !== imageId));
};

export const updateOccupancyDetails = (occupanyDetails: OccupancyDetails) => {
  return Promise.resolve(occupanyDetails);
};

export const updateQuickFacts = (quickFacts: QuickFacts) => {
  return Promise.resolve(quickFacts);
};

export const updatePublicDescription = (description: PublicDescription) => {
  return Promise.resolve(description);
};

export const updateOtherNotes = (notes: OtherNotes) => {
  return Promise.resolve(notes);
};

export const updateRoom = (room: Room) => {
  return Promise.resolve(room);
};

export const addRoom = (room: Room) => {
  return Promise.resolve(room);
};

export const deleteRoom = (room: Room) => {
  return Promise.resolve(room);
};
