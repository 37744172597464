import React from 'react';
import classNames from 'classnames';
import { useDroppable } from '@dnd-kit/core';

import { CalendarSection, Event, Droppable } from './types';
import { getPartiallyVisibleEvent } from '../admin/capacities/utils';
import EventOverlay from './EventOverlay';

interface Props<T, U> {
  isEditing: boolean;
  parentSectionId: string;
  events: ReadonlyArray<Event<T>>;
  section: CalendarSection<U>;
  date: Date;
  rowIndex: number;
  getEventForDay: (
    section: CalendarSection<U>,
    date: Date,
    events: ReadonlyArray<Event<T>>,
    rowIndex: number
  ) => Event<T> | null;
  sectionStartDate: Date;
  sectionEndDate: Date;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
}

const CalendarDay = <T, U>({
  isEditing,
  parentSectionId,
  section,
  date,
  events,
  rowIndex,
  getEventForDay,
  sectionStartDate,
  sectionEndDate,
  getOverlayColour,
}: Props<T, U>) => {
  // Event that starts on this date
  const event = getEventForDay(section, date, events, rowIndex);

  // Event that started before the start of this section
  const partiallyVisibleEvent = getPartiallyVisibleEvent(
    sectionStartDate,
    section,
    date,
    events,
    rowIndex
  );

  const isWeekend = date.getDay() === 0 || date.getDay() === 6;

  const data: { droppable: Droppable<U> } = {
    droppable: {
      date,
      parentSectionId,
      section,
      rowIndex,
      metadata: section.metadata,
    },
  };

  const { setNodeRef } = useDroppable({
    id: `${date.toISOString()}|${section.id}|${rowIndex}`,
    data: data,
  });

  const overlayColour =
    getOverlayColour && partiallyVisibleEvent
      ? getOverlayColour(partiallyVisibleEvent, true)
      : 'bg-blue-100';

  return (
    <div
      className={classNames('w-[32px] h-6 relative', {
        'bg-gray-200 border-x border-gray-300':
          isWeekend && !partiallyVisibleEvent,
        [overlayColour]: partiallyVisibleEvent,
      })}
      ref={isEditing ? setNodeRef : null}
    >
      {event && !partiallyVisibleEvent && (
        <EventOverlay
          isEditing={isEditing}
          event={event}
          sectionEndDate={sectionEndDate}
          getOverlayColour={getOverlayColour}
        />
      )}
      {partiallyVisibleEvent &&
        date.toISOString().split('T')[0] ===
          sectionStartDate.toISOString().split('T')[0] && (
          <div className="absolute top-0 left-0 h-full z-10 opacity-80 w-max">
            {partiallyVisibleEvent.label}
          </div>
        )}
    </div>
  );
};

export default CalendarDay;
