import React, { useCallback, useContext } from 'react';
import { Outlet } from 'react-router';
import _ from 'underscore';

import Navbar from '../../../framework-components/navbar/Navbar';
import { useSubmitDataToAPI } from '../../../api/submit';
import Logo from '../../Logo';
import RequirePermission from './RequirePermission';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';

const AdminPageContainer: React.FC = () => {
  const user = useContext(LoggedInUserContext);
  const submitDataToAPI = useSubmitDataToAPI('/api/auth/logout/');
  const userIsImpersonate = user && user.is_impersonate;

  const onClickLogout = useCallback(async () => {
    const { succeeded } = await submitDataToAPI(undefined, _.noop);
    if (succeeded) {
      window.location.reload();
    }
  }, [submitDataToAPI]);

  const stopImpersonate = () => {
    return (window.location.href = `/impersonate/stop/`);
  };

  return (
    <RequirePermission permissionName="Enquiry.ADD_ENQUIRY">
      <Navbar
        appLogo={
          <Logo
            width={200}
            height={48}
            className="transform translate-y-[-10px] md:translate-y-[-15px]"
          />
        }
        className="min-h-[120px] flex flex-wrap items-center"
        navigation={[
          {
            name: 'Enquiries',
            href: '#',
            children: [
              {
                name: 'New enquiries',
                href: '/admin/enquiries/new',
              },
              {
                name: 'Ongoing enquiries',
                href: '/admin/enquiries/ongoing',
              },
            ],
          },
          {
            name: 'Bookings',
            href: '#',
            children: [
              {
                name: 'Pending bookings',
                href: '/admin/bookings/pending',
              },
              {
                name: 'Confirmed bookings',
                href: '/admin/bookings/confirmed',
              },
              {
                name: 'On Trip bookings',
                href: '/admin/bookings/on-trip',
              },
              {
                name: 'Arrivals',
                href: '/admin/bookings/arrivals',
              },
              {
                name: 'Departures',
                href: '/admin/bookings/departures',
              },
            ],
          },
          {
            name: 'Capacities',
            href: '#',
            children: [
              {
                name: 'Departments capacities',
                href: '/admin/capacities/department-capacities',
              },
              {
                name: 'Room capacities',
                href: '/admin/capacities/room-capacities',
              },
              {
                name: 'House & Village capacities',
                href: '/admin/capacities/house-village-capacities',
              },
            ],
          },
          {
            name: 'Alumni',
            href: '#',
            children: [
              {
                name: 'All alumni',
                href: '/admin/alumni/all',
              },
              {
                name: 'Survey results',
                href: '/admin/alumni/survey-results',
              },
              {
                name: 'Aggregate survey results',
                href: '/admin/alumni/aggregate-survey-results',
              },
            ],
          },
          {
            name: 'Reporting',
            href: '#',
          },
          {
            name: 'Directories',
            href: '#',
            children: [
              {
                name: 'Universities',
                href: '/admin/directories/universities',
              },
              {
                name: 'Destinations',
                href: '/admin/directories/destinations',
              },
              {
                name: 'Hospitals',
                href: '/admin/directories/hospitals',
              },
              {
                name: 'Houses',
                href: '/admin/directories/houses',
              },
            ],
          },
          {
            name: 'Finance',
            href: '#',
          },
          {
            name: 'Admin',
            href: '#',
            children: [
              {
                name: 'Profile settings',
                href: '#',
              },
              {
                name: 'WTW Staff listing',
                href: '/admin/staff-listing',
              },
            ],
          },
        ]}
        profileActions={[
          {
            label: userIsImpersonate ? 'Stop Impersonating' : 'Logout',
            onClick: userIsImpersonate ? stopImpersonate : onClickLogout,
          },
        ]}
      />
      <Outlet />
    </RequirePermission>
  );
};

export default AdminPageContainer;
