import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { redirect } from 'react-router';

import { SearchButtonProps } from '../search-button/types';
import SearchButton from '../search-button/SearchButton';

export type Navigation = ReadonlyArray<{
  name: string;
  href: string;
  openInNewTab?: boolean;
  current?: boolean;
  children?: ReadonlyArray<{
    name: string;
    href: string;
    openInNewTab?: boolean;
  }>;
}>;

export type ProfileActions = ReadonlyArray<{
  label: string;
  href?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface NavbarProps<SearchButtonPropsTyping = any> {
  navigation: Navigation;
  appLogo?: React.ReactNode;
  profileActions?: ProfileActions;
  className?: string;
  search?: SearchButtonProps<SearchButtonPropsTyping>;
}

const renderNavbarItems = (navigation: Navigation) => {
  return navigation.map((item, index) => (
    <div key={index} className="relative group z-10">
      <a
        href={item.href}
        target={item.openInNewTab ? '_blank' : '_self'}
        className={classNames(
          'inline-flex items-center border-b-2 px-1 pt-1 text-md',
          {
            'border-indigo-500 text-gray-900': item.current,
            'border-transparent text-gray-500 group-hover:border-gray-300 hover:text-gray-700':
              !item.current,
          }
        )}
      >
        {item.name}
      </a>
      {item.children && item.children.length > 0 && (
        <div className="absolute left-0 hidden pt-2 group-hover:block w-max">
          <div className="bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            {item.children.map((child, childIndex) => (
              <a
                key={childIndex}
                href={child.href}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {child.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  ));
};

const renderProfileActionsDropdown = (profileActions: ProfileActions) => {
  return (
    <div className="absolute top-0 bottom-0 right-0 flex items-center pr-xs sm:static sm:inset-auto sm:ml-2xl sm:pr-0">
      <Menu as="div" className="relative ml-lg">
        <div>
          <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="absolute -inset-md" />
            <span className="sr-only">Open user menu</span>
            <img
              className="h-2xl w-2xl rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-md w-[192px] origin-top-right rounded-md bg-white py-[4px] ring-1 ring-black ring-opacity-5 focus:outline-none">
            {profileActions.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => {
                  return (
                    <a
                      href={item.href}
                      target={item.openInNewTab ? '_blank' : '_self'}
                      onClick={item.onClick}
                      className={classNames(
                        { 'bg-gray-100': active },
                        'block px-sm py-xs text-sm text-gray-700'
                      )}
                    >
                      {item.label}
                    </a>
                  );
                }}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const renderMobileNavbarItems = (navigation: Navigation) =>
  navigation.map((item, index) => (
    <div key={index}>
      {item.children && item.children.length > 0 ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full text-left py-2 pl-4 pr-4 text-base font-medium text-gray-500 hover:text-gray-700 flex gap-2 items-center">
                {item.name}{' '}
                {open ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Disclosure.Button>
              {item.children && (
                <Disclosure.Panel className="space-y-1">
                  {item.children.map((child, childIndex) => (
                    <Disclosure.Button
                      key={childIndex}
                      as="a"
                      href={child.href}
                      className="block py-2 pl-10 pr-4 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    >
                      {child.name}
                    </Disclosure.Button>
                  ))}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ) : (
        <Disclosure.Button
          as="a"
          href={item.href}
          target={item.openInNewTab ? '_blank' : '_self'}
          className={classNames(
            'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
            {
              'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700':
                !item.current,
              'border-indigo-500 text-indigo-700 bg-indigo-50': item.current,
            }
          )}
        >
          {item.name}
        </Disclosure.Button>
      )}
    </div>
  ));

const Navbar: React.FC<NavbarProps> = ({
  navigation,
  appLogo,
  profileActions,
  className,
  search,
}) => {
  const hasProfileActions = profileActions && profileActions.length > 0;

  return (
    <Disclosure as="nav" className={classNames('bg-white shadow', className)}>
      {({ open }) => (
        <>
          <div className="w-full mx-auto max-w-[1440px] px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-[120px] justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div
                className={classNames(
                  'flex flex-1 items-center justify-center sm:items-stretch sm:justify-start',
                  { 'sm:justify-between': !profileActions },
                  { 'sm:justify-start': profileActions }
                )}
              >
                {appLogo && (
                  <div
                    className="flex flex-shrink-0 items-center cursor-pointer"
                    onClick={() => redirect('/')}
                  >
                    {appLogo}
                  </div>
                )}
                <div className="hidden w-full justify-end sm:ml-6 sm:flex sm:items-center sm:space-x-8">
                  {renderNavbarItems(navigation)}
                </div>
              </div>
              {search && <SearchButton {...search} />}
              {hasProfileActions &&
                renderProfileActionsDropdown(profileActions)}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden w-full">
            <div className="space-y-1 pb-4 pt-2">
              {renderMobileNavbarItems(navigation)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
