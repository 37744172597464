import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';

import useModalState from '../../../../hooks/modals';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import ImageGalleryModal from './ImageGalleryModal';
import { Image } from './types';
import PlaceholderImage from './PlaceholderImage';
import Card from '../../../../framework-components/card/Card';
import ErrorOverlay from '../error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';

interface Props {
  title: string;
  getImages: () => Promise<Image[]>;
  setMainImage: (imageId: string) => Promise<Image[]>;
  deleteImage: (imageId: string) => Promise<Image[]>;
  onSuccessfulUpload: (file_id: string) => void;
}

const ImageGallery: React.FC<Props> = ({
  title,
  getImages,
  setMainImage,
  deleteImage,
  onSuccessfulUpload,
}) => {
  const [images, setImages] = useState<Image[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { isOpen, openModal, closeModal } = useModalState();

  useEffect(() => {
    const handleGetImages = async () => {
      try {
        const imagesResponse = await getImages();
        setImages(imagesResponse);
      } catch {
        setError('Failed to load images');
      } finally {
        setIsLoading(false);
      }
    };

    handleGetImages();
  }, [getImages]);

  const handleSetMainImage = useCallback(
    async (imageId: string) => {
      try {
        const updatedImages = await setMainImage(imageId);
        setImages(updatedImages);
        toast.success('Main image updated');
      } catch {
        toast.error('Failed to set main image');
      }
    },
    [setMainImage]
  );

  const handleDeleteImage = useCallback(
    async (imageId: string) => {
      try {
        const updatedImages = await deleteImage(imageId);
        setImages(updatedImages);
        toast.success('Image deleted');
      } catch {
        toast.error('Failed to delete image');
      }
    },
    [deleteImage]
  );

  const mainPhoto = useMemo(
    () => images.find((image) => image.isMain),
    [images]
  );

  return (
    <Card hasBorder hasShadow>
      <div className="space-y-4 grow relative">
        {isLoading && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        <h3 className="text-h4">{title}</h3>
        <div className="p-2">
          {mainPhoto ? (
            <img
              src={mainPhoto.source}
              alt={mainPhoto.alt || ''}
              className="w-full h-auto"
            />
          ) : (
            <PlaceholderImage />
          )}
        </div>
        {images && (
          <div className="flex justify-center">
            <PrimaryButton onClick={openModal} label="Manage photos" />
          </div>
        )}
        {images.length === 0 && <div className="text-center">No images</div>}
        <ImageGalleryModal
          isOpen={isOpen}
          closeModal={closeModal}
          images={images}
          setMainImage={handleSetMainImage}
          deleteImage={handleDeleteImage}
          onSuccessfulUpload={onSuccessfulUpload}
        />
      </div>
    </Card>
  );
};

export default ImageGallery;
