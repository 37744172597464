import React from 'react';
import { useParams } from 'react-router';

import AdminStandardPage from '../common/AdminStandardPage';
import ThreeColumns from '../common/layout/ThreeColumns';
import TwoColumns from '../common/layout/TwoColumns';
import CardDetail from '../../../framework-components/card/CardDetail';
import { useAPI } from '../../../api/api';
import ErrorOverlay from '../common/error/ErrorOverlay';
import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import { UniversityBasicInfo } from './types';

const UniversityDetail: React.FC = () => {
  const { universityId } = useParams<{ universityId: string }>();

  const {
    data: universityInformation,
    isValidating,
    error,
  } = useAPI<UniversityBasicInfo>(
    `/api/admin/university/${universityId}/basic-profile-page-info/`
  );

  const renderHouseCard = (data: Record<string, string | undefined>) => {
    return (
      <CardDetail
        backgroundColor="bg-wtw-gray-2"
        className="h-full"
        data={data}
      />
    );
  };

  const UniversityName = universityInformation?.name ?? '';

  const renderFirstColumn = () => {
    return renderHouseCard({
      Name: UniversityName,
      Status: 'NOT YET IMPLEMENTED',
    });
  };

  const renderSecondColumn = () => {
    return renderHouseCard({
      Phone: universityInformation?.phone ?? '-',
      Website: universityInformation?.website ?? '-',
    });
  };

  const renderThirdColumn = () => {
    return renderHouseCard({
      Address: universityInformation?.address ?? '-',
    });
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Universities',
          href: '/admin/directories/universities',
        },
        {
          label: UniversityName,
          href: null,
        },
      ]}
      title={UniversityName}
      enableHome={false}
    >
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {universityInformation && (
        <div className="mt-8">
          <div className="pb-16">
            <ThreeColumns
              leftColumn={renderFirstColumn()}
              middleColumn={renderSecondColumn()}
              rightColumn={renderThirdColumn()}
            />
          </div>
          <TwoColumns
            leftColumn={<h1>Add content here</h1>}
            rightColumn={<h1>Add content here</h1>}
          />
        </div>
      )}
    </AdminStandardPage>
  );
};

export default UniversityDetail;
