import classNames from 'classnames';
import React from 'react';

import Loading from '../loading/Loading';
import withEditableProps from '../../context-providers/withEditableProps';

interface Props {
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  roundedClassName?: string;
  paddingClassName?: string;
  containerClassName?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
}

const Button: React.FC<Props> = ({
  label,
  onClick,
  isDisabled,
  isLoading,
  roundedClassName = 'rounded-3xl',
  paddingClassName = 'px-6 py-3',
  containerClassName,
  className,
  type,
  children,
}) => {
  const renderLoading = () => {
    if (!isLoading) return null;

    return <Loading />;
  };

  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={classNames(
        'text-mob-md h-fit',
        roundedClassName,
        paddingClassName,
        className,
        { 'cursor-not-allowed': isDisabled }
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-center',
          containerClassName
        )}
      >
        {renderLoading()}
        {children || label}
      </div>
    </button>
  );
};

export const PlainButton: React.FC<Props> = (props) => {
  const plainButtonProps: Props = {
    ...props,
    className: classNames(
      'text-black hover:text-wtw-dark-blue disabled:text-wtw-disabled-text border border-black min-w-[115px] max-w-fit active:bg-gray-200 hover:bg-gray-100 justify-evenly',
      props.className
    ),
    roundedClassName: 'rounded-md',
    paddingClassName: 'p-2.5',
  };
  return <Button {...plainButtonProps} />;
};

export const PrimaryButton: React.FC<Props> = (props) => {
  const primaryButtonProps: Props = {
    ...props,
    className: classNames(
      'bg-wtw-logo-blue hover:bg-wtw-dark-blue text-white',
      'disabled:bg-wtw-gray-3 disabled:text-wtw-disabled-text',
      'active:bg-blue-700',
      props.className
    ),
  };
  return <Button {...primaryButtonProps} />;
};

export const SecondaryButton: React.FC<Props> = (props) => {
  const secondaryButtonProps: Props = {
    ...props,
    className: classNames(
      'bg-white text-wtw-logo-blue hover:bg-wtw-blue-light-5 hover:text-wtw-secondary-text',
      'disabled:bg-wtw-gray-3 disabled:text-wtw-disabled-text disabled:ring-transparent',
      'active:bg-gray-200 active:text-gray-800 ring-1 ring-wtw-logo-blue',
      props.className
    ),
  };
  return <Button {...secondaryButtonProps} />;
};

export const DangerButton: React.FC<Props> = (props) => {
  const dangerButtonProps: Props = {
    ...props,
    className: classNames(
      'bg-red-500 hover:bg-red-600 text-white ring-gray-300',
      'active:bg-red-700 ring-red-300 disabled:bg-red-100 disabled:text-red-400',
      props.className
    ),
  };
  return <Button {...dangerButtonProps} />;
};

export const RoundedSmallButtonPrimary: React.FC<Props> = (props) => {
  const roundedSmallButtonPrimaryProps: Props = {
    ...props,
    className: classNames(
      'min-h-[24px] leading-[0px] text-sm',
      props.className
    ),
    paddingClassName: props.isLoading ? 'px-6 py-1' : undefined,
  };
  return <PrimaryButton {...roundedSmallButtonPrimaryProps} />;
};

export const RoundedSmallButtonOutline: React.FC<Props> = (props) => {
  const roundedSmallButtonOutlineProps: Props = {
    ...props,
    className: classNames(
      'min-h-[24px] leading-[0px] text-sm',
      props.className
    ),
    paddingClassName: props.isLoading ? 'px-6 py-1' : undefined,
  };
  return <SecondaryButton {...roundedSmallButtonOutlineProps} />;
};

type EditableProps = Pick<Props, 'label' | 'isDisabled' | 'isLoading'>;

export const StyleGuideEditablePrimaryButton = withEditableProps<EditableProps>(
  PrimaryButton,
  {
    label: {
      displayName: 'Label',
      type: 'text',
      defaultValue: 'asdasodijasd',
    },
    isDisabled: {
      displayName: 'Disabled',
      type: 'boolean',
    },
    isLoading: {
      displayName: 'Loading',
      type: 'boolean',
    },
  }
);

export default PrimaryButton;
