import React, { useCallback } from 'react';

import QuickForm from '../../framework-components/quickform/QuickForm';

async function onSubmit<T>(data: T): Promise<T> {
  console.log(data); // eslint-disable-line no-console
  const errors = {
    non_field_errors: ['Something went wrong'],
    name: ['Name is too short!'],
  } as T;

  return new Promise((resolve) => setTimeout(() => resolve(errors), 1000));
}

const QuickFormExample: React.FC = () => {
  const handleSubmit = useCallback(onSubmit, []);

  return (
    <div className="space-y-4 max-w-xs">
      <h2 className="text-2xl font-bold">Quick Form</h2>
      <QuickForm
        onSubmit={handleSubmit}
        initialValues={{
          name: '',
          password: '',
          description: '',
          local_dropdown: null,
          remote_dropdown: null,
          checkbox: false,
          multi_choice: [],
          radio: '',
        }}
        fields={[
          {
            type: 'smalltext',
            name: 'name',
            label: 'Name',
          },
          {
            type: 'smalltext',
            name: 'password',
            label: 'Password',
            extraProps: { type: 'password' },
          },
          {
            type: 'largetext',
            name: 'description',
            label: 'Description',
            extraProps: { placeholder: 'Enter a description', rows: 6 },
          },
          {
            type: 'local-dropdown',
            name: 'local_dropdown',
            label: 'Local dropdown',
            options: [
              { label: 'Option 1', value: '1' },
              { label: 'Option 2', value: '2' },
              { label: 'Option 3', value: '3' },
            ],
          },
          {
            type: 'remote-dropdown',
            name: 'remote_dropdown',
            label: 'Remote dropdown',
            path: '/api/example-dropdown-options/',
          },
          {
            type: 'checkbox',
            name: 'checkbox',
            label: 'Checkbox',
            option: {
              value: 'commentsEnabled',
              label: 'Enable comments',
              description: 'Allow comments on this post',
            },
          },
          {
            type: 'multi-choice',
            name: 'multi_choice',
            label: 'Multiple choice',
            options: [
              { label: 'Option 1', value: '1', description: 'Description 1' },
              { label: 'Option 2', value: '2', description: 'Description 2' },
              { label: 'Option 3', value: '3', description: 'Description 3' },
            ],
          },
          {
            type: 'radio',
            name: 'radio',
            label: 'Radio',
            options: [
              { label: 'Option 1', value: '1', description: 'Description 1' },
              { label: 'Option 2', value: '2', description: 'Description 2' },
              { label: 'Option 3', value: '3', description: 'Description 3' },
            ],
          },
        ]}
      />
    </div>
  );
};

export default QuickFormExample;
