import React from 'react';

import { StyleGuideEditableBlockTabs } from '../../framework-components/blocktabs/BlockTabs';

const BlockTabsExample: React.FC = () => {
  return (
    <div className="mb-4">
      <h1 className="text-2xl font-bold mb-4">Block Tabs</h1>
      <StyleGuideEditableBlockTabs
        tabs={[
          { label: 'My Account', href: '#', current: true },
          { label: 'Company', href: '#' },
          { label: 'Team', href: '#' },
          { label: 'Billing', href: '#' },
          { label: 'Settings', href: '#', onClick: () => alert('Settings') },
        ]}
      />
    </div>
  );
};

export default BlockTabsExample;
