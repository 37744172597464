import { createContext } from 'react';

import { PermissionName } from '../auto/permissions';

export type LoggedInUser = Readonly<{
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  is_impersonate: boolean;
  permissionsGranted: ReadonlyArray<PermissionName>;
}>;

export const LoggedInUserContext = createContext<LoggedInUser | null>(null);
