import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

export interface TextAreaProps {
  label?: string;
  id?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  defaultValue?: string;
  rows?: number;
  disabled?: boolean;
  autoGrow?: boolean;
  containerClassName?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  id,
  name,
  onChange,
  placeholder,
  defaultValue = '',
  rows = 4,
  disabled,
  autoGrow = false,
  containerClassName,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = useCallback(() => {
    if (!(autoGrow && textAreaRef.current)) return;

    const textarea = textAreaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [autoGrow]);

  useEffect(() => {
    adjustHeight();
  }, [autoGrow, adjustHeight]);

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          ref={textAreaRef}
          id={id}
          className={classNames(
            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none',
            { 'resize-none': autoGrow }
          )}
          name={name}
          rows={rows}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextArea;
