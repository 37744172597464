import React from 'react';
import {
  useFormikContext,
  FastField,
  FieldInputProps,
  FormikProps,
} from 'formik';

import { Option } from '../../../types';
import Input from '../../../framework-components/input/Input';
import RemoteDropdown from '../../../framework-components/dropdown/RemoteDropdown';
import LocalDropdown from '../../../framework-components/dropdown/LocalDropdown';
import { FormLabel } from './FormLabel';
import { FormValues } from './types';
import { EDUCATION_STATUS, MAX_YEARS_OF_GRADUATION } from './constants';
import { getFormikErrors } from '../../../utils/forms';

const yearOfGraduationOptions: Array<Option<number>> = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < MAX_YEARS_OF_GRADUATION; i++) {
  yearOfGraduationOptions.push({
    value: currentYear - i,
    label: (currentYear - i).toString(),
  });
}

const yearOfStudyOptions: Array<Option<number>> = [
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: 5, label: '5th' },
  { value: 6, label: '6th' },
  { value: 7, label: '7th' },
];

const StudyDetails: React.FC = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <div className="space-y-8">
      <h2 className="text-mob-h3 xs:text-h3">Your studies</h2>
      <FastField name="university">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<Option>;
          form: FormikProps<FormValues>;
        }) => (
          <RemoteDropdown
            id="university"
            label={<FormLabel label="Your university" required />}
            path="/api/booking/universities/"
            errors={getFormikErrors(form, field.name)}
            name={field.name}
            value={field.value}
            onSelect={(option: Option | null) => {
              form.setFieldValue(field.name, option);
            }}
            placeholder="Type to search"
            inputOnChange={(query: string) => {
              form.setFieldValue('unknown_university', query);
            }}
            row
          />
        )}
      </FastField>
      <FastField name="university_course">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="university_course"
            label={<FormLabel label="Your degree title" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter your degree title..."
            row
          />
        )}
      </FastField>
      {formik.values.education_status === EDUCATION_STATUS.STUDENT ? (
        <FastField name="year_of_university_study">
          {({
            field,
            form,
          }: {
            field: FieldInputProps<number>;
            form: FormikProps<FormValues>;
          }) => (
            <LocalDropdown
              id="year_of_university_study"
              label={<FormLabel label="Your year of study" required />}
              options={yearOfStudyOptions}
              name={field.name}
              value={
                field.value
                  ? yearOfStudyOptions.find(
                      (option) => option.value === field.value
                    )
                  : null
              }
              onSelect={(option: Option<number> | null) => {
                form.setFieldValue(field.name, option?.value);
              }}
              errors={getFormikErrors(form, field.name)}
              row
            />
          )}
        </FastField>
      ) : (
        <FastField name="year_of_graduation">
          {({
            field,
            form,
          }: {
            field: FieldInputProps<number>;
            form: FormikProps<FormValues>;
          }) => (
            <LocalDropdown
              id="year_of_graduation"
              label={<FormLabel label="Your year of graduation" required />}
              options={yearOfGraduationOptions}
              name={field.name}
              value={
                field.value
                  ? yearOfGraduationOptions.find(
                      (option) => option.value === field.value
                    )
                  : null
              }
              onSelect={(option: Option<number> | null) => {
                form.setFieldValue(field.name, option?.value);
              }}
              errors={getFormikErrors(form, field.name)}
              row
            />
          )}
        </FastField>
      )}
    </div>
  );
};

export default StudyDetails;
