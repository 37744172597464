import React, { useCallback } from 'react';

import { StyleGuideEditableTable } from '../../framework-components/table/Table';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../framework-components/table/types';
import {
  DangerButton,
  SecondaryButton,
} from '../../framework-components/button/Button';
import { apiRequest } from '../../api/api';
import TableCell from '../../framework-components/table/TableCell';
import { formatDateToLongDateFormatWithOridinalDay } from '../../utils/dates';
import { RowData } from './types';

const Tables = () => {
  const secondaryButtonClickHandler = useCallback((row: RowData) => {
    alert(`Viewing ${row.name}`);
  }, []);

  const dangerButtonClickHandler = useCallback((row: RowData) => {
    alert(`Are you sure you want to delete ${row.name}`);
  }, []);

  const RenderActions = useCallback(
    (row: RowData) => (
      <div className="flex items-center pr-4">
        <SecondaryButton
          label="View"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => secondaryButtonClickHandler(row)}
        />
        <DangerButton
          label="Delete"
          className="ml-2"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => dangerButtonClickHandler(row)}
        />
      </div>
    ),
    [secondaryButtonClickHandler, dangerButtonClickHandler]
  );

  const renderCell = (data: RowData) => (
    <TableCell>
      <div className="flex items-center">
        <div className="flex-shrink-0 h-5 w-5">
          <img
            className="rounded-full"
            src={`https://ui-avatars.com/api/?name=${data.name}`}
            alt=""
          />
        </div>
        <div className="ml-4">{data.email}</div>
      </div>
    </TableCell>
  );

  const getDataPromise = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<RowData>>(
      '/api/example-table-data/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const getFilterOptions = useCallback((): Promise<
    Record<string, FilterOption[]>
  > => {
    return fetch('/api/example-table-filters/')
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h3 className="text-2xl font-bold">Tables</h3>
      <StyleGuideEditableTable
        columnsConfig={{
          name: {
            key: 'name',
            header: 'Name',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          title: {
            key: 'title',
            header: 'Title',
            enableSort: true,
          },
          email: {
            key: 'email',
            header: 'Email',
            renderCell: renderCell,
            enableSort: true,
          },
          role: {
            key: 'role',
            header: 'Role',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          start_date: {
            key: 'start_date',
            header: 'Start date',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (data) => (
              <TableCell>
                {formatDateToLongDateFormatWithOridinalDay(data.start_date)}
              </TableCell>
            ),
          },
        }}
        renderActions={RenderActions}
        getFilterOptions={getFilterOptions}
        getData={getDataPromise}
        enablePagination
        enableSearch
      />
    </div>
  );
};

export default Tables;
