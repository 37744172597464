import React from 'react';

import Toggles from './Toggles';
import BlockTabsExample from './BlockTabsExample';
import Navbars from './Navbars';
import ExampleButtons from './ExampleButtons';
import DescriptionList from './DescriptionList';
import PaginationBar from './PaginationBarExample';
import Inputs from './Inputs';
import TextAreas from './TextArea';
import Tables from './Tables';
import Dropdowns from './Dropdowns';
import Alerts from './Alerts';
import BreadCrumbs from './Breadcrumbs';
import Selects from './Selects';
import StatsExample from './StatsExample';
import QuickFormExample from './QuickFormExample';
import ProgressBars from './ProgressBars';
import FileUploadExample from './FileUploadExample';
import QuickSetDropDownExample from './QuickSetDropdownExample';
import ExpandableRowExample from './ExpandableRowExample';
import QuickEditCreateExample from './QuickEditCreateExample';
import QuickListEditExample from './QuickListEditExample';
import TypographyExample from './TypographyExample';
import ImageGalleryExample from './ImageGalleryExample';
import SearchButtonExample from './SearchButtonExample';
import CardDetailExample from './CardDetailExample';

const StyleGuide: React.FC = () => {
  return (
    <div className="mx-auto max-w-[1000px] my-8 px-8">
      <h1 className="text-4xl mb-8 ">StyleGuide</h1>
      <Navbars />
      <ExampleButtons />
      <Toggles />
      <BlockTabsExample />
      <DescriptionList />
      <PaginationBar />
      <TextAreas />
      <Inputs />
      <Tables />
      <Dropdowns />
      <Selects />
      <Alerts />
      <BreadCrumbs />
      <StatsExample />
      <QuickFormExample />
      <ProgressBars />
      <FileUploadExample />
      <QuickSetDropDownExample />
      <QuickEditCreateExample />
      <QuickListEditExample />
      <TypographyExample />
      <ExpandableRowExample />
      <ImageGalleryExample />
      <SearchButtonExample />
      <CardDetailExample />
    </div>
  );
};

export default StyleGuide;
