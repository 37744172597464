import React from 'react';

import TabbedWidgetSection from '../../../../framework-components/tabbedContainer/TabbedWidgetSection';
import FlightArrivalTable from './FlightArrivalTable';
import FlightDepartureTable from './FlightDepartureTable';
import { PencilEditLink } from '../../../../framework-components/button/PencilEditButton';

interface Props {
  bookingId?: string;
}

const FlightDetailsSection: React.FC<Props> = ({ bookingId }) => {
  return (
    <div className="border p-5 space-y-7 bg-white rounded-lg">
      <h1 className="text-h4">Flight details:</h1>

      <TabbedWidgetSection className="bg-wtw-gray-2">
        <h3 className="text-h5">Arrival</h3>
        <FlightArrivalTable bookingId={bookingId} />
      </TabbedWidgetSection>

      <TabbedWidgetSection className="bg-wtw-gray-2">
        <h3 className="text-h5">Departure</h3>
        <FlightDepartureTable bookingId={bookingId} />
      </TabbedWidgetSection>

      <PencilEditLink to="#" />
    </div>
  );
};

export default FlightDetailsSection;
