import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';

interface Values {
  new_password1: string;
  new_password2: string;
}

export function useOnSubmit(): (
  data: Values,
  formHelpers: Pick<
    FormikHelpers<Values>,
    'setSubmitting' | 'setErrors' | 'setStatus'
  >
) => void {
  const params = useParams();
  const submitForm = useSubmitFormToAPI<Values, null>(
    '/api/auth/password/reset/confirm/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(
        { ...data, ...params },
        formHelpers
      );
      if (hasSucceeded) {
        window.location.reload();
      }
    },
    [params, submitForm]
  );
}

const ResetPasswordForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Formik<Values>
      initialValues={{
        new_password1: '',
        new_password2: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-6">
            <h3 className="block text-lg font-medium leading-6 text-gray-900">
              Example Reset Password
            </h3>
            <WrappedField
              name="new_password1"
              type="password"
              label="Password"
            />
            <WrappedField
              name="new_password2"
              type="password"
              label="Confirm Password"
            />
          </div>
          <div className="py-6 space-y-6">
            <button
              type="submit"
              className="w-full px-4 py-2 border rounded-full focus:outline-none focus:ring-1 hover:opacity-80 bg-slate-200"
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            {status && <ErrorText>{status}</ErrorText>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
