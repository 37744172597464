import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';
import { ToastContainer } from 'react-toastify';

import router from './router';
import {
  LoggedInUser,
  LoggedInUserContext,
} from './context-providers/logged-in-user';

type Settings = Partial<{
  SENTRY_DSN: string;
}>;

const settingsElement = document.getElementById('settings');

const settings: Settings =
  settingsElement && settingsElement.textContent
    ? JSON.parse(settingsElement.textContent)
    : {};

Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const loggedInUserElement = document.getElementById('logged_in_user');

const user: LoggedInUser | null =
  loggedInUserElement && loggedInUserElement.textContent
    ? JSON.parse(loggedInUserElement.textContent)
    : {};

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <LoggedInUserContext.Provider value={user}>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>{router}</Routes>
      </QueryParamProvider>
    </BrowserRouter>
    <ToastContainer />
  </LoggedInUserContext.Provider>
);
