import { useCallback } from 'react';

import { apiRequest } from './api';
import { safelyHandleResponseError } from './utils';

export type FieldError = ReadonlyArray<string> | string;
export type SubmissionAPIError =
  | FieldError
  | Record<string, FieldError | Record<string, FieldError>>;

export function useSubmitDataToAPI<Data, Response>(
  apiUrl: string
): (
  data: Data,
  setSubmitting: (isSubmitting: boolean) => void
) => Promise<{
  succeeded: boolean;
  response: Response | null;
  errors: SubmissionAPIError | null;
}> {
  return useCallback(
    async (data, setSubmitting) => {
      setSubmitting(true);
      try {
        const response = await apiRequest<Response>(apiUrl, {
          method: 'POST',
          body: JSON.stringify(data),
        });
        setSubmitting(false);
        return { succeeded: true, response, errors: null };
      } catch (error: unknown) {
        const errors = safelyHandleResponseError(error);
        setSubmitting(false);
        return { succeeded: false, response: null, errors };
      }
    },
    [apiUrl]
  );
}
