import React, { useCallback, useState } from 'react';

import LocalDropdown from '../../framework-components/dropdown/LocalDropdown';
import RemoteDropdown from '../../framework-components/dropdown/RemoteDropdown';
import { Option } from '../../types';

const options: Option[] = [
  { value: '1', label: 'Option 1' },
  { value: '2', label: 'Option 2' },
  { value: '3', label: 'Option 3' },
  { value: '4', label: 'Option 4' },
  { value: '5', label: 'Option 5' },
];

const Dropdowns: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options[0]
  );

  const onSelect = useCallback((option: Option | null) => {
    setSelectedOption(option);
  }, []);

  const [selectedOptionRemote, setSelectedOptionRemote] =
    useState<Option | null>(options[0]);

  const onSelectRemote = useCallback((option: Option | null) => {
    setSelectedOptionRemote(option);
  }, []);

  return (
    <div className="space-y-4 max-w-xs">
      <h2 className="text-2xl font-bold">Dropdowns</h2>
      <LocalDropdown
        options={options}
        onSelect={onSelect}
        label="Local dropdown"
        name="local_dropdown"
        id="local_dropdown"
        value={selectedOption}
      />
      <RemoteDropdown
        path="/api/example-dropdown-options/"
        onSelect={onSelectRemote}
        label="Remote dropdown"
        name="remote_dropdown"
        id="local_dropdown"
        value={selectedOptionRemote}
      />
    </div>
  );
};

export default Dropdowns;
