import { PermissionName } from '../auto/permissions';
import { LoggedInUser } from '../context-providers/logged-in-user';

export function hasPermission(
  user: LoggedInUser,
  requiredPermission: PermissionName
) {
  return Boolean(
    user.permissionsGranted.find((perm) => perm === requiredPermission)
  );
}
