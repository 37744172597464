import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import TermsAndConditionsText from './TermsAndConditionsText';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  setIsAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermsAndConditionsModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  setIsAcceptedTerms,
}) => {
  const focusRef = React.useRef(null);

  const acceptTerms = React.useCallback(() => {
    setIsAcceptedTerms(true);
    closeModal();
  }, [setIsAcceptedTerms, closeModal]);

  const declineTerms = React.useCallback(() => {
    setIsAcceptedTerms(false);
    closeModal();
  }, [setIsAcceptedTerms, closeModal]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      initialFocus={focusRef}
      maxWidth="w-[1080px]"
    >
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Terms and conditions
        </Dialog.Title>
        <TermsAndConditionsText />
        <div className="flex justify-end gap-4">
          <SecondaryButton onClick={declineTerms} label="Decline" />
          <PrimaryButton onClick={acceptTerms} label="Accept" />
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
