import React from 'react';

import BookingForm from './BookingForm';
import PageWrapper from './PageWrapper';
import { SECTIONS } from './constants';

export const SUBTITLE_TEXT = `Complete all 5 sections, then move on to the next screens to choose your payment method and see a preview before submitting.
For help or enquiries call 07893 444 444  or email xxx@xxxx.com
`;

const BookingFormPage: React.FC = () => {
  return (
    <PageWrapper
      title="Booking form"
      subtitle={SUBTITLE_TEXT}
      section={SECTIONS.YOUR_DETAILS}
    >
      <BookingForm />
    </PageWrapper>
  );
};

export default BookingFormPage;
