import React from 'react';
import classNames from 'classnames';

import { Note } from '../../components/admin/common/types';
import NoteTag from './NoteTag';
import { formatDateTimeShorthandMonthDayYear } from '../../utils/dates';
import { NoteTagOption } from './types';

type PickedNoteProps = Pick<Note, 'author' | 'created' | 'content'>;

export interface NotePreviewProps extends PickedNoteProps {
  isSelected?: boolean;
  tag?: NoteTagOption;
  onClick?: () => void;
}

const NotePreview: React.FC<NotePreviewProps> = ({
  isSelected,
  author,
  created,
  content,
  tag,
  onClick,
}) => {
  const renderTags = () => {
    if (!tag) return null;

    return <NoteTag tag={tag} />;
  };
  return (
    <div
      className={classNames(
        'p-3 mb-2 rounded-lg',
        {
          'cursor-pointer transition-all hover:bg-gray-100': !isSelected,
        },
        { 'bg-gray-100': isSelected }
      )}
      onClick={onClick}
    >
      <p className="text-xs-semibold">
        From: {author.first_name} {author.last_name}
      </p>
      <p className="text-xs">{formatDateTimeShorthandMonthDayYear(created)}</p>
      <p className="text-xs pb-1 text-ellipsis overflow-hidden line-clamp-2">
        {content}
      </p>

      {renderTags()}
    </div>
  );
};

export default NotePreview;
