import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import { Image } from './types';
import ImageTile from './ImageTile';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import UploadImageModal from './UploadImageModal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  images: Image[];
  setMainImage: (imageId: string) => void;
  deleteImage: (imageId: string) => void;
  onSuccessfulUpload: (file_id: string) => void;
}

const ImageGalleryModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  images,
  setMainImage,
  deleteImage,
  onSuccessfulUpload,
}) => {
  const focusRef = React.useRef(null);

  const {
    isOpen: isOpenUploadModal,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModalState();

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      initialFocus={focusRef}
      maxWidth="w-[1080px]"
    >
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Image Gallery
        </Dialog.Title>
        <PrimaryButton onClick={openUploadModal}>Upload image</PrimaryButton>
        <div className="grid gap-4 grid-cols-1 xs:grid-cols-2 md:grid-cols-3">
          {images.map((image) => (
            <ImageTile
              key={image.id}
              image={image}
              setMainImage={setMainImage}
              deleteImage={deleteImage}
            />
          ))}
        </div>
        <UploadImageModal
          isOpen={isOpenUploadModal}
          closeModal={closeUploadModal}
          onSuccessfulUpload={onSuccessfulUpload}
        />
      </div>
    </Modal>
  );
};

export default ImageGalleryModal;
