import React from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';

const DestinationsDirectories: React.FC = () => {
  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Destinations',
          href: null,
        },
      ]}
      title="Destinations"
      enableHome={false}
    />
  );
};

export default DestinationsDirectories;
