import React from 'react';
import { FieldAttributes, Field } from 'formik';

import FieldWrapper from './field-wrapper';

interface Props {
  label: string;
}

function WrappedField<T>(props: FieldAttributes<T> & Props): JSX.Element {
  const { label, className, name, ...rest } = props;
  return (
    <FieldWrapper label={label} className={className} fieldName={name}>
      <Field name={name} {...rest} className="w-full rounded-md" />
    </FieldWrapper>
  );
}

export default WrappedField;
