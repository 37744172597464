import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import { REGISTRATION_FEE } from '../booking-form/basket/PaymentDue';
import { ExperienceType, GetEnquiryData } from '../booking-form/types';
import { EXPERIENCE_TYPE_TO_DISPLAY_NAME } from '../booking-form/labels';
import { useAPI } from '../../../api/api';
import Spinner from '../../spinner';
import Logo from '../../Logo';

export const SummarySection: React.FC = () => {
  return (
    <div className="p-4 space-y-8 text-center">
      <div className="flex flex-col items-center justify-start sm:justify-center gap-8">
        <p className="text-mob-sh2 xs:text-sh2">
          Thank you for booking with Work the World!
        </p>
        <CheckCircleIcon
          className="h-32 w-32 stroke-wtw-logo-blue"
          strokeWidth={0.5}
        />
        <div className="space-y-4">
          <p className="text-mob-sh2 xs:text-sh2 text-wtw-logo-blue">
            Payment due now
          </p>
          <p className="text-mob-sh2 xs:text-sh2 text-wtw-logo-blue">
            £{REGISTRATION_FEE} registration fee
          </p>
        </div>
      </div>
      <div className="text-sm space-y-4">
        <p>
          We've emailed you confirmation of your booking and included our BACS
          and contact details so you can pay by bank transfer or cheque. Please
          note that your place will not be secured until payment has been made.
        </p>
      </div>
    </div>
  );
};

export const CostBreakdown: React.FC<{
  order_reference: string;
  date: string;
  email: string;
  discipline: string;
  destination: string;
  start_date: string;
  hospital_duration: number;
  hospital_total_price: number;
  experience_accomodation_type: ExperienceType | null;
  experience_duration: number | null;
  experience_total_price: number | null;
}> = ({
  order_reference,
  date,
  email,
  discipline,
  destination,
  start_date,
  hospital_duration,
  hospital_total_price,
  experience_accomodation_type,
  experience_duration,
  experience_total_price,
}) => {
  return (
    <div className="p-lg bg-wtw-gray-2 space-y-12 rounded-lg">
      <div className="flex flex-col-reverse sm:flex-row gap-4 sm:gap-0 items-center justify-between">
        <div className="text-md-semibold">
          <p>Booking reference: {order_reference}</p>
          <p>Date: {date}</p>
          <p>Email: {email}</p>
        </div>
        <Logo
          width={200}
          height={48}
          className="transform translate-y-[-10px] md:translate-y-[-15px]"
        />
      </div>
      <div className="h-[1px] bg-gray-400" />
      <div className="space-y-4 max-w-[500px]">
        <h3 className="text-mob-sh2 xs:text-sh2">Booking summary</h3>
        <div className="space-y-4 text-md-semibold">
          <div className="flex justify-between">
            <p>Placement type:</p>
            <p>{discipline}</p>
          </div>
          <div className="flex justify-between">
            <p>Destination:</p>
            <p>{destination}</p>
          </div>
          <div className="flex justify-between">
            <p>Start date:</p>
            <p>{format(parseISO(start_date), 'MMMM do, yyyy')}</p>
          </div>
          <div className="flex justify-between">
            <p>Hospital placement duration:</p>
            <p>{hospital_duration} weeks</p>
          </div>
          {experience_accomodation_type && experience_duration && (
            <div className="flex justify-between">
              <p>
                {EXPERIENCE_TYPE_TO_DISPLAY_NAME[experience_accomodation_type]}:
              </p>
              <p>{experience_duration} weeks</p>
            </div>
          )}
          <div className="flex justify-between">
            <p>Total duration:</p>
            <p>{hospital_duration + (experience_duration ?? 0)} weeks</p>
          </div>
        </div>
      </div>
      <div className="space-y-4 max-w-[500px] text-wtw-logo-blue">
        <h3 className="text-mob-sh2 xs:text-sh2">Payment due now</h3>
        <p className="text-md-semibold">£{REGISTRATION_FEE} registration fee</p>
        <p className="text-md">This fee secures your place.</p>
      </div>
      <div className="space-y-4 max-w-[500px]">
        <h3 className="text-mob-sh2 xs:text-sh2">Balance remaining:</h3>
        <p className="text-md-semibold">
          Balance: £
          {(hospital_total_price + (experience_total_price ?? 0)) / 100}
        </p>
        <p className="text-md">
          This is due 90 days before your departure date.
        </p>
      </div>
    </div>
  );
};

const OrderSummary: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, error, isValidating } = useAPI<GetEnquiryData>(
    `/api/booking/enquiry/${id}/`
  );

  if (isValidating) {
    return (
      <div className="p-4 flex justify-center">
        <Spinner className="h-6 w-6" />
      </div>
    );
  }
  if (error || !data) {
    return (
      <div className="p-4 text-center">
        There was an error getting your order
      </div>
    );
  }

  return (
    <div className="p-4 md:p-lg space-y-8">
      <SummarySection />
      <CostBreakdown
        order_reference={data.order_reference}
        date={format(parseISO(data.modified), 'MMMM do, yyyy')}
        email={data.email}
        discipline={data.discipline.label}
        destination={data.destination.label}
        start_date={data.start_date}
        hospital_duration={data.hospital_duration}
        hospital_total_price={data.hospital_total_price}
        experience_accomodation_type={
          (data.experience_accomodation_type?.type as ExperienceType) || null
        }
        experience_duration={data.experience_duration}
        experience_total_price={data.experience_total_price}
      />
    </div>
  );
};

export default OrderSummary;
