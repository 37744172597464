import React from 'react';
import { useParams } from 'react-router';

import { ProgressTrackingData } from './types';
import { useAPI } from '../../../../api/api';
import Spinner from '../../../spinner';
import ProgressTracker from './ProgressTracker';

const ApplicantProgressTracker: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { data, isValidating, error } = useAPI<ProgressTrackingData[]>(
    `/api/admin/bookings/${bookingId}/get-progress/`
  );

  if (isValidating) {
    return <Spinner className="h-6 w-6" />;
  }

  if (error || !data) {
    return <div>Error getting progress data</div>;
  }

  return <ProgressTracker trackingData={data} />;
};

export default ApplicantProgressTracker;
