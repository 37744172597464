import classNames from 'classnames';
import React from 'react';

import {
  NoteTagOption,
  NoteTagOptionToReadableTextMap,
  NoteTagToColorMap,
} from './types';
import { TAG_DEFAULT_COLOR_CLASS } from './constants';

export const getNoteTagColorForOption = (tag: NoteTagOption) => {
  return NoteTagToColorMap[tag] || TAG_DEFAULT_COLOR_CLASS;
};

interface Props {
  tag: NoteTagOption;
}

const NoteTag: React.FC<Props> = ({ tag }) => {
  return (
    <span
      className={classNames(
        'flex items-center justify-center text-white text-xs-semibold rounded-md px-1 py-0.5 text-center w-full min-w-[77px] max-w-fit h-full leading-normal px-2',
        getNoteTagColorForOption(tag)
      )}
    >
      {NoteTagOptionToReadableTextMap[tag]}
    </span>
  );
};

export default NoteTag;
