import React from 'react';

import QuickEditCreate from '../quick-edit-create/QuickEditCreate';
import { apiRequest } from '../../api/api';

interface ExampleData {
  population: number;
  distance: string;
  travel_time: string;
  travel_method: string;
  language: string;
  activity: string;
  has_hospital: boolean;
}

const submitItem = async (data: ExampleData) => {
  const url = '/api/example-update-facts/';
  return apiRequest<ExampleData>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

const QuickEditCreateExample: React.FC = () => {
  return (
    <div className="space-y-4 max-w-xl">
      <h2 className="text-2xl font-bold">Quick Edit/Create</h2>
      <div className="p-4 border border-gray-200 rounded-md">
        <QuickEditCreate<ExampleData>
          title="Quick facts:"
          getUrl="/api/example-facts/"
          submitItem={submitItem}
          labels={{
            population: 'Population',
            distance: 'Distance to town',
            travel_time: 'Travel time to Dar es Salaam',
            travel_method: 'Travel method to Dar es Salaam',
            language: 'Local language',
            activity: 'Main local activity',
            has_hospital: 'Has hospital',
          }}
          fields={[
            {
              type: 'smalltext',
              name: 'population',
              label: 'Population',
              extraProps: { type: 'number' },
            },
            {
              type: 'local-dropdown',
              name: 'distance',
              label: 'Distance to town',
              options: [
                { label: '45km', value: '45km' },
                { label: '50km', value: '50km' },
                { label: '60km', value: '60km' },
              ],
            },
            {
              type: 'remote-dropdown',
              name: 'travel_time',
              label: 'Travel time to Dar es Salaam',
              path: '/api/example-dropdown-options/',
            },
            {
              type: 'largetext',
              name: 'travel_method',
              label: 'Travel method to Dar es Salaam',
            },
            {
              type: 'smalltext',
              name: 'language',
              label: 'Local language',
            },
            {
              type: 'smalltext',
              name: 'activity',
              label: 'Main local activity',
            },
            {
              type: 'checkbox',
              name: 'has_hospital',
              label: 'Has hospital',
              option: {
                value: 'has_hospital',
                label: 'Has hospital',
                description: 'The town has a hospital',
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default QuickEditCreateExample;
