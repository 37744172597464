import React, { useEffect, useState } from 'react';

import ProgressBar from '../../framework-components/progressbar/ProgressBar';

const ProgressBars = () => {
  const [percentage, setProgressPercentage] = useState<number>(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgressPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          return 0;
        }

        return prevPercentage + 1;
      });
    }, 1000 / 60);

    return () => clearInterval(progressInterval);
  }, []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h1 className="text-2xl font-bold">ProgressBar</h1>
      <ProgressBar percentage={percentage} />
      <ProgressBar percentage={25} />
      <ProgressBar percentage={50} />
      <ProgressBar percentage={75} />
    </div>
  );
};

export default ProgressBars;
