import React from 'react';

import Loading from './Loading';

const LoadingOverlay: React.FC = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
      <div className="flex flex-col gap-y-4 items-center">
        <Loading />
        <h1 className="text-h4">Loading</h1>
        <p>We're just fetching some data...</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
