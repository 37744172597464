import React from 'react';

import EditableDescriptionList from './EditableDescriptionList';
import { ReviewPaymentDetailsData } from './types';
import { PAYMENT_METHOD_TO_DISPLAY_NAME } from '../booking-form/labels';
import { PaymentMethodType } from '../booking-form/types';

interface Props {
  data: ReviewPaymentDetailsData;
}

const ReviewPaymentDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Your Payment Preferences"
        items={[
          {
            label: 'Preferred payment method',
            value:
              PAYMENT_METHOD_TO_DISPLAY_NAME[
                data.payment_method as PaymentMethodType
              ],
          },
        ]}
      />
      <div className="space-y-2 text-wtw-secondary-text text-sm">
        <p>
          Upon completion of this form your registration fee of £300 is due,
          which will secure your booking. The remainder of the balance will be
          due before you depart.
        </p>
        <p>
          Please check that all of your details are correct, and then read the
          Terms and Conditions below to proceed to your booking summary.
        </p>
      </div>
    </div>
  );
};

export default ReviewPaymentDetails;
