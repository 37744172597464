import { Event, CalendarSection } from '../../../calendar/types';
import { EventMetadata } from './types';

export const checkIsSectionFull = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  sectionId: string,
  capacity: number
) => {
  const eventsOnDateInSection = events.filter(
    (e) =>
      e.id !== event.id &&
      event.start_date <= e.end_date &&
      event.end_date >= e.start_date &&
      e.sectionId === sectionId
  );
  return eventsOnDateInSection.length >= capacity;
};

export const checkIsSectionDisabled = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  section: CalendarSection
) => {
  return checkIsSectionFull(event, events, section.id, section.capacity);
};
