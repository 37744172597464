import { APIError } from './api';
import { APIErrorData, FieldError } from './types';

export const safelyHandleResponseError = (
  error: unknown
): APIErrorData | null =>
  error instanceof APIError ? (error as APIError).body : null;

export const convertErrorsIntoString = (
  errors: FieldError | null
): string | null =>
  typeof errors === 'string' ? errors : errors && errors.join(' - ');
