import React from 'react';

import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import { Option } from '../../../types';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  destination: Option | null;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancelEditing: () => void;
  handleSaveEvents: () => Promise<void>;
}

const EditSaveButtons: React.FC<Props> = ({
  startDate,
  endDate,
  destination,
  isEditing,
  setIsEditing,
  handleCancelEditing,
  handleSaveEvents,
}) => {
  return (
    <>
      {startDate && endDate && destination && (
        <div className="flex grow justify-end">
          {isEditing ? (
            <div className="flex gap-2">
              <SecondaryButton
                label="Cancel"
                onClick={handleCancelEditing}
                type="button"
              />
              <PrimaryButton
                label="Save"
                onClick={handleSaveEvents}
                type="button"
              />
            </div>
          ) : (
            <div className="">
              <PrimaryButton
                label="Edit mode"
                onClick={() => setIsEditing(true)}
                type="button"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EditSaveButtons;
