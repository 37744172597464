import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import Breadcrumbs from '../../framework-components/breadcrumbs/Breadcrumbs';

const BreadcrumbsExample = () => {
  const navigate = useNavigate();

  const clickHandler = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h1 className="text-2xl font-bold">Breadcrumbs</h1>

      <h2 className="text-lg font-bold mb-4 mt-8">Breadcrumbs with home</h2>

      <Breadcrumbs
        crumbs={[
          { label: 'Home', onClick: () => clickHandler('/') },
          { label: 'Styleguide', onClick: () => clickHandler('/styleguide') },
        ]}
      />

      <h2 className="text-lg font-bold mb-4 mt-8">Breadcrumbs without home</h2>

      <Breadcrumbs
        crumbs={[
          { label: 'Styleguide', onClick: () => clickHandler('/styleguide') },
          {
            label: 'Breadcrumbs',
            onClick: () => clickHandler('/styleguide/breadcrumbs'),
          },
        ]}
        enableHome={false}
      />
    </div>
  );
};

export default BreadcrumbsExample;
