import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AdminStandardPage from '../../common/AdminStandardPage';
import { useAPI } from '../../../../api/api';
import { Option } from '../../../../types';
import LocalDropdown from '../../../../framework-components/dropdown/LocalDropdown';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import RoomCapacityCalendar from './RoomCapacityCalendar';

const RoomCapacity: React.FC = () => {
  const [searchParams] = useSearchParams();
  const month = searchParams.get('month');
  const year = searchParams.get('year');

  const { data: destinations } = useAPI<ReadonlyArray<Option>>(
    '/api/booking/destinations/'
  );

  const [destination, setDestination] = useState<Option | null>(null);

  const [isEditing, setIsEditing] = useState(false);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Capacities',
          href: null,
        },
        {
          label: 'Room Capacities',
          href: null,
        },
      ]}
      title={`Room allocation ${destination ? `- ${destination.label}` : ''}`}
      enableHome={false}
    >
      <div className="mt-4 w-48">
        <LocalDropdown
          label="Location:"
          name="destination"
          options={destinations}
          value={destination}
          isLoading={!destinations}
          onSelect={(option: Option | null) => {
            setDestination(option);
          }}
          isDisabled={isEditing}
        />
      </div>
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Discipline',
              href: '/admin/capacities/department-capacities',
              current: false,
            },
            {
              label: 'Room',
              href: '/admin/capacities/room-capacities',
              current: true,
            },
            {
              label: 'House/Village',
              href: '/admin/capacities/house-village-capacities',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />
      </div>
      <RoomCapacityCalendar
        month={month}
        year={year}
        destination={destination}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </AdminStandardPage>
  );
};

export default RoomCapacity;
