import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const Validator: React.FC = () => {
  const { values, validateForm } = useFormikContext();
  useEffect(() => {
    validateForm(values);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
