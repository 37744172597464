import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { BookingCount, OnTripBookingTableData } from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../../../framework-components/table/types';
import TableCell from '../../../../../framework-components/table/TableCell';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../../utils/dates';

const OnTripBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getData = (selectedFilters: CombinedFilters) => {
    return new Promise<TableData<OnTripBookingTableData>>((resolve, reject) => {
      apiRequest<TableData<OnTripBookingTableData>>(
        '/api/admin/bookings/on-trip-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      )
        .then((response) => {
          resolve(response);
        })
        .catch(() => reject());
    });
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: null,
        },
        {
          label: 'On Trip',
          href: null,
        },
      ]}
      title="On Trip"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: false,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: false,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: true,
            },
          ]}
          variant="pilled_tab"
        />
      </div>

      <Table<OnTripBookingTableData>
        columnsConfig={{
          applicant_full_name: {
            header: 'Name',
            enableSort: true,
            key: 'applicant_name',
          },
          university: {
            header: 'University',
            key: 'university',
            enableSort: true,
            filterType: 'remote-dropdown',
            remoteDropDownDataURL: '/api/admin/options/university/',
            renderCell: (booking) => booking.applicant.university.name,
          },
          destination: {
            header: 'Destination',
            key: 'destination',
            enableSort: true,
            filterType: 'remote-dropdown',
            remoteDropDownDataURL: '/api/admin/options/destination/',
            renderCell: (booking) => booking.destination.name,
          },
          discipline: {
            header: 'Discipline',
            key: 'discipline',
            enableSort: true,
            filterType: 'remote-dropdown',
            remoteDropDownDataURL: '/api/admin/options/discipline/',
            renderCell: (booking) => booking.discipline.name,
          },
          house_arrival: {
            header: 'House Arrival',
            key: 'house_arrival',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (booking) => (
              <TableCell>
                {formatDateToLongDateFormatWithOridinalDay(
                  booking.house_arrival
                )}
              </TableCell>
            ),
          },
          house_departure: {
            header: 'House Departure',
            key: 'house_departure',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (booking) => (
              <TableCell>
                {formatDateToLongDateFormatWithOridinalDay(
                  booking.house_departure
                )}
              </TableCell>
            ),
          },
          monitoring_forms: {
            header: 'Monitoring forms',
            key: 'monitoring_forms',
            renderCell: () => <TableCell>Not implemented yet</TableCell>,
          },
          positive_feedback: {
            header: 'Positive feedback',
            key: 'positive_feedback',
            renderCell: () => <TableCell>Not implemented yet</TableCell>,
          },
          actions: {
            header: 'Actions',
            key: 'actions',
            renderCell: () => (
              <TableCell>
                <a
                  className="hover:cursor-pointer"
                  onClick={() => alert('Not implemented yet.')}
                >
                  <FontAwesomeIcon icon={faCircleArrowRight} />
                </a>
              </TableCell>
            ),
          },
        }}
        enablePagination
        enableSearch
        getData={getData}
      />
    </AdminStandardPage>
  );
};

export default OnTripBookings;
