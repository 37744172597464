import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  children: React.ReactElement;
  isOpen: boolean;
  onClose(): void;
  initialFocus?: React.MutableRefObject<null>;
  maxWidth?: string;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  initialFocus,
  maxWidth,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-hide flex flex-col items-center justify-center">
          <div className="relative flex flex-col items-center justify-start w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0 md:scale-95"
              enterTo="opacity-100 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 md:scale-100"
              leaveTo="opacity-0 md:scale-95"
            >
              <div className="container flex items-center justify-center transition-all transform md:px-4">
                <Dialog.Panel
                  className={`px-4 py-6 ${
                    maxWidth ? maxWidth : 'w-full'
                  } overflow-y-auto bg-white rounded-lg shadow-xl md:p-6`}
                  style={{ maxHeight: 'calc(100vh - 4rem)' }}
                >
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
