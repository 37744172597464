import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import NotePreview from './NotePreview';
import { RoundedSmallButtonPrimary } from '../button/Button';
import TextArea from '../textarea/TextArea';
import { apiRequest, useAPI } from '../../api/api';
import NewNotesButton from './NewNotesButton';
import { Note, NoteFilters, Notes } from '../../components/admin/common/types';
import LoadingOverlay from '../loading/LoadingOverlay';
import ErrorOverlay from '../../components/admin/common/error/ErrorOverlay';
import { LoggedInUserContext } from '../../context-providers/logged-in-user';
import CopyToClipboardButton from '../../components/CopyClipboardButton';
import {
  NoteTagOption,
  NoteTagOptionToReadableTextMap,
  NoteEntityType,
} from './types';
import Card from '../card/Card';
import SearchButton from '../search-button/SearchButton';
import { SearchButtonProps } from '../search-button/types';

type PickedSearchButtonProps = Pick<SearchButtonProps<Note>, 'renderAction'>;

export interface NotesProps extends PickedSearchButtonProps {
  entityId?: string;
  entityType: NoteEntityType;
  searchDebounceTime?: number;
}

type SelectedFilters = {
  authorId: string;
};

type EndpointFilters = {
  search?: string;
} & SelectedFilters;

export function useDeleteNote(noteId: string | null, onSuccess: () => void) {
  return useCallback(() => {
    if (noteId) {
      const confirmedDelete = confirm(
        'Are you sure you want to delete this note.'
      );
      if (confirmedDelete) {
        apiRequest<Response>(`/api/admin/notes/${noteId}/delete/`, {
          method: 'POST',
          body: '',
        })
          .then(() => {
            toast.success('deleted note');
            onSuccess();
          })
          .catch(() => {
            toast.error('failed to delete note');
          });
      }
    }
  }, [noteId, onSuccess]);
}

const Notes: React.FC<NotesProps> = ({
  entityId,
  entityType,
  renderAction,
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [selectedTag, setSelectedTag] = useState<NoteTagOption | undefined>(
    undefined
  );
  const [isTemporaryNote, setIsTemporaryNote] = useState<boolean>(false);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    authorId: '',
  });

  const loggedInUser = useContext(LoggedInUserContext);

  const buildApplicantNotesURL = useCallback(
    (filters: EndpointFilters) => {
      const url = new URL(
        `/api/admin/notes/${entityType}/${entityId}/`,
        window.location.origin
      );

      Object.entries(filters).map(([key, value]) => {
        url.searchParams.set(key, value);
      });

      return url.search ? `${url.pathname}${url.search}` : url.pathname;
    },
    [entityType, entityId]
  );

  const {
    data: noteFilters,
    error: noteFiltersError,
    isValidating: noteFiltersIsValidating,
  } = useAPI<NoteFilters>(
    `/api/admin/notes/${entityType}/${entityId}/filters/`
  );
  const getNotes = useCallback(
    async (filters: SelectedFilters) => {
      setIsLoading(true);

      const requestURL = buildApplicantNotesURL(filters);

      return apiRequest<Note[]>(requestURL)
        .then((data) => {
          const firstItemOrNull = data.length > 0 ? data[0] : null;

          setIsTemporaryNote(false);
          setSelectedFilters(filters);
          setSelectedNote(firstItemOrNull);
          setSelectedTag(undefined);
          setNotes(data);
          setIsLoading(false);
        })
        .catch(() => setError('Failed to fetch notes'));
    },
    [buildApplicantNotesURL]
  );

  useEffect(() => {
    if (!isInitialized) {
      getNotes(selectedFilters).then(() => {
        setIsInitialized(true);
      });
    }
  }, [getNotes, selectedFilters, isInitialized]);

  const renderOverlays = () => {
    if (!isInitialized || isLoading || noteFiltersIsValidating) {
      return <LoadingOverlay />;
    }

    if (error || !notes) {
      return <ErrorOverlay detail="Error fetching notes" />;
    }

    if (noteFiltersError || !noteFilters) {
      return <ErrorOverlay detail="Error fetching note filters" />;
    }
  };

  const filterByAuthor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNote(null);
    getNotes({
      ...selectedFilters,
      authorId: e.target.value.trim(),
    });
  };

  const hasNotes = notes && notes.length > 0;

  const onNoteClickHandler = (note: Note) => {
    setSelectedNote(note);
    setIsTemporaryNote(false);
  };

  const renderNotePreviewListing = () => {
    if (!hasNotes) {
      return <p>No notes</p>;
    }

    return notes.map((note) => {
      return (
        <NotePreview
          key={note.id}
          author={note.author}
          created={note.created}
          content={note.content}
          isSelected={selectedNote?.id === note.id}
          onClick={() => onNoteClickHandler(note)}
          tag={note.tag}
        />
      );
    });
  };

  const createTemporaryNote = () => {
    if (!loggedInUser) return;

    const blankNoteObject = {
      id: '',
      created: new Date().toISOString(),
      author: {
        first_name: loggedInUser.firstName,
        last_name: loggedInUser.lastName,
      },
      content: '',
      tag: undefined,
    };
    setSelectedNote(blankNoteObject);
    setIsTemporaryNote(true);
  };

  const onSearchChange = (query: string) => {
    const requestURL = buildApplicantNotesURL({
      ...selectedFilters,
      search: query,
    });

    return apiRequest<Note[]>(requestURL);
  };

  const handleUpdateNoteTag = () => {
    if (!selectedNote || !selectedTag) return;

    apiRequest(`/api/admin/notes/${selectedNote.id}/add-tag/`, {
      method: 'POST',
      body: JSON.stringify({
        tag: selectedTag,
      }),
    })
      .then(() => {
        toast.success('Updated note with tag successfully!');
        getNotes(selectedFilters);
      })
      .catch(() => toast.error('Failed to update note with tag'));
  };

  const handleCreateNewNote = () => {
    if (!selectedNote || selectedNote.content === '') return;

    apiRequest<Note>(`/api/admin/notes/${entityType}/${entityId}/add/`, {
      method: 'POST',
      body: JSON.stringify({
        content: selectedNote.content,
        tag: selectedTag,
      }),
    })
      .then(() => {
        toast.success('Note saved successfully');
        getNotes({
          authorId: '',
        });
        setSelectedTag(undefined);
      })
      .catch(() => toast.error('Failed to save note'));
  };

  const saveNewNote = () => {
    if (!selectedNote) return;

    if (!isTemporaryNote) {
      handleUpdateNoteTag();
      return;
    }

    handleCreateNewNote();
  };

  const renderNoteTagOptions = () => {
    return Object.entries(NoteTagOptionToReadableTextMap).map(
      ([key, value], index) => {
        return (
          <option value={key} key={`note-tag-option-${index}`}>
            {value}
          </option>
        );
      }
    );
  };

  return (
    <Card className="border p-6 items-stretch w-full md:max-w-[570px] relative rounded-lg">
      {renderOverlays()}
      <div className="flex flex-wrap pb-6 justify-between items-end">
        <div>
          <h1 className="text-h4">Notes:</h1>
        </div>
        <div className="flex flex-wrap items-end relative">
          {' '}
          {/* the problem line because of the space-x-5 which applies to all children components */}
          <NewNotesButton onClick={createTemporaryNote} />
          <div className="flex flex-col">
            <label className="text-xs-semibold">Added by</label>
            <select
              className="text-xs p-2 min-w-[91px] h-[36px] leading-none"
              onChange={filterByAuthor}
              value={selectedFilters.authorId}
            >
              <option value="">All</option>
              {noteFilters?.added_by.map((addedBy) => (
                <option key={addedBy.value} value={addedBy.value}>
                  {addedBy.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-xs-semibold">Type</label>
            <select className="text-xs p-2 min-w-[91px] h-[36px] leading-none">
              <option value="">Booking</option>
            </select>
          </div>
          <SearchButton
            onSearch={onSearchChange}
            renderAction={renderAction}
            onSelectItem={onNoteClickHandler}
          />
        </div>
      </div>
      <div className="flex flex-wrap max-h-[485px] h-screen space-x-6">
        <div
          className={classNames('w-2/5 overflow-y-auto h-full', {
            'flex justify-center items-center': !hasNotes,
          })}
        >
          {renderNotePreviewListing()}
        </div>
        <div className="w-3/5 border h-full flex flex-1 flex-col rounded-lg">
          <div className="h-full">
            <TextArea
              containerClassName="note-textarea"
              name="note"
              placeholder="Type your note here"
              onChange={(e) => {
                if (!selectedNote || !isTemporaryNote) return;

                setSelectedNote({
                  ...selectedNote,
                  content: e.target.value,
                });
              }}
              disabled={!isTemporaryNote}
              defaultValue={selectedNote?.content || ''}
            />
          </div>
          <div className="flex flex-wrap flex-1 p-2.5 border border-l-0 border-r-0 border-b-0 justify-end text-sm h-full items-center space-x-2.5">
            <select className="rounded-3xl text-xs leading-none py-0.5 px-1 m-0 h-[19px] max-w-[100px] w-full text-wtw-logo-blue border-wtw-logo-blue">
              <option value="">Templates</option>
            </select>
            <select
              className="rounded-3xl text-xs leading-none py-0.5 px-1 m-0 h-[19px] max-w-[100px] w-full text-wtw-logo-blue border-wtw-logo-blue"
              onChange={(e) => setSelectedTag(e.target.value as NoteTagOption)}
            >
              <option value="">Add tag</option>
              {renderNoteTagOptions()}
            </select>
            {CopyToClipboardButton({
              textToCopy: selectedNote?.content || '',
              className: 'text-wtw-logo-blue',
            })}
            <button
              aria-label="Delete note"
              className="text-wtw-logo-blue"
              onClick={useDeleteNote(
                selectedNote ? selectedNote.id : null,
                () => getNotes(selectedFilters)
              )}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>

          <div className="flex p-2.5 justify-end">
            <RoundedSmallButtonPrimary onClick={saveNewNote}>
              Save note
            </RoundedSmallButtonPrimary>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Notes;
