import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';

import { Event } from './types';
import { calculateDaysBetweenDates } from '../../utils/dates';

const EditableOverlay = <T,>({
  style,
  event,
  getOverlayColour,
}: {
  style: React.CSSProperties;
  event: Event<T>;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${event.id}`,
  });
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, false)
    : 'bg-blue-300';

  return (
    <div
      style={style}
      className={classNames(
        'absolute top-0 left-0 h-full z-10 opacity-100 cursor-pointer',
        overlayColour
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {event.label}
    </div>
  );
};

const ReadonlyOverlay = <T,>({
  style,
  event,
  getOverlayColour,
}: {
  style: React.CSSProperties;
  event: Event<T>;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
}) => {
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, false)
    : 'bg-blue-300';
  return (
    <div
      style={style}
      className={classNames(
        'absolute top-0 left-0 h-full z-10 opacity-100 cursor-default',
        overlayColour
      )}
    >
      {event.label}
    </div>
  );
};

const EventOverlay = <T,>({
  isEditing,
  event,
  sectionEndDate,
  getOverlayColour,
}: {
  isEditing: boolean;
  event: Event<T>;
  sectionEndDate: Date;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
}) => {
  const isEventPartial =
    event && event.end_date < sectionEndDate ? false : true;

  const relevantEndDate =
    event && !isEventPartial ? event.end_date : sectionEndDate;

  const eventDuration = event
    ? calculateDaysBetweenDates(event.start_date, relevantEndDate) + 1
    : 0;
  const eventCellWidth = eventDuration * 32;

  const style = { width: `${eventCellWidth}px` };
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, true)
    : 'bg-blue-100';

  return (
    <>
      {isEventPartial ? (
        <div
          style={style}
          className={classNames(
            'absolute top-0 left-0 h-full z-10 opacity-100 truncate',
            overlayColour
          )}
        >
          {event.label}
        </div>
      ) : (
        <>
          {isEditing ? (
            <EditableOverlay
              style={style}
              event={event}
              getOverlayColour={getOverlayColour}
            />
          ) : (
            <ReadonlyOverlay
              style={style}
              event={event}
              getOverlayColour={getOverlayColour}
            />
          )}
        </>
      )}
    </>
  );
};

export default EventOverlay;
