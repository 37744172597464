import React, { useMemo, useCallback } from 'react';
import { FormikValues } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { mutate } from 'swr';

import { useAPI } from '../../api/api';
import Table from './Table';
import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';
import { PrimaryButton } from '../../framework-components/button/Button';
import useModalState from '../../hooks/modals';
import ManageModal from './ManageModal';
import LoadingOverlay from '../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../admin/common/error/ErrorOverlay';
import Card from '../../framework-components/card/Card';

interface Props<T> {
  title: string;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields: ReadonlyArray<FieldType>;
  getUrl: string;
  addHandler?: (values: T) => Promise<T>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
}

const QuickListEdit = <T extends FormikValues>({
  title,
  tableFields,
  quickFormFields,
  getUrl,
  addHandler,
  editHandler,
  deleteHandler,
}: Props<T>) => {
  const { data, isValidating, error } = useAPI<Array<T>>(getUrl);

  const { isOpen, openModal, closeModal } = useModalState();

  const onEditSuccess = useCallback(
    (item: T) => {
      if (data) {
        const updatedRooms = [...data];
        const roomIdx = data.findIndex((room) => room.id === item.id);
        if (roomIdx === -1) {
          return;
        }
        updatedRooms.splice(roomIdx, 1, item);
        mutate(getUrl, () => updatedRooms, {
          revalidate: false,
        });
      }
    },
    [getUrl, data]
  );

  const onAddSuccess = useCallback(
    (item: T) => {
      if (data) {
        const updatedRooms = [...data, item];
        mutate(getUrl, () => updatedRooms, {
          revalidate: false,
        });
      }
    },
    [getUrl, data]
  );

  const onDeleteSuccess = useCallback(
    (item: T) => {
      if (data) {
        const updatedRooms = [...data];
        const roomIdx = data.findIndex((room) => room.id === item.id);
        if (roomIdx === -1) {
          return;
        }
        updatedRooms.splice(roomIdx, 1);
        mutate(getUrl, () => updatedRooms, {
          revalidate: false,
        });
      }
    },
    [getUrl, data]
  );

  const actionField = useMemo(
    () => ({
      name: 'actions',
      render: (_: T, editAction: () => void, deleteAction: () => void) => (
        <div className="flex gap-2 justify-end">
          {editHandler && (
            <button className="p-1 rounded-md" onClick={editAction}>
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
          {deleteHandler && (
            <button className="p-1 rounded-md" onClick={deleteAction}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
      ),
    }),
    [editHandler, deleteHandler]
  );

  const tableFieldsWithActions = [...tableFields, actionField];

  return (
    <Card hasBorder hasShadow>
      <div className="relative space-y-4">
        {isValidating && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        <h4 className="text-h4">{title}</h4>
        {data && (
          <Table
            data={data}
            tableFields={tableFields}
            quickFormFields={quickFormFields}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            onEditSuccess={onEditSuccess}
            onDeleteSuccess={onDeleteSuccess}
          />
        )}
        <PrimaryButton label="Manage" onClick={openModal} />
        {data && (
          <ManageModal
            isOpen={isOpen}
            closeModal={closeModal}
            data={data}
            tableFields={tableFieldsWithActions}
            quickFormFields={quickFormFields}
            addHandler={addHandler}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            onAddSuccess={onAddSuccess}
            onEditSuccess={onEditSuccess}
            onDeleteSuccess={onDeleteSuccess}
          />
        )}
      </div>
    </Card>
  );
};

export default QuickListEdit;
