import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';

import TabbedWidgetSection from '../../../../framework-components/tabbedContainer/TabbedWidgetSection';
import Table from '../../../../framework-components/table/Table';
import { HospitalDetailsRequestData } from './types';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../utils/dates';
import { PlainButton } from '../../../../framework-components/button/Button';

interface Props {
  bookingId?: string;
}

const HospitalPlacementDetailsSection: React.FC<Props> = ({ bookingId }) => {
  const getData = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-hospital-details/`
    );

    return request.then((response) => response.json());
  };

  return (
    <TabbedWidgetSection className="bg-wtw-gray-2">
      <h3 className="text-h5">Hospitals</h3>

      <Table<HospitalDetailsRequestData>
        columnsConfig={{
          hospital_name: {
            key: 'hospital_name',
            header: 'Hospital',
          },
          department_name: {
            key: 'department_name',
            header: 'Department',
          },
          from_date: {
            key: 'from_date',
            header: 'Start',
            renderCell: (data) =>
              formatDateToLongDateFormatWithOridinalDay(data.from_date),
          },
          to_date: {
            key: 'to_date',
            header: 'End',
            renderCell: (data) =>
              formatDateToLongDateFormatWithOridinalDay(data.to_date),
          },
        }}
        getData={getData}
        compact
      />

      <div className="flex gap-x-3.5">
        <p className="text-xs-bold flex-grow-0 flex-shrink-0 w-[134px]">
          Details sheet for hospitals:
        </p>
        <div className="space-x-6">
          <PlainButton containerClassName="space-x-2.5">
            <span>View</span>
            <FontAwesomeIcon icon={faEye} />
          </PlainButton>
          <PlainButton containerClassName="space-x-2.5">
            <span>Download</span> <FontAwesomeIcon icon={faDownload} />
          </PlainButton>
        </div>
      </div>

      <div className="flex gap-x-3.5">
        <p className="text-xs flex-grow-0 flex-shrink-0 w-[134px]">
          Print hospital badge:
        </p>
        <a href="#" className="text-link text-lg">
          <FontAwesomeIcon icon={faCreditCard} />
        </a>
      </div>
    </TabbedWidgetSection>
  );
};

export default HospitalPlacementDetailsSection;
