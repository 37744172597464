import * as queryString from 'query-string';

import { URLParams } from '../types';

export const urlParseOptions: queryString.StringifyOptions &
  queryString.ParseOptions = {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
  skipNull: true,
  skipEmptyString: true,
};

export const formatUrlWithParams = (
  path: string,
  params: URLParams = {}
): string =>
  queryString.stringifyUrl(
    {
      url: path,
      query: params,
    },
    urlParseOptions
  );
