import React, { useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import { useAPI } from '../../../api/api';
import ReviewPersonalDetails from './ReviewPersonalDetails';
import Basket from '../booking-form/basket/Basket';
import Spinner from '../../spinner';
import { GetEnquiryData } from '../booking-form/types';
import ReviewTripDetails from './ReviewTripDetails';
import ReviewContactDetails from './ReadonlyContactDetails';
import ReviewStudyDetails from './ReviewStudyDetails';
import ReviewOtherDetails from './ReviewOtherDetails';
import ReviewPaymentDetails from './ReviewPaymentDetails';
import ReviewTermsConditions from './ReviewTermsConditions';
import { ReviewBookingFormValues } from './types';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import { useSubmitDataToAPI } from '../../../api/submit';

export function useOnSubmit(enquiryId: string | undefined): {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
} {
  const navigate = useNavigate();
  const apiUrl = `/api/booking/create-booking/${enquiryId}/`;
  const submitDataToAPI = useSubmitDataToAPI(apiUrl);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    const { succeeded } = await submitDataToAPI(undefined, setSubmitting);
    if (succeeded) {
      localStorage.removeItem('enquiryId');
      toast.success('Booking created successfully');
      navigate(`/order-summary/${enquiryId}`);
    } else {
      toast.error('Failed to create booking');
    }
  }, [enquiryId, navigate, submitDataToAPI]);

  return {
    onSubmit,
    isSubmitting,
  };
}

const ReviewBookingForm: React.FC = () => {
  const params = useParams();
  const { uid } = params;
  const { onSubmit, isSubmitting } = useOnSubmit(uid);

  const formik = useFormik<ReviewBookingFormValues>({
    initialValues: {
      uid: uid || null,
    },
    onSubmit: () => {
      onSubmit();
    },
  });

  const { data, isValidating, error } = useAPI<GetEnquiryData>(
    `/api/booking/enquiry/${uid}/`
  );

  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);

  const getExperienceData = useCallback(() => {
    if (!data?.experience_accomodation_type || !data?.experience_total_price) {
      return null;
    }

    return {
      type: data.experience_accomodation_type.type,
      price: data.experience_total_price,
    };
  }, [data]);

  const renderData = () => {
    if (isValidating) {
      return (
        <div className="p-4 flex justify-center">
          <Spinner className="h-6 w-6" />
        </div>
      );
    }
    if (error) {
      return (
        <div className="p-4 text-center">
          There was an error getting your enquiry
        </div>
      );
    }
    if (data) {
      return (
        <>
          <div className="space-y-8 w-full max-w-[768px] pr-12">
            <ReviewPersonalDetails data={data} />
            <ReviewTripDetails data={data} />
            <ReviewContactDetails data={data} />
            <ReviewStudyDetails data={data} />
            <ReviewOtherDetails data={data} />
            <ReviewPaymentDetails data={data} />
            <ReviewTermsConditions
              isAcceptedTerms={isAcceptedTerms}
              setIsAcceptedTerms={setIsAcceptedTerms}
            />
          </div>

          <Basket
            destination={data.destination}
            discipline={data.discipline.label}
            duration={data.hospital_duration}
            experience={getExperienceData()}
            start_date={data.start_date}
          />
        </>
      );
    }
  };

  return (
    <div>
      <div className="px-md xs:px-lg pb-lg flex flex-col-reverse lg:flex-row justify-start border-b border-wtw-gray-3">
        {renderData()}
      </div>
      <div className="p-lg">
        <div className="w-full flex justify-center gap-4">
          <Link to={`/booking-form/${uid}`}>
            <SecondaryButton type="button">
              <div className="flex gap-2">
                <ArrowLeftIcon className="h-6 w-6" />
                <span>Back</span>
              </div>
            </SecondaryButton>
          </Link>
          <PrimaryButton
            type="submit"
            onClick={formik.handleSubmit}
            isDisabled={!isAcceptedTerms && isSubmitting}
          >
            <div className="flex gap-2">
              <span>Payment</span>
              <ArrowRightIcon className="h-6 w-6" />
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ReviewBookingForm;
