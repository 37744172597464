import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import Navbar from '../../framework-components/navbar/Navbar';
import { SearchButtonProps } from '../../framework-components/search-button/types';

interface SearchResultOption {
  category: string;
  label: string;
  href: string;
}

const Navbars: React.FC = () => {
  const getSearchableOptions = useCallback(async (query?: string) => {
    const searchURL = new URL(
      '/api/example-search-bar-options',
      window.location.origin
    );

    if (query) {
      searchURL.searchParams.append('search', query);
    }

    return await fetch(searchURL.toString()).then((response) =>
      response.json()
    );
  }, []);

  const searchBarProps: SearchButtonProps<SearchResultOption> = {
    onSelectItem: (option) => toast.info(`Selected ${option.label}`),
    onSearch: getSearchableOptions,
    renderAction: (option: SearchResultOption, index: number) => (
      <li key={index} tabIndex={-1} role="option">
        <a
          id={`render-actions-href-${index}`}
          href={option.href}
          className="flex items-center justify-between p-sm whitespace-nowrap font-semibold text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="whitespace-nowrap font-semibold text-slate-900">
            {option.label}
          </span>
          <span className="ml-sm text-right text-xs text-slate-600">
            {option.category}
          </span>
        </a>
      </li>
    ),
    showBackgroundColor: false,
  };

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h1 className="text-2xl font-bold">Navbars</h1>

      <h2 className="text-lg font-bold mb-4 mt-8">Default Navbar</h2>
      <Navbar
        navigation={[
          { name: 'Services', href: '#', current: true },
          { name: 'Work', href: '#', openInNewTab: true },
          { name: 'About', href: '#' },
          { name: 'Team', href: '#' },
        ]}
        profileActions={[
          { label: 'Profile', onClick: () => alert('Profile') },
          { label: 'Settings', href: '#', openInNewTab: true },
          { label: 'Logout', onClick: () => alert('Logging out!') },
        ]}
        appLogo={
          <a href="#">
            <img
              src="https://dabapps-website-next-production.s3.amazonaws.com/images/Logo.width-1440.jpg"
              alt="DabApps Logo"
              className="h-5 w-auto"
            />
          </a>
        }
      />

      <h2 className="text-lg font-bold mb-4 mt-8">Navbar with Search</h2>

      <Navbar
        navigation={[
          { name: 'Services', href: '#', current: true },
          { name: 'Work', href: '#', openInNewTab: true },
          { name: 'About', href: '#' },
          { name: 'Team', href: '#' },
        ]}
        search={searchBarProps}
        appLogo={
          <a href="#">
            <img
              src="https://dabapps-website-next-production.s3.amazonaws.com/images/Logo.width-1440.jpg"
              alt="DabApps Logo"
              className="h-xl w-auto"
            />
          </a>
        }
      />
    </div>
  );
};

export default Navbars;
