import React from 'react';
import classNames from 'classnames';

export const CalendarDate: React.FC<{ date: Date }> = ({ date }) => {
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  return (
    <div
      className={classNames(
        'w-[32px] h-6 flex items-center justify-center border border-gray-300',
        {
          'bg-gray-200': isWeekend,
        }
      )}
    >
      {String(date.getDate()).padStart(2, '0')}
    </div>
  );
};

const Dates: React.FC<{ datesByMonth: Record<string, Date[]> }> = ({
  datesByMonth,
}) => {
  return (
    <div className="flex w-fit border border-gray-300">
      <div className="w-[283px]" />
      <div className="flex">
        {Object.entries(datesByMonth).map(([month, datesInMonth]) => {
          const width = datesInMonth.length * 32;
          return (
            <div key={month}>
              <div
                style={{ width: width }}
                className="pl-1 border-x border-gray-300 flex items-center"
              >
                <div className="truncate">{month}</div>
              </div>
              <div className="flex">
                {datesInMonth.map((date, idx) => (
                  <CalendarDate key={idx} date={date} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dates;
