import React from 'react';
import { redirect } from 'react-router';

import BreadCrumbs from '../../../framework-components/breadcrumbs/Breadcrumbs';
import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from './error/ErrorOverlay';
import PageContainer from '../../layout/PageContainer';

export interface AdminStandardPageProps {
  title: string;
  additionalHeaderContent?: React.ReactNode;
  breadcrumbs: ReadonlyArray<{ label: string; href: string | null }>;
  enableHome?: boolean;
  isLoading?: boolean;
  error?: string;
}

const AdminStandardPage: React.FC<
  React.PropsWithChildren<AdminStandardPageProps>
> = (props: React.PropsWithChildren<AdminStandardPageProps>) => {
  return (
    <PageContainer>
      {props.isLoading || props.error ? (
        <>
          {props.isLoading && <LoadingOverlay />}
          {props.error && <ErrorOverlay detail={props.error} />}
        </>
      ) : (
        <>
          <BreadCrumbs
            crumbs={props.breadcrumbs.map((crumb) => ({
              label: crumb.label,
              onClick: () => crumb.href && redirect(crumb.href),
            }))}
            enableHome={props.enableHome}
          />

          <div className="flex flex-wrap justify-between items-center pt-2">
            <h1 className="text-h3">{props.title}</h1>
            {props.additionalHeaderContent && (
              <div>{props.additionalHeaderContent}</div>
            )}
          </div>

          <div>{props.children}</div>
        </>
      )}
    </PageContainer>
  );
};

export default AdminStandardPage;
