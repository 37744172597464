import React from 'react';

import { CalendarSection, Event } from './types';
import CalendarDay from './CalendarDay';

interface Props<T, U> {
  isEditing: boolean;
  sectionId: string;
  title: string;
  subsections: ReadonlyArray<CalendarSection<U>>;
  events: ReadonlyArray<Event<T>>;
  dates: ReadonlyArray<Date>;
  getEventForDay: (
    section: CalendarSection<U>,
    date: Date,
    events: readonly Event<T>[],
    rowIndex: number
  ) => Event<T> | null;
  checkIsSectionDisabled?: (
    event: Event<T>,
    events: ReadonlyArray<Event<T>>,
    section: CalendarSection<U>
  ) => boolean;
  activeEvent: Event<T> | null;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  hideEmptyRows?: boolean;
}

const Section = <T, U>({
  isEditing,
  sectionId,
  title,
  subsections,
  events,
  dates,
  getEventForDay,
  checkIsSectionDisabled,
  activeEvent,
  getOverlayColour,
  hideEmptyRows,
}: Props<T, U>) => {
  return (
    <div className="w-fit">
      <div className="bg-gray-300 text-lg w-full py-1 px-4 h-full">
        <div className="sticky left-4 top-0 z-20 w-[235px]">{title}</div>
      </div>
      {subsections.map((subsection) => {
        const numberEventsInSection = new Set(
          events
            .filter((event) => event.sectionId === subsection.id)
            .map((event) => event.rowIndex)
        ).size;
        return (
          <div
            key={subsection.id}
            className="relative flex border-b border-gray-500"
          >
            <div className="flex items-center justify-center w-[235px] border border-gray-300 sticky left-0 bg-white z-20">
              {subsection.label}
            </div>
            <div>
              {Array.from({
                length: hideEmptyRows
                  ? numberEventsInSection
                  : subsection.capacity,
              }).map((_, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center border border-gray-300"
                >
                  <div className="w-12 flex items-center justify-center border-r border-gray-300">
                    {index + 1}
                  </div>
                  {dates.map((date, idx) => (
                    <CalendarDay
                      key={idx}
                      isEditing={isEditing}
                      parentSectionId={sectionId}
                      section={subsection}
                      date={date}
                      events={events}
                      rowIndex={index}
                      getEventForDay={getEventForDay}
                      sectionStartDate={new Date(dates[0])}
                      sectionEndDate={new Date(dates[dates.length - 1])}
                      getOverlayColour={getOverlayColour}
                    />
                  ))}
                </div>
              ))}
              {hideEmptyRows && (
                <div className="flex items-center justify-start border border-gray-300 text-sm pl-4">
                  {`Places ${numberEventsInSection + 1} to ${
                    subsection.capacity
                  } are vacant`}
                </div>
              )}
            </div>
            {activeEvent &&
              checkIsSectionDisabled &&
              checkIsSectionDisabled(activeEvent, events, subsection) && (
                <div
                  data-testid="calendar-section-disabled-overlay"
                  className="absolute top-0 left-0 h-full w-full bg-gray-200 opacity-60"
                />
              )}
          </div>
        );
      })}
    </div>
  );
};

export default Section;
