export const booleanToYesNo = (value: boolean) => (value ? 'Yes' : 'No');

export const formatHospitalDuration = (duration: number) => {
  let durationString = `${duration} week`;

  if (duration > 1) {
    durationString += 's';
  }

  return durationString;
};
