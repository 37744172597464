import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

type UKFormattedDatePickerProps = Omit<ReactDatePickerProps, 'dateFormat'>;

const UKFormattedDatePicker: React.FC<UKFormattedDatePickerProps> = (props) => {
  return <DatePicker {...props} dateFormat="dd/MM/yyyy" />;
};

export default UKFormattedDatePicker;
