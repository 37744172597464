import { FormikErrors, FormikHelpers } from 'formik';
import { useCallback } from 'react';

import { useSubmitDataToAPI } from './submit';
import { convertErrorsIntoString } from './utils';

export function useSubmitFormToAPI<Data, Response>(
  apiUrl: string
): (
  data: Data,
  formikHelpers: Pick<
    FormikHelpers<Data>,
    'setSubmitting' | 'setStatus' | 'setErrors'
  >
) => Promise<[boolean, Response | null]> {
  const submit = useSubmitDataToAPI<Data, Response>(apiUrl);

  return useCallback(
    async (
      data,
      { setStatus, setSubmitting, setErrors }
    ): Promise<[boolean, Response | null]> => {
      const { succeeded, response, errors } = await submit(data, setSubmitting);
      if (succeeded) {
        return [succeeded, response];
      }
      if (errors) {
        setErrors(errors as FormikErrors<Data>);

        const nonFieldErrors = convertErrorsIntoString(
          (errors as Record<string, string | string[]>)['non_field_errors']
        );
        if (nonFieldErrors) {
          setStatus(nonFieldErrors);
        } else {
          setStatus('There was an error when submitting your data');
        }
      }
      return [false, null];
    },
    [submit]
  );
}
