import React from 'react';
import { toast } from 'react-toastify';

import SearchButton from '../../framework-components/search-button/SearchButton';

interface SearchResultOption {
  image: string;
  name: string;
  href: string;
  date: string;
}

const SearchButtonExample = () => {
  const getSearchableOptions = async (query?: string) => {
    const searchURL = new URL(
      '/api/example-search-bar-options-2',
      window.location.origin
    );

    if (query) {
      searchURL.searchParams.append('search', query);
    }

    return await fetch(searchURL.toString()).then((response) =>
      response.json()
    );
  };

  const renderAction = React.useCallback(
    (option: SearchResultOption, index: number) => (
      <li key={index} tabIndex={-1} role="option">
        <a
          id={`render-actions-href-${index}`}
          href={option.href}
          className="flex items-center justify-between p-sm whitespace-nowrap font-semibold text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <div className="whitespace-nowrap font-semibold text-slate-900">
            <img
              className="rounded-full"
              src={option.image}
              alt={option.name}
            />
          </div>
          <div className="ml-sm text-right text-xs text-slate-600 flex flex-wrap items-center flex-col">
            <p className="text-sm font-semibold">{option.name}</p>
            <p className="text-xs text-slate-600">{option.date}</p>
          </div>
        </a>
      </li>
    ),
    []
  );

  const onSearchEventHandler = React.useCallback((query: string) => {
    return getSearchableOptions(query);
  }, []);

  return (
    <div className="mt-[40px] mb-[40px]">
      <h1 className="text-2xl font-bold">Search</h1>
      <SearchButton
        onSelectItem={(option) => toast.success(`Selected ${option.name}`)}
        onSearch={onSearchEventHandler}
        renderAction={renderAction}
      />
    </div>
  );
};

export default SearchButtonExample;
