import React from 'react';

import PrimaryButton from '../../framework-components/button/Button';

const PreparationList: React.FC = () => {
  return (
    <div>
      <h1 className="text-h3">Preparation List</h1>
      <div className="flex flex-col w-full lg:w-1/3">
        <PrimaryButton onClick={() => alert('Not yet implemented')}>
          Flight Details
        </PrimaryButton>
        <PrimaryButton
          className="mt-1"
          onClick={() => alert('Not yet implemented')}
        >
          Monitoring forms
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PreparationList;
