import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';

import { RoundedSmallButtonPrimary } from '../button/Button';

export interface ExpandableRowProps {
  title: string;
  titleIcon?: JSX.Element;
  bodyWhenCollapsed: () => JSX.Element;
  bodyWhenExpanded: () => JSX.Element;
  onSaveClick: () => Promise<void>;
}

const ExpandableRow: React.FC<ExpandableRowProps> = ({
  title,
  titleIcon,
  bodyWhenCollapsed,
  bodyWhenExpanded,
  onSaveClick,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const onEditDetailsClick = () => {
    setIsExpanded(true);
  };

  const onSaveClickHandler = () => {
    setIsSaving(true);
    onSaveClick()
      .then(() => {
        setIsSaving(false);
        setIsExpanded(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const renderAdditionalRightSideAction = () => {
    if (!isExpanded) {
      return (
        <Link
          to={`#`}
          className="text-indigo-600 hover:text-indigo-500 flex items-center cursor-pointer"
          onClick={onEditDetailsClick}
        >
          <FontAwesomeIcon icon={faPenToSquare} className="h-4 w-4 ml-1" /> Edit
          details
        </Link>
      );
    }

    return (
      <RoundedSmallButtonPrimary
        label="Save"
        onClick={onSaveClickHandler}
        className="cursor-pointer"
        isDisabled={isSaving}
      />
    );
  };

  return (
    <div className="flex flex-wrap bg-wtw-gray-1 rounded-lg">
      <div className="w-full">
        <div className="flex justify-between p-4">
          <div className="flex">
            <span className="font-semibold">{title}</span>
            {titleIcon && <span className="ml-2">{titleIcon}</span>}
            <div className="ml-3 flex-grow w-full">
              {!isExpanded ? bodyWhenCollapsed() : bodyWhenExpanded()}
            </div>
          </div>
          <div className="ml-3">{renderAdditionalRightSideAction()}</div>
        </div>
      </div>
    </div>
  );
};

export default ExpandableRow;
