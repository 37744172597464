import React from 'react';

import { Experience } from '../types';
import { Uuid } from '../../../../types';
import PaymentDue from './PaymentDue';
import TripCalculator from './TripCalculator';

const Basket: React.FC<{
  destination: { label: string; value: Uuid } | null;
  discipline: string | undefined | null;
  duration: number | null;
  experience: Experience | null;
  start_date: string | null;
}> = (props) => {
  return (
    <div className="h-content xs:min-w-fit pb-8 pt-9 lg:pb-0">
      <PaymentDue />
      <TripCalculator {...props} />
    </div>
  );
};

export default Basket;
