import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSterling } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import {
  BookingCount,
  PendingBookingTableData,
  RegistrationFeePaymentMethod,
} from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import MarkPaidButton from './MarkPaidButton';
import ConfirmBookingButton from './ConfirmBookingButton';
import Table from '../../../../../framework-components/table/Table';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../../utils/dates';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../../../framework-components/table/types';

const PendingBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getPendingBookingTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<PendingBookingTableData>>(
        '/api/admin/bookings/pending-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback((): Promise<
    Record<string, FilterOption[]>
  > => {
    return fetch('/api/admin/bookings/pending-bookings-filters/')
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: null,
        },
        {
          label: 'Pending',
          href: null,
        },
      ]}
      title="Pending"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: true,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: false,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />

        <Table<PendingBookingTableData>
          columnsConfig={{
            full_name: {
              key: 'full_name',
              header: 'Name',
              enableSort: true,
              renderCell: (request) => (
                <a
                  href={`/admin/bookings/${request.id}/applicant-profile/`}
                  title={request.full_name}
                >
                  {request.full_name}
                </a>
              ),
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              filterType: 'remote-dropdown',
              remoteDropDownDataURL: '/api/admin/options/destination/',
              renderCell: (request) => request.destination.name,
            },
            discipline: {
              key: 'discipline',
              header: 'Discipline',
              enableSort: true,
              filterType: 'remote-dropdown',
              remoteDropDownDataURL: '/api/admin/options/discipline/',
              renderCell: (request) => request.discipline.name,
            },
            start_date: {
              key: 'start_date',
              header: 'Travel Date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) =>
                formatDateToLongDateFormatWithOridinalDay(request.start_date),
            },
            registration_fee_payment_method: {
              key: 'registration_fee_payment_method',
              header: 'Deposit type',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => (
                <>
                  {request.registration_fee_payment_method}
                  <FontAwesomeIcon
                    className={classNames(
                      'pl-3 w-[15px] h-[15px]',
                      {
                        'text-wtw-deposit-paid':
                          request.registration_fee_paid_at ||
                          request.registration_fee_payment_method ===
                            RegistrationFeePaymentMethod.CARD,
                      },
                      {
                        'text-wtw-deposit-unpaid':
                          !request.registration_fee_paid_at &&
                          request.registration_fee_payment_method !==
                            RegistrationFeePaymentMethod.CARD,
                      }
                    )}
                    icon={faCircleSterling}
                  />
                </>
              ),
            },
            registration_fee_paid_at: {
              key: 'registration_fee_paid_at',
              header: 'Payment date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => {
                if (request.registration_fee_paid_at) {
                  return formatDateToLongDateFormatWithOridinalDay(
                    request.registration_fee_paid_at
                  );
                }

                return <MarkPaidButton bookingId={request.id} />;
              },
            },
            booking_confirmed_at: {
              key: 'booking_confirmed_at',
              header: 'Confirm Booking',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => {
                if (request.booking_confirmed_at) {
                  return formatDateToLongDateFormatWithOridinalDay(
                    request.booking_confirmed_at
                  );
                }

                return <ConfirmBookingButton bookingId={request.id} />;
              },
            },
            capacity_details: {
              key: 'capacity_details',
              header: 'Capacity Details',
              renderCell: () => (
                <div className="flex justify-center">
                  <FontAwesomeIcon
                    icon={faHouse}
                    className="text-gray-400 text-h5"
                  />
                </div>
              ),
            },
          }}
          getData={getPendingBookingTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            is_paid: {
              key: 'is_paid',
              label: 'Paid view',
              filterType: 'toggle',
              size: 'sm',
            },
            is_active: {
              key: 'is_active',
              label: 'Active/Archived',
              filterType: 'local-dropdown',
            },
            year: {
              key: 'year',
              label: 'Year',
              filterType: 'local-dropdown',
            },
            month: {
              key: 'month',
              label: 'Month',
              filterType: 'local-dropdown',
            },
          }}
        />
      </div>
    </AdminStandardPage>
  );
};

export default PendingBookings;
