import React from 'react';

import EditableDescriptionList from './EditableDescriptionList';
import { ReviewOtherDetailsData } from './types';

interface Props {
  data: ReviewOtherDetailsData;
}

const ReviewOtherDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Other details"
        items={[
          {
            label: 'Anything else you mentioned',
            value: data.notes,
          },
        ]}
      />
    </div>
  );
};

export default ReviewOtherDetails;
