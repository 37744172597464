import React from 'react';

import { RoundedSmallButtonOutline } from '../../../../../framework-components/button/Button';
import useModalState from '../../../../../hooks/modals';
import MarkPaidModal from './MarkPaidModal';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../../utils/dates';

interface Props {
  bookingId: string;
}

const MarkPaidButton: React.FC<Props> = ({ bookingId }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const [datePaid, setDatePaid] = React.useState<Date | null>(null);

  return (
    <div>
      {datePaid ? (
        formatDateToLongDateFormatWithOridinalDay(datePaid.toUTCString())
      ) : (
        <>
          <MarkPaidModal
            isOpen={isOpen}
            closeModal={closeModal}
            onPaid={(paymentDate) => setDatePaid(paymentDate)}
            bookingId={bookingId}
          />
          <RoundedSmallButtonOutline
            label="Mark as paid"
            onClick={openModal}
            className="text-sm-bold"
          />
        </>
      )}
    </div>
  );
};

export default MarkPaidButton;
