import React from 'react';
import { Link } from 'react-router-dom';

import RegistrationForm from './registration-form';

const Registration: React.FC = () => (
  <div className="space-y-6">
    <RegistrationForm />
    <p className="text-center">
      <Link className="text-blue-600 underline hover:text-blue-900" to="/login">
        Already registered? Login
      </Link>
    </p>
  </div>
);

export default Registration;
