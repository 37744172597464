import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import Table from '../../../../framework-components/table/Table';

interface Props {
  bookingId?: string;
}

const ApplicantDownloadDocumentsSection: React.FC<Props> = ({ bookingId }) => {
  const getDocumentsForBooking = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-documents-for-booking/`
    );

    return request.then((response) => response.json());
  };
  return (
    <Table<{
      category: string;
      name: string;
      downloaded: boolean;
    }>
      columnsConfig={{
        category: {
          key: 'category',
          header: 'Category',
        },
        name: {
          key: 'name',
          header: 'Name',
        },
        downloaded: {
          key: 'downloaded',
          header: 'Downloaded?',
          renderCell: (request) => {
            if (request.downloaded) {
              return <FontAwesomeIcon icon={faCheck} />;
            }

            return <FontAwesomeIcon icon={faXmark} />;
          },
        },
      }}
      getData={getDocumentsForBooking}
      compact
    />
  );
};

export default ApplicantDownloadDocumentsSection;
