import React from 'react';

import SectionBar from './SectionBar';
import { SectionType } from './types';

interface Props {
  title: string;
  subtitle?: string;
  section: SectionType;
  children: React.ReactNode;
}

const PageWrapper: React.FC<Props> = ({
  title,
  subtitle,
  section,
  children,
}) => {
  return (
    <div className="w-full min-h-screen px-4 py-8 xs:p-md md:p-lg lg:p-xl bg-white space-y-8 text-wrap">
      <div className="space-y-4 px-4 md:p-0">
        <h1 className="text-mob-h2 xs:text-h2">{title}</h1>
        {subtitle && <p className="text-md whitespace-pre-wrap">{subtitle}</p>}
      </div>
      <div className="bg-white w-full max-w-[1120px] rounded-lg shadow-lg border">
        <div className="p-md xs:p-lg">
          <SectionBar selectedSection={section} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
