import React from 'react';

const TypographyExample: React.FC = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Typography</h1>
      <div className="mt-10 mb-10 space-y-6">
        <h1 className="text-h1">Heading 1 - 60px</h1>
        <h2 className="text-h2">Heading 2 - 48px</h2>
        <h3 className="text-h3">Heading 3 - 40px</h3>
        <h4 className="text-h4">Heading 4 - 28px</h4>
        <h5 className="text-h5">Heading 5 - 24px</h5>
        <h1 className="text-sh1">Super Heading 1 - 72px</h1>
        <h2 className="text-sh2">Super Heading 2 - 56px</h2>
        <h1 className="text-lg">Large - 18px</h1>
        <h1 className="text-lg-semibold">Large Semibold - 18px</h1>
        <h1 className="text-lg-bold">Large Bold - 18px</h1>
        <h1 className="text-md">Medium - 16px</h1>
        <h1 className="text-md-semibold">Medium Semibold - 16px</h1>
        <h1 className="text-md-bold">Medium Bold - 16px</h1>
        <h1 className="text-sm">Small - 14px</h1>
        <h1 className="text-sm-semibold">Small Semibold - 14px</h1>
        <h1 className="text-sm-bold">Small Bold - 14px</h1>
        <h1 className="text-xs">Extra Small - 12px</h1>
        <h1 className="text-xs-semibold">Extra Small Semibold - 12px</h1>
        <h1 className="text-xs-bold">Extra Small Bold - 12px</h1>
        <h1 className="text-mob-h1">Mobile Heading 1 - 48px</h1>
        <h2 className="text-mob-h2">Mobile Heading 2 - 36px</h2>
        <h3 className="text-mob-h3">Mobile Heading 3 - 32px</h3>
        <h4 className="text-mob-h4">Mobile Heading 4 - 28px</h4>
        <h5 className="text-mob-h5">Mobile Heading 5 - 24px</h5>
        <h1 className="text-mob-lg">Mobile Large - 18px</h1>
        <h1 className="text-mob-lg-semibold">Mobile Large Semibold - 18px</h1>
        <h1 className="text-mob-lg-bold">Mobile Large Bold - 18px</h1>
        <h1 className="text-mob-md">Mobile Medium - 16px</h1>
        <h1 className="text-mob-md-semibold">Mobile Medium Semibold - 16px</h1>
        <h1 className="text-mob-md-bold">Mobile Medium Bold - 16px</h1>
        <h1 className="text-mob-sm">Mobile Small - 14px</h1>
        <h1 className="text-mob-sm-semibold">Mobile Small Semibold - 14px</h1>
        <h1 className="text-mob-sm-bold">Mobile Small Bold - 14px</h1>
        <h1 className="text-mob-xs">Mobile Extra Small - 12px</h1>
        <h1 className="text-mob-xs-semibold">
          Mobile Extra Small Semibold - 12px
        </h1>
        <h1 className="text-mob-xs-bold">Mobile Extra Small Bold - 12px</h1>
        <h1 className="text-table-xs">Table Extra Small - 12px</h1>
        <h1 className="text-table-md">Table Medium - 12px</h1>
        <h1 className="text-table-xs-bold">Table Extra Small Bold - 12px</h1>
      </div>
    </div>
  );
};

export default TypographyExample;
