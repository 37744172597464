import classNames from 'classnames';

import { BlockVariantStyleConfigObject } from './types';

const BlockTabVariations: BlockVariantStyleConfigObject = {
  underlined: {
    select:
      'block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
    nav: 'isolate flex items-end divide-x divide-gray-200 rounded-lg',
    tab: {
      link: {
        selected: 'text-gray-900',
        default: 'text-gray-500 hover:text-gray-700',
        additionalClasses: (tabIndex, tabs) =>
          classNames(
            tabIndex === 0 ? 'rounded-l-lg' : '',
            tabIndex === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative min-w-0 flex-1 overflow-hidden bg-white py-sm px-sm text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
          ),
      },
      text: {
        selected: 'bg-indigo-500',
        default: 'bg-transparent',
        additionalClasses: 'absolute inset-x-0 bottom-0 h-xs',
      },
    },
  },
  pilled_buttons: {
    select:
      'block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
    nav: 'flex space-x-xl',
    tab: {
      link: {
        selected:
          'bg-gray-300 text-gray-700 rounded-md px-xs py-xs text-sm font-medium',
        default:
          'text-gray-500 hover:text-gray-700 rounded-md px-xs py-xs text-sm font-medium',
      },
    },
  },
  pilled_tab: {
    select:
      'block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
    nav: 'flex space-x-xl pl-12 pt-6',
    tab: {
      link: {
        selected:
          'bg-wtw-blue-primary text-white rounded-tl-md rounded-tr-md px-xs py-xs text-sm font-medium',
        default:
          'text-black bg-wtw-gray-2 rounded-tl-md rounded-tr-md px-xs py-xs text-sm font-medium border border-b-0 border-wtw-gray-5',
      },
    },
  },
};

export default BlockTabVariations;
