import React from 'react';

interface Props {
  label: string;
  required?: boolean;
}

export const FormLabel: React.FC<Props> = ({
  label,
  required,
}: {
  label: string;
  required?: boolean;
}) => (
  <>
    {required && (
      <span aria-hidden className="text-wtw-logo-blue mr-2">
        *
      </span>
    )}
    <span>{label}</span>
  </>
);
