import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Image } from './types';
import useModalState from '../../../../hooks/modals';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';

const ImageTile: React.FC<{
  image: Image;
  setMainImage: (imageId: string) => void;
  deleteImage: (imageId: string) => void;
}> = ({ image, setMainImage, deleteImage }) => {
  const { isOpen, openModal, closeModal } = useModalState();

  const deleteImageHandler = () => {
    deleteImage(image.id);
    closeModal();
  };

  return (
    <div
      className={classNames('relative', {
        'p-2': image.isMain,
        'border-4 border-wtw-blue-primary': image.isMain,
      })}
    >
      <img src={image.source} alt={image.alt || ''} className="w-full h-auto" />
      <FontAwesomeIcon
        icon={faTrash}
        className="absolute h-6 w-6 top-4 right-4 cursor-pointer"
        onClick={openModal}
        data-testid="delete-icon"
      />
      {!image.isMain && (
        <FontAwesomeIcon
          icon={faStar}
          className="absolute h-6 w-6 top-4 left-4 cursor-pointer"
          onClick={() => setMainImage(image.id)}
          data-testid="main-icon"
        />
      )}
      <DeleteConfirmationModal
        isOpen={isOpen}
        closeModal={closeModal}
        handleDelete={deleteImageHandler}
      />
    </div>
  );
};

export default ImageTile;
