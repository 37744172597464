import React, { useCallback, useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import _ from 'underscore';

import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import Navbar from '../../../framework-components/navbar/Navbar';
import { useSubmitDataToAPI } from '../../../api/submit';
import Logo from '../../Logo';
import PageContainer from '../../layout/PageContainer';

const MyTripContainer: React.FC = () => {
  const user = useContext(LoggedInUserContext);
  const submitDataToAPI = useSubmitDataToAPI('/api/auth/logout/');

  const onClickLogout = useCallback(async () => {
    const { succeeded } = await submitDataToAPI(undefined, _.noop);
    if (succeeded) {
      window.location.reload();
    }
  }, [submitDataToAPI]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Navbar
        appLogo={
          <Logo
            width={200}
            height={48}
            className="transform translate-y-[-10px] md:translate-y-[-15px]"
          />
        }
        className="min-h-[120px] flex flex-wrap items-center"
        navigation={[]}
        profileActions={[{ label: 'Logout', onClick: onClickLogout }]}
      />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </div>
  );
};

export default MyTripContainer;
