import React from 'react';
import { cloneDeep } from 'lodash';
import { DragEndEvent } from '@dnd-kit/core';

import {
  isValidMonth,
  getEndDate,
  calculateDaysBetweenDates,
} from '../../../utils/dates';
import { SelectedDates, GetBookingsData, GetSectionsData } from './types';
import {
  Event,
  CalendarSection,
  Droppable,
  Sections,
} from '../../calendar/types';
import { UNALLOCATED_SECTION_ID } from '../../calendar/constants';

export const getSelectedDates = (
  currentMonth: number,
  currentYear: number,
  month: string | null,
  year: string | null
): SelectedDates => {
  return {
    month:
      month && isValidMonth(parseInt(month, 10))
        ? parseInt(month, 10)
        : currentMonth,
    year: year ? parseInt(year, 10) : currentYear,
    period: 31,
  };
};

export const calculateStartDate = (
  selectedDates: SelectedDates
): Date | null => {
  return selectedDates.year && selectedDates.month
    ? new Date(
        `${selectedDates.year}-${selectedDates.month
          .toString()
          .padStart(2, '0')}-01`
      )
    : null;
};

export const calculateEndDate = (
  startDate: Date | null,
  period: number | null | undefined
): Date | null => {
  return startDate && period ? getEndDate(startDate, period) : null;
};

export const transformResponseToEvents = <EventMetadata>(
  response: GetBookingsData<EventMetadata>
) => {
  const sectionsCount: Record<string, number> = {};
  const deptBookingEvents: ReadonlyArray<Event<EventMetadata>> = Object.entries(
    response
  ).flatMap(([booking_id, booking]) => {
    return Object.entries(booking.bookings).flatMap(
      ([section_id, dept_bookings]) => {
        if (!sectionsCount[section_id]) {
          sectionsCount[section_id] = 0;
        }
        sectionsCount[section_id] += 1;
        return dept_bookings.map((dept_booking) => {
          return {
            id: `${booking_id}|${dept_booking.start_date}`,
            label: booking.label,
            start_date: new Date(dept_booking.start_date),
            end_date: new Date(dept_booking.end_date),
            sectionId: section_id,
            rowIndex: sectionsCount[section_id] - 1,
            bookingId: booking_id,
            metadata: booking.metadata,
          };
        });
      }
    );
  });
  const unallocatedEvents: ReadonlyArray<Event<EventMetadata>> = Object.entries(
    response
  ).flatMap(([booking_id, booking], idx) => {
    return Object.values(booking[UNALLOCATED_SECTION_ID]).map(
      (dept_booking) => {
        return {
          id: `${booking_id}|${dept_booking.start_date}`,
          label: booking.label,
          start_date: new Date(dept_booking.start_date),
          end_date: new Date(dept_booking.end_date),
          sectionId: UNALLOCATED_SECTION_ID,
          rowIndex: idx,
          bookingId: booking_id,
          metadata: booking.metadata,
        };
      }
    );
  });
  const events = unallocatedEvents.concat(deptBookingEvents);
  return events;
};

export const transformResponseToSections = <EventMetadata, SectionMetadata>(
  response: GetSectionsData<SectionMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  addUnallocatedSection: boolean
) => {
  const sectionIds: Array<string> = Object.values(response).flatMap(
    (hospital) => hospital.sections.map((section) => section.id)
  );
  sectionIds.push(UNALLOCATED_SECTION_ID);

  const countSeparateBookings = events.reduce((acc, event) => {
    if (!acc.has(event.bookingId) && sectionIds.includes(event.sectionId)) {
      acc.add(event.bookingId);
    }
    return acc;
  }, new Set<string>()).size;

  const sections = addUnallocatedSection
    ? {
        [UNALLOCATED_SECTION_ID]: {
          label: 'Students to be allocated',
          sections: [
            {
              id: UNALLOCATED_SECTION_ID,
              label: 'Not yet allocated',
              capacity: countSeparateBookings || 1,
            },
          ],
        },
        ...response,
      }
    : (response as Sections<SectionMetadata>);
  return sections;
};

export const getEventForDay = <EventMetadata, SectionMetadata>(
  section: CalendarSection<SectionMetadata>,
  date: Date,
  events: ReadonlyArray<Event<EventMetadata>>,
  rowIndex: number
): Event<EventMetadata> | null => {
  return (
    events.find((event) => {
      return (
        event.sectionId === section.id &&
        event.start_date.toISOString().split('T')[0] ===
          date.toISOString().split('T')[0] &&
        event.rowIndex === rowIndex
      );
    }) || null
  );
};

export const getPartiallyVisibleEvent = <T, U>(
  sectionStartDate: Date,
  section: CalendarSection<U>,
  date: Date,
  events: ReadonlyArray<Event<T>>,
  rowIndex: number
) => {
  const eventInDateRange =
    events.find((event) => {
      return (
        event.sectionId === section.id &&
        event.start_date < sectionStartDate &&
        event.end_date >= date &&
        event.rowIndex === rowIndex
      );
    }) || null;
  return eventInDateRange;
};

export const checkDroppableEmpty = <EventMetadata, SectionMetadata>(
  event: Event<EventMetadata>,
  droppable: Droppable<SectionMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>
) => {
  return !events.some((e) => {
    if (e.id === event.id) return false;
    const inSameRow =
      e.sectionId === droppable.section.id && e.rowIndex === droppable.rowIndex;
    const overlap =
      event.start_date <= e.end_date && event.end_date >= e.start_date;
    return inSameRow && overlap;
  });
};

export const findEventsToMerge = <EventMetadata>(
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>
): Array<Event<EventMetadata>> => {
  const bookingId = event.bookingId;
  const bookingEvents = events.filter(
    (e) => e.bookingId === bookingId && e.id !== event.id
  );
  const bookingEventsInSameRow = bookingEvents.filter(
    (e) => e.sectionId === event.sectionId && e.rowIndex === event.rowIndex
  );

  const foundEvents: Array<Event<EventMetadata>> = [];
  bookingEventsInSameRow.forEach((e) => {
    if (
      calculateDaysBetweenDates(e.end_date, event.start_date) === 3 ||
      calculateDaysBetweenDates(e.start_date, event.end_date) === 3
    ) {
      foundEvents.push(e);
    }
  });
  return foundEvents;
};

export const mergeEvents = <EventMetadata>(
  event: Event<EventMetadata>,
  eventsToMerge: Array<Event<EventMetadata>>,
  events: Array<Event<EventMetadata>>
) => {
  eventsToMerge.push(event);
  const earliestEvent = eventsToMerge.reduce((acc, e) =>
    e.start_date < acc.start_date ? e : acc
  );
  const latestEvent = eventsToMerge.reduce((acc, e) =>
    e.end_date > acc.end_date ? e : acc
  );
  const mergedEvent = {
    ...event,
    id: `${event.bookingId}|${earliestEvent.start_date.toISOString()}`,
    start_date: earliestEvent.start_date,
    end_date: latestEvent.end_date,
  };
  eventsToMerge.forEach((e) => {
    const idx = events.findIndex((ne) => ne.id === e.id);
    events.splice(idx, 1);
  });

  events.push(mergedEvent);
};

export const onDropOnDay = <EventMetadata, SectionMetadata>(
  dragEvent: DragEndEvent,
  events: ReadonlyArray<Event<EventMetadata>>,
  setEvents: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Event<EventMetadata>> | null>
  >,
  checkIsSectionDisabled: (
    event: Event<EventMetadata>,
    events: ReadonlyArray<Event<EventMetadata>>,
    section: CalendarSection<SectionMetadata>
  ) => boolean
) => {
  if (!dragEvent.over || !dragEvent.over.data.current) return;
  const newEvents = [...cloneDeep(events)];
  const droppable = dragEvent.over.data.current
    .droppable as Droppable<SectionMetadata>;
  const event = newEvents?.find((e) => e.id === dragEvent.active.id);

  if (event) {
    const isDroppableEmpty = checkDroppableEmpty(event, droppable, newEvents);
    const isSectionDisabled = checkIsSectionDisabled(
      event,
      newEvents,
      droppable.section
    );

    if (isDroppableEmpty && !isSectionDisabled) {
      event.sectionId = droppable.section.id;
      event.rowIndex = droppable.rowIndex;

      const eventsToMerge = findEventsToMerge(event, newEvents);

      if (eventsToMerge.length > 0) {
        mergeEvents(event, eventsToMerge, newEvents);
      }

      setEvents([...newEvents]);
    }
  }
};

export const prepareEventsForSave = <T>(events: ReadonlyArray<Event<T>>) => {
  const data: Record<
    string,
    Record<string, Array<Record<string, string>>>
  > = {};

  events.forEach((event) => {
    if (!data[event.bookingId]) {
      data[event.bookingId] = {};
    }
    if (!data[event.bookingId][event.sectionId]) {
      data[event.bookingId][event.sectionId] = [];
    }
    data[event.bookingId][event.sectionId].push({
      start_date: event.start_date.toISOString().split('T')[0],
      end_date: event.end_date.toISOString().split('T')[0],
    });
  });
  return data;
};
