import React from 'react';

import CheckboxExample from './CheckboxExample';
import RadioExample from './RadioExample';
import MultiChoiceExample from './MultiChoiceExample';

const Selects: React.FC = () => {
  return (
    <div className="space-y-4 max-w-xs mt-10">
      <h2 className="text-2xl font-bold">Selects</h2>
      <CheckboxExample />
      <RadioExample />
      <MultiChoiceExample />
    </div>
  );
};

export default Selects;
