import React from 'react';
import { FormikValues } from 'formik';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import useModalState from '../../hooks/modals';
import EditModal from '../quick-edit-create/EditModal';
import DeleteConfirmationModal from '../admin/common/modal/DeleteConfirmationModal';
import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';

interface Props<T> {
  item: T;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields: ReadonlyArray<FieldType>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onEditSuccess?: (data: T) => void;
  onDeleteSuccess?: (data: T) => void;
}

const Row = <T extends FormikValues>({
  item,
  tableFields,
  quickFormFields,
  editHandler,
  deleteHandler,
  onEditSuccess,
  onDeleteSuccess,
}: Props<T>) => {
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState();

  const onDelete = async () => {
    if (!deleteHandler) {
      return;
    }
    try {
      await deleteHandler(item);
      if (onDeleteSuccess) {
        onDeleteSuccess(item);
      }
      toast.success('Item deleted successfully');
      closeDeleteModal();
    } catch {
      toast.error('Failed to delete item');
    }
  };

  const numberOfTableFields = tableFields.length - 1;

  return (
    <tr className="even:bg-white odd:bg-wtw-gray-2">
      {tableFields.map(({ render, name }, index) => (
        <td
          key={name}
          className={classNames(
            'min-w-[150px] max-w-[250px] h-[44px] text-table-xs text-ellipsis table-cell overflow-hidden line-clamp-2 align-top py-1.5',
            { 'pl-3': index === 0 },
            { 'pr-3': index === numberOfTableFields },
            { 'pr-6': index !== numberOfTableFields }
          )}
        >
          {render(item, openEditModal, openDeleteModal)}
        </td>
      ))}
      {editHandler && (
        <EditModal
          isOpen={isEditModalOpen}
          closeModal={closeEditModal}
          submitItem={editHandler}
          onSubmitSuccess={onEditSuccess}
          fields={quickFormFields}
          labels={{}}
          data={item}
        />
      )}
      {deleteHandler && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          closeModal={closeDeleteModal}
          handleDelete={onDelete}
        />
      )}
    </tr>
  );
};

export default Row;
