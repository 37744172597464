import React from 'react';

interface PageContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    <div className="mx-auto w-full md:max-w-[1440px] min-h-[500px] flex flex-col p-12 relative">
      {children}
    </div>
  );
};

export default PageContainer;
