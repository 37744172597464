import React from 'react';

interface Stat {
  label: string;
  value: string;
}

export interface StatsProps {
  stats: ReadonlyArray<Stat>;
}

const Stats: React.FC<StatsProps> = ({ stats = [] }) => (
  <div className="mx-auto max-w-7xl">
    <div className="mx-auto max-w-2xl lg:max-w-none">
      <div className="flex flex-wrap gap-0.5 overflow-hidden rounded-2xl text-center">
        {stats.map((stat, index) => (
          <div
            key={`stats-${index}`}
            className="flex flex-col flex-1 bg-gray-400/5 p-8"
          >
            <dt className="text-sm font-semibold leading-6 text-gray-600">
              {stat.label}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Stats;
