import React, { useCallback, useState } from 'react';

import PaginationBar from '../table/pagination-bar';

const PaginationBarExample: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChangePage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Pagination bar</h2>
      <PaginationBar
        currentPage={currentPage}
        totalPages={6}
        onChangePage={onChangePage}
        isLoading={false}
        totalCount={60}
        pageSize={10}
      />
      <PaginationBar
        currentPage={currentPage}
        totalPages={10}
        onChangePage={onChangePage}
        isLoading={false}
        totalCount={100}
        pageSize={10}
      />
    </div>
  );
};

export default PaginationBarExample;
