import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { FlightArrivalDetailsRequestData } from './types';
import Table from '../../../../framework-components/table/Table';

interface Props {
  bookingId?: string;
}

const FlightArrivalTable: React.FC<Props> = ({ bookingId }) => {
  const getFlightArrivalDetails = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-flight-arrival-details/`
    );

    return request.then((response) => response.json());
  };

  return (
    <Table<FlightArrivalDetailsRequestData>
      columnsConfig={{
        arrival: {
          key: 'arrival',
        },
        conflict: {
          key: 'conflict',
          renderCell: (request) => (
            <FontAwesomeIcon
              icon={faPlane}
              className={classNames({ 'text-red-500': request.conflict })}
            />
          ),
        },
      }}
      getData={getFlightArrivalDetails}
      compact
    />
  );
};

export default FlightArrivalTable;
