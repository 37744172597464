import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';

import Modal from '../../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../framework-components/button/Button';
import { apiRequest } from '../../../../../api/api';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm?: () => void;
  bookingId: string;
}

const ConfirmBookingModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
  bookingId,
}) => {
  const submitHandler = async () => {
    try {
      await apiRequest(`/api/admin/bookings/${bookingId}/confirm-booking/`, {
        method: 'post',
      });
      toast.success('Booking confirmed successfully');
      if (onConfirm) {
        onConfirm();
      }
      closeModal();
    } catch (e) {
      toast.error('Failed to confirm booking');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} maxWidth="w-[1080px]">
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold">
          Confirm booking
        </Dialog.Title>
        <p>
          Are you sure you want to confirm this booking? This action will change
          the booking status from Pending to Confirmed.
        </p>
        <div className="flex justify-end gap-4">
          <SecondaryButton label="Cancel" onClick={closeModal} />
          <PrimaryButton
            label="Confirm booking"
            type="button"
            onClick={submitHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmBookingModal;
