import classNames from 'classnames';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { BlockTabsProps } from './types';
import BlockTabVariations from './variants';
import withEditableProps from '../../context-providers/withEditableProps';

const BlockTabs: React.FC<BlockTabsProps> = ({
  tabs = [],
  variant = 'underlined',
  className,
}) => {
  const styleConfig = BlockTabVariations[variant];

  const defaultValue = tabs.find((tab) => tab.current)?.label;
  const [selectedTab, setSelectedTab] = React.useState(defaultValue);

  const onChangeEventHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const activeTab = tabs.find((tab) => tab.label === event.target.value);
    if (activeTab) {
      setSelectedTab(activeTab.label);
      if (activeTab.onClick) activeTab.onClick();
    }
  };

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={onChangeEventHandler}
          className={classNames(styleConfig.select, {
            'bg-red-500': tabs.find(
              (tab) => tab.showWarningIcon && tab.label === selectedTab
            ),
          })}
          defaultValue={defaultValue}
        >
          {tabs.map((tab) => (
            <option key={tab.label} onClick={tab.onClick}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className={styleConfig.nav} aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.label}
              href={tab.href}
              onClick={tab.onClick}
              className={classNames(
                'relative',
                tab.current
                  ? styleConfig.tab.link.selected
                  : styleConfig.tab.link.default,
                typeof styleConfig.tab.link.additionalClasses === 'function'
                  ? styleConfig.tab.link.additionalClasses(tabIdx, tabs)
                  : styleConfig.tab.link.additionalClasses
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <div className="flex flex-wrap items-center justify-center space-x-2.5">
                <span>{tab.label}</span>
                {tab.showWarningIcon && (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className={classNames(
                      { 'text-red-500': !tab.current },
                      { 'text-white': tab.current }
                    )}
                  />
                )}
              </div>
              {variant === 'underlined' && styleConfig.tab.text && (
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current
                      ? styleConfig.tab.text.selected
                      : styleConfig.tab.text.default,
                    typeof styleConfig.tab.text.additionalClasses === 'function'
                      ? styleConfig.tab.text.additionalClasses(tabIdx, tabs)
                      : styleConfig.tab.text.additionalClasses
                  )}
                />
              )}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

type EditableProps = Pick<BlockTabsProps, 'tabs' | 'variant'>;

export const StyleGuideEditableBlockTabs = withEditableProps<EditableProps>(
  BlockTabs,
  {
    variant: {
      displayName: 'Variant',
      type: 'select',
      options: [
        { label: 'Underlined', value: 'underlined' },
        { label: 'Pilled Button', value: 'pilled_buttons' },
        { label: 'Pilled Tab', value: 'pilled_tab' },
      ],
    },
  }
);

export default BlockTabs;
