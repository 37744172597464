import React from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const Logo: React.FC<Props> = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 200 49"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9454 11.2596L48.6917 21.6973C49.9116 26.3337 54.1034 29.5652 58.8976 29.5652H63.5055C64.6279 29.5652 65.5377 28.6554 65.5377 27.5331C65.5377 26.4107 64.6279 25.5009 63.5055 25.5009H58.8976C55.9498 25.5009 53.3724 23.5139 52.6223 20.6631L47.7098 1.99258C47.1593 -0.0988967 44.1638 -0.0254751 43.7186 2.09269L35.9293 39.1599L31.2369 26.8566C30.5607 25.0834 28.0465 25.1006 27.3941 26.8823C27.3941 26.8823 27.3941 26.8823 27.3941 26.8823L22.8444 39.308L20.9315 31.2718C20.1261 27.8885 17.1029 25.5009 13.6253 25.5009H2.03218C0.909836 25.5009 0 26.4107 0 27.5331C0 28.6554 0.909836 29.5652 2.03218 29.5652H13.6253C15.2211 29.5652 16.6081 30.6609 16.9776 32.213L20.4394 46.7562C20.9101 48.7335 23.6619 48.896 24.3611 46.9865C24.3611 46.9865 24.3611 46.9864 24.3611 46.9865L29.3588 33.3375L34.5736 47.0107C35.3028 48.9224 38.0764 48.7058 38.4976 46.7016C38.4976 46.7017 38.4976 46.7016 38.4976 46.7016L45.9454 11.2596ZM20.5445 45.5891C20.5445 45.5891 20.5445 45.5891 20.5445 45.5891V45.5891ZM34.5201 45.8659C34.5202 45.8652 34.5204 45.8645 34.5205 45.8638L34.5201 45.8659Z"
        fill="#2568EB"
      />
      <path
        d="M77.9442 34.6832L77.9342 34.6915L72.7318 39.5005V30.2725C72.7318 29.8148 72.5585 29.414 72.2275 29.1002C71.9137 28.7691 71.5128 28.5959 71.0552 28.5959C70.5944 28.5959 70.1912 28.7715 69.8764 29.107C69.5619 29.4249 69.4041 29.8241 69.4041 30.2725V46.7585C69.4041 47.21 69.5642 47.6117 69.8831 47.9306C70.202 48.2495 70.6037 48.4096 71.0552 48.4096C71.5035 48.4096 71.9028 48.2518 72.2207 47.9372C72.5561 47.6225 72.7318 47.2193 72.7318 46.7585V43.7065L74.3581 42.2298L78.4035 47.7334L78.4143 47.7462C78.7622 48.1594 79.2094 48.4096 79.7428 48.4096C80.1912 48.4096 80.5992 48.2714 80.9256 47.9701C81.2641 47.6577 81.4193 47.244 81.4193 46.7839C81.4193 46.4172 81.286 46.083 81.0615 45.7903L81.0599 45.7883L76.7462 40.0684L80.0667 37.0937L80.0766 37.0839C80.2789 36.8816 80.4593 36.6586 80.5487 36.4204L80.552 36.4116L80.555 36.4026C80.6142 36.2249 80.6572 36.0394 80.6572 35.8609C80.6572 35.5014 80.5485 35.1638 80.3519 34.8558C80.067 34.388 79.5536 34.2352 79.0315 34.2352C78.8007 34.2352 78.5789 34.2872 78.3725 34.3905L78.3598 34.3968L78.3476 34.4038C78.2041 34.4858 78.0694 34.5789 77.9442 34.6832Z"
        fill="#1F1F1F"
      />
      <path
        d="M91.2461 47.9882L91.2573 47.9946L91.2688 48.0004C91.7183 48.2251 92.177 48.3706 92.6435 48.43C93.0802 48.4996 93.4697 48.5366 93.8076 48.5366C94.248 48.5366 94.6617 48.4909 95.0458 48.3951C95.428 48.304 95.7872 48.1846 96.1224 48.0356L96.1369 48.0292L96.151 48.0218C96.4044 47.8896 96.6238 47.7116 96.7788 47.4757C96.9359 47.2366 97.0083 46.966 97.0083 46.6823C97.0083 46.2804 96.8635 45.9195 96.5626 45.6459C96.2891 45.3973 95.9576 45.2598 95.5858 45.2598C95.3035 45.2598 95.03 45.3135 94.769 45.4202C94.667 45.4549 94.4471 45.4884 94.0617 45.4884C93.8901 45.4884 93.6818 45.4744 93.4344 45.4441C93.3114 45.4161 93.1939 45.3483 93.0778 45.211C93.0076 45.128 92.9186 44.9205 92.9186 44.4723V37.3089H95.281C95.6898 37.3089 96.0606 37.1715 96.3515 36.8806L96.3593 36.8728L96.3667 36.8647C96.6373 36.5696 96.7797 36.2106 96.7797 35.8101C96.7797 35.4013 96.6423 35.0305 96.3515 34.7396C96.0606 34.4488 95.6898 34.3114 95.281 34.3114H92.9186V32.7873C92.9186 32.516 92.8139 32.2765 92.633 32.0884C92.4505 31.8795 92.2021 31.7712 91.9279 31.7712C91.706 31.7712 91.505 31.8474 91.3381 31.9786C91.2662 32.0327 91.1963 32.0895 91.1392 32.1463L87.7042 35.5055C87.462 35.7037 87.3047 35.9803 87.3047 36.3182C87.3047 36.5574 87.3648 36.8301 87.5741 37.0394C87.7835 37.2487 88.0562 37.3089 88.2954 37.3089H89.5909V44.6755C89.5909 45.5059 89.7341 46.2249 90.0683 46.7889C90.376 47.3081 90.7659 47.7168 91.2461 47.9882Z"
        fill="#1F1F1F"
      />
      <path
        d="M106.735 37.8443L106.74 37.8512L106.745 37.8579C107.088 38.28 107.292 38.9055 107.292 39.7983V46.7585C107.292 47.21 107.452 47.6117 107.771 47.9306C108.09 48.2495 108.492 48.4096 108.943 48.4096C109.392 48.4096 109.791 48.2518 110.109 47.9372C110.444 47.6225 110.62 47.2193 110.62 46.7585V39.5443C110.62 38.563 110.429 37.6579 110.038 36.8379C109.644 35.9957 109.067 35.3214 108.309 34.8277C107.534 34.3058 106.611 34.0574 105.565 34.0574C104.358 34.0574 103.314 34.3188 102.466 34.8773C102.068 35.1367 101.695 35.414 101.348 35.7093V30.2725C101.348 29.8031 101.178 29.3887 100.837 29.0683C100.519 28.7538 100.12 28.5959 99.6714 28.5959C99.2199 28.5959 98.8182 28.756 98.4993 29.0749C98.175 29.3992 98.0202 29.8121 98.0202 30.2725V46.7585C98.0202 47.21 98.1803 47.6117 98.4993 47.9306C98.8182 48.2495 99.2199 48.4096 99.6714 48.4096C100.12 48.4096 100.519 48.2518 100.837 47.9372C101.172 47.6225 101.348 47.2193 101.348 46.7585V39.3171C101.802 38.7665 102.324 38.2766 102.916 37.8472C103.499 37.4249 104.188 37.2073 105.006 37.2073C105.917 37.2073 106.444 37.46 106.735 37.8443Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.075 46.6298L114.078 46.6325L114.08 46.6351C115.367 47.9214 117.126 48.5366 119.291 48.5366C120.416 48.5366 121.347 48.4338 122.061 48.2082L122.065 48.207L122.069 48.2058C122.757 47.9763 123.396 47.6928 123.985 47.354C124.224 47.2233 124.429 47.0544 124.593 46.8445C124.794 46.5922 124.905 46.2965 124.905 45.971C124.905 45.5479 124.749 45.1725 124.436 44.8853C124.141 44.6147 123.782 44.4723 123.381 44.4723C123.142 44.4723 122.911 44.5205 122.697 44.6275C122.253 44.8494 121.785 45.0323 121.291 45.1761C120.822 45.2964 120.226 45.3614 119.495 45.3614C118.204 45.3614 117.297 45.0435 116.693 44.4835C116.184 43.9971 115.834 43.3816 115.653 42.6179H123.661C124.126 42.6179 124.549 42.4703 124.865 42.1318C125.179 41.8141 125.337 41.4149 125.337 40.9668C125.337 39.7602 125.085 38.6342 124.575 37.5963C124.079 36.5489 123.34 35.7004 122.368 35.0577C121.371 34.3815 120.167 34.0574 118.783 34.0574C117.301 34.0574 116.037 34.3974 115.033 35.1178C114.057 35.8173 113.337 36.7318 112.878 37.8515C112.428 38.9319 112.204 40.082 112.204 41.297C112.204 43.523 112.807 45.3243 114.075 46.6298ZM121.445 38.3929L121.448 38.397L121.451 38.4012C121.704 38.7387 121.885 39.1733 121.977 39.7221H115.614C115.704 39.1693 115.879 38.7318 116.122 38.3929C116.465 37.9165 116.871 37.5791 117.342 37.3649C117.844 37.1369 118.323 37.0294 118.783 37.0294C119.244 37.0294 119.723 37.1369 120.224 37.3649C120.695 37.5791 121.102 37.9165 121.445 38.3929Z"
        fill="#1F1F1F"
      />
      <path
        d="M131.764 31.6119L136.748 46.9206L136.75 46.9254C136.912 47.3908 137.171 47.7791 137.552 48.0408C137.905 48.284 138.302 48.4096 138.728 48.4096C139.185 48.4096 139.6 48.2586 139.958 47.9704C140.319 47.6924 140.577 47.338 140.712 46.9115L144.317 35.491L147.922 46.9115C148.055 47.3314 148.3 47.6852 148.645 47.9657L148.651 47.9704L148.657 47.9749C149.027 48.2578 149.447 48.4096 149.905 48.4096C150.332 48.4096 150.729 48.284 151.082 48.0408C151.463 47.7791 151.722 47.3908 151.884 46.9254L151.886 46.9206L156.87 31.6119L156.873 31.6C156.924 31.4127 156.963 31.2269 156.967 31.063C157.008 30.6095 156.869 30.1959 156.532 29.8807C156.209 29.5613 155.799 29.4088 155.342 29.4088C154.955 29.4088 154.602 29.5331 154.305 29.7806C154.021 30.0171 153.825 30.3227 153.713 30.6798L153.713 30.6816L149.929 43.1161L146.125 30.7388L146.124 30.7358C146.007 30.3391 145.77 30.0174 145.428 29.7846C145.104 29.5352 144.728 29.4088 144.317 29.4088C143.901 29.4088 143.518 29.5381 143.181 29.7848C142.85 30.0215 142.625 30.3449 142.509 30.7358L142.509 30.7388L138.705 43.1161L134.925 30.6946C134.831 30.3311 134.642 30.0199 134.354 29.7806C134.051 29.5282 133.688 29.4088 133.292 29.4088C132.844 29.4088 132.445 29.5665 132.127 29.8807C131.795 30.1912 131.646 30.5974 131.667 31.0487C131.669 31.2161 131.708 31.4073 131.761 31.6L131.764 31.6119Z"
        fill="#1F1F1F"
      />
      <path
        d="M176.692 37.8715L176.696 37.8692L176.7 37.8667C177.294 37.4958 177.953 37.3089 178.692 37.3089H178.819C179.267 37.3089 179.675 37.1706 180.002 36.8693C180.334 36.5622 180.495 36.1603 180.495 35.7085C180.495 35.2604 180.338 34.8613 180.023 34.5436C179.717 34.216 179.318 34.0574 178.87 34.0574H178.743C177.761 34.0574 176.882 34.3335 176.128 34.8944C175.814 35.128 175.52 35.3751 175.248 35.6358C175.2 35.2714 175.034 34.9499 174.758 34.6886C174.444 34.3576 174.043 34.1844 173.586 34.1844C173.125 34.1844 172.722 34.36 172.407 34.6954C172.093 35.0133 171.935 35.4126 171.935 35.8609V46.7585C171.935 47.21 172.095 47.6117 172.414 47.9306C172.733 48.2495 173.134 48.4096 173.586 48.4096C174.034 48.4096 174.433 48.2518 174.751 47.9372C175.087 47.6225 175.262 47.2193 175.262 46.7585V39.1941C175.647 38.6531 176.123 38.2133 176.692 37.8715Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.64 47.6837L189.642 47.6847L189.644 47.6856C190.662 48.2494 191.729 48.5366 192.837 48.5366C193.799 48.5366 194.68 48.3547 195.468 47.9793C195.914 47.7671 196.333 47.5064 196.727 47.1984C196.799 47.4723 196.94 47.7196 197.151 47.9306L197.158 47.9373L197.165 47.9437C197.495 48.2505 197.898 48.4096 198.349 48.4096C198.8 48.4096 199.202 48.2495 199.521 47.9306C199.84 47.6117 200 47.21 200 46.7585V30.2725C200 29.8121 199.845 29.3992 199.521 29.0749C199.202 28.756 198.8 28.5959 198.349 28.5959C197.898 28.5959 197.495 28.755 197.165 29.0619L197.158 29.0683L197.151 29.0749C196.827 29.3992 196.672 29.8121 196.672 30.2725V35.3531C196.295 35.0635 195.893 34.8171 195.468 34.6148C194.68 34.2393 193.799 34.0574 192.837 34.0574C191.729 34.0574 190.662 34.3446 189.644 34.9084L189.642 34.9094L189.64 34.9103C188.626 35.4819 187.795 36.3084 187.145 37.3745L187.143 37.3772L187.141 37.3799C186.492 38.4742 186.181 39.788 186.181 41.297C186.181 42.806 186.492 44.1198 187.141 45.2141L187.143 45.2169L187.145 45.2196C187.795 46.2856 188.626 47.1122 189.64 47.6837ZM195.183 37.7286L195.187 37.731L195.191 37.7333C195.758 38.0309 196.251 38.3969 196.672 38.8307V43.7636C196.25 44.1999 195.756 44.575 195.187 44.8884C194.59 45.2011 193.92 45.3614 193.167 45.3614C192.087 45.3614 191.219 45.0167 190.527 44.3398C189.874 43.6858 189.509 42.7 189.509 41.297C189.509 39.8729 189.876 38.8908 190.525 38.2562C191.218 37.578 192.086 37.2327 193.167 37.2327C193.917 37.2327 194.586 37.4003 195.183 37.7286Z"
        fill="#1F1F1F"
      />
      <path
        d="M68.7484 35.7085C68.7484 35.2604 68.5907 34.8614 68.2764 34.5436C67.9703 34.216 67.571 34.0574 67.1226 34.0574H66.9956C66.0139 34.0574 65.1353 34.3335 64.3809 34.8944C64.0667 35.128 63.7733 35.3751 63.501 35.6358C63.453 35.2714 63.2868 34.9499 63.0113 34.6887C62.6975 34.3576 62.2966 34.1844 61.839 34.1844C61.3782 34.1844 60.975 34.36 60.6602 34.6955C60.3457 35.0134 60.1878 35.4126 60.1878 35.8609V46.7585C60.1878 47.21 60.3479 47.6117 60.6669 47.9306C60.9858 48.2495 61.3875 48.4096 61.839 48.4096C62.2873 48.4096 62.6866 48.2518 63.0044 47.9372C63.3399 47.6225 63.5155 47.2193 63.5155 46.7585V39.1941C63.9001 38.6531 64.3757 38.2133 64.9454 37.8715L64.9494 37.8692L64.9533 37.8667C65.5467 37.4958 66.2063 37.3089 66.9448 37.3089H67.0718C67.5203 37.3089 67.9283 37.1706 68.2547 36.8693C68.5874 36.5622 68.7484 36.1603 68.7484 35.7085Z"
        fill="#1F1F1F"
      />
      <path
        d="M181.63 47.9306C181.949 48.2495 182.351 48.4096 182.802 48.4096C183.262 48.4096 183.675 48.2549 184 47.9306C184.319 47.6117 184.479 47.21 184.479 46.7585V30.2725C184.479 29.8121 184.324 29.3992 184 29.0749C183.675 28.7507 183.262 28.5959 182.802 28.5959C182.351 28.5959 181.949 28.756 181.63 29.0749C181.306 29.3992 181.151 29.8121 181.151 30.2725V46.7585C181.151 47.21 181.311 47.6117 181.63 47.9306Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9314 48.1556C54.9297 48.1556 58.171 44.9143 58.171 40.916C58.171 36.9177 54.9297 33.6764 50.9314 33.6764C46.9331 33.6764 43.6918 36.9177 43.6918 40.916C43.6918 44.9143 46.9331 48.1556 50.9314 48.1556ZM50.9314 44.8533C53.1059 44.8533 54.8687 43.0905 54.8687 40.916C54.8687 38.7415 53.1059 36.9786 50.9314 36.9786C48.7569 36.9786 46.9941 38.7415 46.9941 40.916C46.9941 43.0905 48.7569 44.8533 50.9314 44.8533Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.449 40.916C170.449 44.9143 167.207 48.1556 163.209 48.1556C159.211 48.1556 155.97 44.9143 155.97 40.916C155.97 36.9177 159.211 33.6764 163.209 33.6764C167.207 33.6764 170.449 36.9177 170.449 40.916ZM167.146 40.916C167.146 43.0905 165.384 44.8533 163.209 44.8533C161.035 44.8533 159.272 43.0905 159.272 40.916C159.272 38.7415 161.035 36.9786 163.209 36.9786C165.384 36.9786 167.146 38.7415 167.146 40.916Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};

export default Logo;
