import React from 'react';

import FileUpload from '../../framework-components/fileUpload/FileUpload';

const FileUploadExample: React.FC = () => {
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">File Upload</h2>
      <FileUpload
        accepted_extensions={['pdf', 'png', 'jpg']}
        max_file_size={10}
      />
    </div>
  );
};

export default FileUploadExample;
