import React, { useState, useCallback } from 'react';

import { Option } from '../../types';
import DropdownInner from './DropdownInner';
import ErrorText from '../../components/error-text';

interface Props<T> {
  options: ReadonlyArray<Option<T>> | undefined | null;
  onSelect: (option: Option<T> | null) => void;
  id?: string;
  name: string;
  label?: string | React.ReactNode;
  isLoading?: boolean;
  row?: boolean;
  placeholder?: string;
  errors?: Array<string>;
  value: Option<T> | null | undefined;
  labelClassName?: string;
  inputClassNameOverride?: string;
  zIndex?: number;
  isDisabled?: boolean;
}

const LocalDropdown = <T,>({
  options,
  onSelect,
  id,
  name,
  label,
  isLoading,
  row,
  placeholder,
  errors = [],
  value,
  labelClassName,
  inputClassNameOverride,
  zIndex,
  isDisabled,
}: Props<T>) => {
  const [query, setQuery] = useState('');
  const hasErrors = errors.length > 0;

  const filteredOptions =
    query === '' && options
      ? options
      : options &&
        options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const selectHandler = useCallback(
    (option: Option<T> | null) => {
      onSelect(option);
    },
    [onSelect]
  );

  const displayErrors = () => {
    if (!hasErrors) return;
    return <ErrorText className="text-sm">{errors}</ErrorText>;
  };

  return (
    <div>
      <DropdownInner
        query={query}
        setQuery={setQuery}
        filteredOptions={filteredOptions}
        selectedOption={value ? value : null}
        selectHandler={selectHandler}
        id={id}
        name={name}
        label={label}
        isLoading={isLoading ? isLoading : false}
        row={row}
        placeholder={placeholder}
        labelClassName={labelClassName}
        inputClassNameOverride={inputClassNameOverride}
        zIndex={zIndex}
        isDisabled={isDisabled}
      />
      {displayErrors()}
    </div>
  );
};

export default LocalDropdown;
