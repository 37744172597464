import React from 'react';
import { useParams } from 'react-router';

import AdminStandardPage from '../../common/AdminStandardPage';
import ApplicantContactBox from './ApplicantContactBox';
import { BasicBookingApplicantProfileInfo } from './types';
import { useAPI } from '../../../../api/api';
import TwoColumns from '../../common/layout/TwoColumns';
import TabbedWidget from '../../../../framework-components/tabbedContainer/TabbedWidget';
import AccomodationPlacementDetailsSection from './AccomodationPlacementDetailsSection';
import HospitalPlacementDetailsSection from './HospitalPlacementDetailsSection';
import AddonDetailsSection from './AddonDetailsSection';
import FlightDetailsSection from './FlightDetailsSection';
import ImpersonateLoginSection from './ImpersonateLoginSection';
import { formatDateToDayDateMonthYear } from '../../../../utils/dates';
import ApplicantDownloadDocumentsSection from './ApplicantDownloadDocumentsSection';
import Notes from '../../../../framework-components/notes/Notes';
import ApplicantProgressTracker from './ApplicantProgressTracker';
import Card from '../../../../framework-components/card/Card';
import { NoteEntityType } from '../../../../framework-components/notes/types';
import NotePreview from '../../../../framework-components/notes/NotePreview';
import { Note } from '../../common/types';
import { PencilEditLink } from '../../../../framework-components/button/PencilEditButton';

const renderNotePreview = (note: Note) => {
  return (
    <NotePreview
      key={note.id}
      author={note.author}
      created={note.created}
      content={note.content}
      tag={note.tag}
    />
  );
};

const ApplicantProfilePage = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { data, error, isValidating } =
    useAPI<BasicBookingApplicantProfileInfo>(
      `/api/admin/bookings/${bookingId}/basic-profile-page-info/`
    );

  const applicantNameAndReferenceOrBlank = (function () {
    if (!data) {
      return '';
    }

    return `${data.applicant.first_name} ${data.applicant.last_name} (${data.applicant.reference})`;
  })();

  const renderPlacementDetailsText = () => {
    if (!data) {
      return '';
    }

    return `${data.basis_display} in ${
      data.discipline.name
    } (From ${formatDateToDayDateMonthYear(
      data.start_date
    )} to ${formatDateToDayDateMonthYear(data.end_date)})`;
  };

  return (
    <AdminStandardPage
      title={applicantNameAndReferenceOrBlank}
      breadcrumbs={[
        { label: 'Bookings', href: '/admin' },
        { label: 'Confirmed bookings', href: '/admin' },
        { label: 'Student profile', href: null },
      ]}
      isLoading={isValidating}
      error={error ? 'Failed to load page data' : undefined}
      enableHome={false}
    >
      <div className="space-y-8 mt-2">
        <div className="flex gap-x-3">
          <div className="flex gap-x-2">
            <p>Status:</p>
            <p className="text-md-bold">Confirmed</p>
          </div>

          <div className="flex gap-x-2">
            <p>Sold by:</p>
            {data && data.sold_by ? (
              <p className="text-md-bold">
                {data.sold_by.first_name} {data.sold_by.last_name}
              </p>
            ) : (
              '-'
            )}
          </div>
        </div>

        <div className="w-full flex justify-center">
          <ApplicantProgressTracker />
        </div>

        <ApplicantContactBox />

        <TwoColumns
          leftColumn={
            <div className="max-w-[693px]">
              <TabbedWidget
                variant="pilled_tab"
                tabs={[
                  {
                    title: 'Placement',
                    showWarningIcon: true,
                    content: (
                      <>
                        <Card className="space-y-7 bg-white">
                          <h1 className="text-h4">Placement details:</h1>
                          <h2 className="text-sm">
                            {renderPlacementDetailsText()}
                          </h2>

                          <AccomodationPlacementDetailsSection
                            bookingId={bookingId}
                          />
                          <HospitalPlacementDetailsSection
                            bookingId={bookingId}
                          />
                          <AddonDetailsSection bookingId={bookingId} />
                          <PencilEditLink to="#" />
                        </Card>

                        <FlightDetailsSection bookingId={bookingId} />

                        <div className="border p-5 space-y-7 bg-white rounded-lg">
                          <h1 className="text-h4">Documents:</h1>

                          <ApplicantDownloadDocumentsSection
                            bookingId={bookingId}
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    title: 'Education & Experience',
                    showWarningIcon: true,
                    content: <div>Education & Experience</div>,
                  },
                  {
                    title: 'Finances',
                    content: <div>Finances</div>,
                  },
                  {
                    title: 'Info',
                    content: <div>Info</div>,
                  },
                ]}
              />
            </div>
          }
          rightColumn={
            <div className="max-w-[693px]">
              <Notes
                entityId={bookingId}
                entityType={NoteEntityType.BOOKING}
                renderAction={renderNotePreview}
              />
              <ImpersonateLoginSection bookingId={bookingId} />
            </div>
          }
        />
      </div>
    </AdminStandardPage>
  );
};

export default ApplicantProfilePage;
