import React from 'react';

import EditableDescriptionList from './EditableDescriptionList';
import { ReviewPersonalDetailsData } from './types';

interface Props {
  data: ReviewPersonalDetailsData;
}

const ReviewPersonalDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Your Personal Details"
        items={[
          {
            label: 'First Name',
            value: data.first_name,
          },
          {
            label: 'Last Name',
            value: data.last_name,
          },
          {
            label: 'Email address',
            value: data.email,
          },
        ]}
      />
    </div>
  );
};

export default ReviewPersonalDetails;
