import React from 'react';

import { GetUserIdData } from './types';
import TabbedWidgetSection from '../../../../framework-components/tabbedContainer/TabbedWidgetSection';
import { RoundedSmallButtonPrimary } from '../../../../framework-components/button/Button';
import { useAPI } from '../../../../api/api';

interface Props {
  bookingId?: string;
}

const ImpersonateLoginSection: React.FC<Props> = ({ bookingId }) => {
  const { data } = useAPI<GetUserIdData>(
    `/api/admin/bookings/${bookingId}/get-user-id/`
  );

  if (!data) return null;

  const userId = data.user_id;

  return (
    <TabbedWidgetSection className="bg-wtw-gray-2">
      <h3 className="text-h5">Impersonate User</h3>
      {userId ? (
        <RoundedSmallButtonPrimary
          onClick={() => (window.location.href = `/impersonate/${userId}/`)}
          containerClassName="space-x-2.5"
        >
          <span>Impersonate</span>
        </RoundedSmallButtonPrimary>
      ) : (
        <div> Applicant does not have a user. </div>
      )}
    </TabbedWidgetSection>
  );
};

export default ImpersonateLoginSection;
