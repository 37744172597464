import { format, parseISO } from 'date-fns';

export const formatDateToReadableString = (date: string) => {
  return format(date, 'MMMM do, yyyy');
};

export const dateToYearMonthDayFormat = (date: Date) => {
  return format(parseISO(date.toISOString()), 'yyyy-MM-dd');
};

export const formatDateToLongDateFormatWithOridinalDay = (date: string) => {
  return format(date, 'do MMMM yyyy');
};

export const formatDateToDayDateMonthYear = (date: string) => {
  return format(date, 'EEE dd MMM yyyy');
};

export const formatDateTimeShorthandMonthDayYear = (date: string) => {
  return format(date, 'MMM do, yyyy, HH:mm');
};

export const isValidMonth = (month: number): boolean =>
  month >= 1 && month <= 12;

export const getDatesInRange = (start: Date, end: Date) => {
  const dates = [];

  while (start <= end) {
    dates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }

  return dates;
};

export const sortDatesIntoMonths = (dates: ReadonlyArray<Date>) => {
  const months: Record<string, Date[]> = {};

  dates.forEach((date) => {
    const month = format(date, 'MMMM');
    if (!months[month]) {
      months[month] = [];
    }
    months[month].push(date);
  });

  return months;
};

export const getEndDate = (startDate: Date, period: number) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + period);
  return endDate;
};

export const calculateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const timeDifferenceMilliseconds: number = Math.abs(
    endDate.getTime() - startDate.getTime()
  );
  const daysDifference: number = Math.ceil(
    timeDifferenceMilliseconds / (1000 * 60 * 60 * 24)
  );
  return daysDifference;
};

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
