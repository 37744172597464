import React from 'react';

import ImageGallery from '../admin/common/ImageGallery/ImageGallery';
import { images } from '../admin/directories/houses/images';
import { Image } from '../admin/common/ImageGallery/types';

const ImageGalleryExample: React.FC = () => {
  const getImages: () => Promise<Image[]> = async () => {
    return Promise.resolve(images);
  };

  const setMainImage = (imageId: string): Promise<Image[]> => {
    return Promise.resolve(
      images.map((image) => ({
        ...image,
        isMain: image.id === imageId,
      }))
    );
  };

  const deleteImage = (imageId: string): Promise<Image[]> => {
    const image = images.find((img) => img.id === imageId);
    if (!image) {
      return Promise.resolve(images);
    }
    const imageIdx = images.indexOf(image);
    images.splice(imageIdx, 1);
    return Promise.resolve(images.filter((img) => img.id !== imageId));
  };

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Image Gallery</h2>
      <ImageGallery
        title="Main house photo:"
        getImages={getImages}
        setMainImage={setMainImage}
        deleteImage={deleteImage}
        onSuccessfulUpload={(file_id: string) => {
          console.log('File uploaded:', file_id); // eslint-disable-line no-console
          // TODO: Call API to add the image to the house
        }}
      />
    </div>
  );
};

export default ImageGalleryExample;
