import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoggedInUserContext } from '../context-providers/logged-in-user';

const LoggedInLayout: React.FC = () => {
  const user = useContext(LoggedInUserContext);

  if (!user) {
    return <Navigate to="login" />;
  }

  return <Outlet />;
};

export default LoggedInLayout;
