import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Spinner from '../spinner';
import { ChangePageHandler } from './types';

interface PageButtonProps {
  currentPage: number;
  selectPageNumber: (e: React.MouseEvent<HTMLButtonElement>) => void;
  item: { value: number; label: number };
}

const PageButton: React.FC<PageButtonProps> = ({
  currentPage,
  selectPageNumber,
  item,
}) => (
  <button
    key={item.value}
    className={classNames(
      'relative inline-flex items-center px-sm py-xs text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
      'rounded-full w-9 h-9 justify-center',
      {
        'z-10 bg-indigo-600 ring-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-indigo-700 hover:text-white':
          currentPage === item.value,
      }
    )}
    onClick={selectPageNumber}
    value={item.value}
  >
    {item.label}
  </button>
);

interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  direction: 'Previous' | 'Next';
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  onClick,
  disabled,
  direction,
}) => (
  <button
    onClick={onClick}
    className={classNames(
      'relative inline-flex items-center px-xs py-xs text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
      { 'cursor-not-allowed': disabled },
      'rounded-full w-9 h-9 justify-center'
    )}
    disabled={disabled}
  >
    <span className="sr-only">{direction}</span>
    {direction === 'Previous' && (
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="h-xl w-xl"
        aria-hidden="true"
      />
    )}
    {direction === 'Next' && (
      <FontAwesomeIcon
        icon={faArrowRight}
        className="h-xl w-xl"
        aria-hidden="true"
      />
    )}
  </button>
);

const Ellipsis: React.FC = () => (
  <span className="relative inline-flex items-center px-sm py-xs text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0 rounded-full w-2xl w-9 h-9 justify-center">
    ...
  </span>
);

interface PageInfoProps {
  currentPage: number;
  totalCount: number;
  pageSize: number;
}

const PageInfo: React.FC<PageInfoProps> = ({
  currentPage,
  totalCount,
  pageSize,
}) => {
  const currentPageCount = currentPage * pageSize;
  return (
    <div>
      <p className="text-sm text-gray-700">
        Showing{' '}
        <span className="font-medium">{1 + (currentPage - 1) * pageSize}</span>{' '}
        to{' '}
        <span className="font-medium">
          {totalCount < currentPageCount ? totalCount : currentPageCount}
        </span>{' '}
        of <span className="font-medium">{totalCount}</span> results
      </p>
    </div>
  );
};

interface PaginationBarProps {
  currentPage: number;
  totalPages: number;
  onChangePage: ChangePageHandler;
  isLoading?: boolean;
  totalCount: number;
  pageSize: number;
}

const PaginationBar: React.FC<PaginationBarProps> = ({
  currentPage,
  totalPages,
  onChangePage,
  isLoading,
  totalCount,
  pageSize,
}) => {
  const onClickNextPage = useCallback(
    () => onChangePage(currentPage + 1),
    [currentPage, onChangePage]
  );
  const onClickPrevPage = useCallback(
    () => onChangePage(currentPage - 1),
    [currentPage, onChangePage]
  );

  const selectPageNumber = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const value = (e.target as HTMLInputElement).value;
      onChangePage(Number(value));
    },
    [onChangePage]
  );

  const pageItems = [...Array(totalPages).keys()].map((index) => {
    return { value: index + 1, label: index + 1 };
  });

  return (
    <div className="flex grow items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      {isLoading ? (
        <Spinner className="w-6 h-6 text-gray-500" />
      ) : (
        <>
          <div className="flex flex-1 justify-between sm:hidden">
            <ArrowButton
              onClick={onClickPrevPage}
              disabled={currentPage === 1}
              direction="Previous"
            />
            <ArrowButton
              onClick={onClickNextPage}
              disabled={currentPage === totalPages}
              direction="Next"
            />
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <PageInfo
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
            />
            <div>
              <nav
                className="isolate inline-flex space-x-md"
                aria-label="Pagination"
              >
                <ArrowButton
                  onClick={onClickPrevPage}
                  disabled={currentPage === 1}
                  direction="Previous"
                />
                {totalPages <= 6 &&
                  pageItems.map((item) => {
                    return (
                      <PageButton
                        key={item.value}
                        item={item}
                        selectPageNumber={selectPageNumber}
                        currentPage={currentPage}
                      />
                    );
                  })}
                {totalPages > 6 && (
                  <>
                    <PageButton
                      item={pageItems[0]}
                      selectPageNumber={selectPageNumber}
                      currentPage={currentPage}
                    />
                    {![1, totalPages - 2, totalPages - 1, totalPages].includes(
                      currentPage
                    ) && (
                      <PageButton
                        item={pageItems[currentPage - 1]}
                        selectPageNumber={selectPageNumber}
                        currentPage={currentPage}
                      />
                    )}
                    <Ellipsis />
                    {pageItems.slice(totalPages - 3).map((item) => (
                      <PageButton
                        key={item.value}
                        item={item}
                        selectPageNumber={selectPageNumber}
                        currentPage={currentPage}
                      />
                    ))}
                  </>
                )}
                <ArrowButton
                  onClick={onClickNextPage}
                  disabled={currentPage === totalPages}
                  direction="Next"
                />
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaginationBar;
