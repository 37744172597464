import React from 'react';

interface Props {
  detail?: string;
}

const ErrorOverlay: React.FC<Props> = (props: Props) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
      <div className="flex flex-col gap-y-4 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-24 h-24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>

        <h1 className="text-h4">There has been an unexpected error</h1>

        {props.detail && <p>{props.detail}</p>}

        <p>Try refreshing the page or please try again later.</p>
      </div>
    </div>
  );
};

export default ErrorOverlay;
