export const EXPERIENCE = {
  VILLAGE: 'VILLAGE' as const,
  AYURVEDA: 'AYURVEDA' as const,
};

export const EDUCATION_STATUS = {
  STUDENT: 'STUDENT' as const,
  PROFESSIONAL: 'PROFESSIONAL' as const,
};

export const GENDER = {
  MALE: 'MALE' as const,
  FEMALE: 'FEMALE' as const,
  OTHER: 'OTHER' as const,
};

export const PAYMENT_METHOD = {
  BANK_TRANSFER: 'BANK_TRANSFER' as const,
};

export const SECTIONS = {
  YOUR_DETAILS: 'YOUR_DETAILS' as const,
  REVIEW: 'REVIEW' as const,
  SUMMARY: 'SUMMARY' as const,
};

export const MAX_YEARS_OF_GRADUATION = 50;
