import React from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';

const HospitalDirectories: React.FC = () => {
  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Hospitals',
          href: null,
        },
      ]}
      title="Hospitals"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Destinations',
              href: '/admin/directories/destinations',
              current: false,
            },
            {
              label: 'Hospitals',
              href: '/admin/directories/hospitals',
              current: true,
            },
            {
              label: 'Accommodation',
              current: false,
            },
            {
              label: 'Associations',
              current: false,
            },
            {
              label: 'Organisations',
              current: false,
            },
          ]}
          variant="underlined"
        />
      </div>
    </AdminStandardPage>
  );
};

export default HospitalDirectories;
