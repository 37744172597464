import React from 'react';
import { Route } from 'react-router-dom';

import ForgotPassword from './components/auth/forgot-password';
import ForgotPasswordEmailSent from './components/auth/forgot-password-email-sent';
import Login from './components/auth/login';
import Registration from './components/auth/registration';
import RegistrationEmailSent from './components/auth/registration-email-sent';
import ResetPassword from './components/auth/reset-password';
import NotFound from './components/not-found';
import Landing from './components/landing/landing';
import LoggedInLayout from './components/logged-in-layout';
import LoggedOutLayout from './components/logged-out-layout';
import StyleGuide from './components/styleguide';
import UpdateEnquiryPage from './components/booking/booking-form/UpdateEnquiryPage';
import BookingReview from './components/booking/booking-review';
import BookingFormPage from './components/booking/booking-form';
import OrderSummaryPage from './components/booking/order-summary';
import AdminPageContainer from './components/admin/common/AdminPageContainer';
import DashboardNewEnquiryPage from './components/admin/enquiries/dashboards/DashboardNewEnquiryPage';
import DashboardOnGoingEnquiryPage from './components/admin/enquiries/dashboards/DashboardOnGoingEnquiryPage';
import ApplicantProfilePage from './components/admin/bookings/applicant-profile/ApplicantProfilePage';
import UnsubscribeReminder from './components/booking/unsubscribe-reminder';
import DepartmentCapacity from './components/admin/capacities/department';
import PendingBookings from './components/admin/bookings/booking-list/pending';
import ConfirmedBookings from './components/admin/bookings/booking-list/confirmed';
import OnTripBookings from './components/admin/bookings/booking-list/on-trip';
import PreparationList from './components/my-trip/PreparationList';
import MyTripContainer from './components/my-trip/layout/MyTripContainer';
import ApplicantArrivals from './components/admin/bookings/booking-list/arrivals/ApplicantArrivals';
import ApplicantDepartures from './components/admin/bookings/booking-list/departures/ApplicantDepartures';
import HouseAndVillageCapacities from './components/admin/capacities/house';
import UniversityDirectories from './components/admin/directories/universities/UniversityDirectories';
import HospitalDirectories from './components/admin/directories/hospitals/HospitalDirectories';
import DestinationsDirectories from './components/admin/directories/destinations/DestinationsDirectories';
import StaffListing from './components/admin/StaffListing';
import AllAlumniListing from './components/alumni/all/AllAlumniListing';
import AlumniSurveyResults from './components/alumni/survey-results/AlumniSurveyResults';
import AlumniAggregateSurveyResults from './components/alumni/aggregate-survey-results/AggregateSurveyResults';
import RoomCapacity from './components/admin/capacities/room';
import HouseList from './components/admin/directories/houses';
import HouseDetailPage from './components/admin/directories/houses/HouseDetail';
import UniversityDetail from './components/admin/university/UniversityDetail';

const router = (
  <>
    <Route path="*" element={<NotFound />} />
    <Route path="booking-form" element={<BookingFormPage />} />
    <Route path="booking-form/:id" element={<UpdateEnquiryPage />} />
    <Route path="booking-review/:uid" element={<BookingReview />} />
    <Route path="order-summary/:id" element={<OrderSummaryPage />} />
    <Route
      path="unsubscribe-enquiry-reminder/:id"
      element={<UnsubscribeReminder />}
    />
    <Route element={<LoggedOutLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="forgot-password-email-sent"
        element={<ForgotPasswordEmailSent />}
      />
      <Route path="register" element={<Registration />} />
      <Route
        path="registration-email-sent"
        element={<RegistrationEmailSent />}
      />
      <Route path="reset-password/:uid/:token" element={<ResetPassword />} />
    </Route>
    <Route element={<LoggedInLayout />}>
      <Route path="/" element={<Landing />} />
      <Route path="styleguide" element={<StyleGuide />} />
      <Route path="admin" element={<AdminPageContainer />}>
        <Route path="enquiries">
          <Route path="new" element={<DashboardNewEnquiryPage />} />
          <Route path="ongoing" element={<DashboardOnGoingEnquiryPage />} />
        </Route>
        <Route path="bookings">
          <Route
            path=":bookingId/applicant-profile/"
            element={<ApplicantProfilePage />}
          />
          <Route path="pending" element={<PendingBookings />} />
          <Route path="confirmed" element={<ConfirmedBookings />} />
          <Route path="on-trip" element={<OnTripBookings />} />
          <Route path="arrivals" element={<ApplicantArrivals />} />
          <Route path="departures" element={<ApplicantDepartures />} />
        </Route>
        <Route path="capacities">
          <Route
            path="department-capacities"
            element={<DepartmentCapacity />}
          />
          <Route path="room-capacities" element={<RoomCapacity />} />
          <Route
            path="house-village-capacities"
            element={<HouseAndVillageCapacities />}
          />
        </Route>
        <Route path="directories">
          <Route path="universities" element={<UniversityDirectories />} />
          <Route path="destinations" element={<DestinationsDirectories />} />
          <Route path="hospitals" element={<HospitalDirectories />} />
          <Route path="houses">
            <Route path="" element={<HouseList />} />
            <Route path=":id" element={<HouseDetailPage />} />
          </Route>
        </Route>
        <Route path="university">
          <Route path=":universityId/" element={<UniversityDetail />} />
        </Route>
        <Route path="alumni">
          <Route path="all" element={<AllAlumniListing />} />
          <Route path="survey-results" element={<AlumniSurveyResults />} />
          <Route
            path="aggregate-survey-results"
            element={<AlumniAggregateSurveyResults />}
          />
        </Route>
        <Route path="staff-listing" element={<StaffListing />} />
      </Route>
      <Route path="my-trip" element={<MyTripContainer />}>
        <Route path="preparation-list" element={<PreparationList />} />
      </Route>
    </Route>
  </>
);

export default router;
