import React from 'react';

import BlockTabs from '../../framework-components/blocktabs/BlockTabs';
import AdminStandardPage from './common/AdminStandardPage';

const StaffListing: React.FC = () => {
  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Admin',
          href: null,
        },
        {
          label: 'Staff listing',
          href: null,
        },
      ]}
      title="Staff listing"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Room',
              href: '/admin/staff-listing',
              current: true,
            },
            {
              label: 'User Management',
              href: '',
              current: false,
            },
          ]}
          variant="underlined"
        />
      </div>
    </AdminStandardPage>
  );
};

export default StaffListing;
