import React from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

export const REGISTRATION_FEE = 300;

const PaymentDue: React.FC = () => {
  return (
    <div className="border bg-wtw-gray-2 rounded-lg p-md space-y-2xl">
      <div className="flex items-center gap-4">
        <ShoppingCartIcon className="hidden xs:block w-8 h-8 xs:w-9 xs:h-9 sm:w-10 sm:h-10" />
      </div>
      <div className="px-1 space-y-2">
        <h3 className="text-lg-bold text-wtw-logo-blue">REGISTRATION FEE:</h3>
        <h3 className="text-lg font-semibold flex gap-6 justify-between">
          <span>£{REGISTRATION_FEE}</span>
        </h3>
      </div>
      <div className="text-sm">
        <p>This fee secures your place.</p>
      </div>
    </div>
  );
};

export default PaymentDue;
