import React, { useCallback } from 'react';

import QuickSetDropdown from '../quick-set-dropdown/QuickSetDropdown';

const QuickSetDropDownExample: React.FC = () => {
  const mockServerRoundTrip = useCallback(() => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);

  const mockServerFailedRoundTrip = useCallback(() => {
    return new Promise<void>((_, reject) => {
      setTimeout(() => {
        reject();
      }, 1000);
    });
  }, []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">QuickSetDropdown</h2>

      <div className="flex flex-row gap-4 flex-wrap">
        <div className="space-y-4">
          <h1 className="text-sm font-semibold">
            Setter with no default value
          </h1>
          <QuickSetDropdown
            saveValue={mockServerRoundTrip}
            options={[
              { label: 'High', value: 'high' },
              { label: 'Medium', value: 'medium' },
              { label: 'Low', value: 'low' },
            ]}
          />
        </div>

        <div className="space-y-4">
          <h1 className="text-sm font-semibold">Setter with default value</h1>
          <QuickSetDropdown
            saveValue={mockServerRoundTrip}
            options={[
              { label: 'High', value: 'high' },
              { label: 'Medium', value: 'medium' },
              { label: 'Low', value: 'low' },
            ]}
            initialValue="low"
          />
        </div>

        <div className="space-y-4">
          <h1 className="text-sm font-semibold">Setter with failed request</h1>
          <QuickSetDropdown
            saveValue={mockServerFailedRoundTrip}
            options={[
              { label: 'High', value: 'high' },
              { label: 'Medium', value: 'medium' },
              { label: 'Low', value: 'low' },
            ]}
            initialValue="low"
          />
        </div>

        <div className="space-y-4">
          <h1 className="text-sm font-semibold">Setter with custom styling</h1>
          <QuickSetDropdown
            saveValue={mockServerRoundTrip}
            options={[
              {
                label: 'High',
                value: 'HIGH',
              },
              {
                label: 'Medium',
                value: 'MEDIUM',
              },
              {
                label: 'Low',
                value: 'LOW',
              },
            ]}
            selectedStyling={{
              HIGH: 'bg-wtw-enquiry-priority-high',
              MEDIUM: 'bg-wtw-enquiry-priority-medium',
              LOW: 'bg-wtw-enquiry-priority-low',
              NOT_SET:
                'text-wtw-enquiry-priority-notset border-wtw-enquiry-priority-notset',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default QuickSetDropDownExample;
