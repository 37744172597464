import React, { useCallback } from 'react';

import { Option } from '../../../../types';
import { GENDER } from '../../../booking/booking-form/constants';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Calendar from '../../../calendar/Calendar';
import SelectDates from '../SelectDates';
import EditSaveButtons from '../EditSaveButtons';
import { useCapacityCalendar } from '../hooks';
import { EventMetadata } from './types';
import { checkIsSectionDisabled } from './utils';
import { getEventForDay, onDropOnDay } from '../utils';
import { Event } from '../../../calendar/types';

interface Props {
  month: string | null;
  year: string | null;
  destination: Option | null;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const RoomCapacityCalendar: React.FC<Props> = ({
  month,
  year,
  destination,
  isEditing,
  setIsEditing,
}) => {
  const {
    selectedDates,
    setSelectedDates,
    events,
    setEvents,
    sections,
    errors,
    startDate,
    endDate,
    handleCancelEditing,
    handleSaveEvents,
  } = useCapacityCalendar<EventMetadata, null>(
    destination,
    month,
    year,
    `/api/admin/calendar/room-bookings/${destination?.value}/`,
    `/api/admin/calendar/room-sections/${destination?.value}/`,
    true,
    '/api/admin/calendar/update-room-bookings/',
    setIsEditing
  );

  const getOverlayColour = useCallback(
    (event: Event<EventMetadata>, light: boolean) => {
      if (event.metadata?.gender === GENDER.FEMALE) {
        if (light) {
          return 'bg-pink-100';
        }
        return 'bg-pink-300';
      }
      if (light) {
        return 'bg-green-100';
      }
      return 'bg-green-300';
    },
    []
  );

  if (errors.events || errors.sections) {
    return <ErrorOverlay detail={errors.events || errors.sections} />;
  }

  return (
    <div className="border border-gray-300 bg-white rounded-lg">
      <div className="flex flex-wrap justify-between items-center p-4">
        <SelectDates
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          isDisabled={isEditing}
        />
        <EditSaveButtons
          startDate={startDate}
          endDate={endDate}
          destination={destination}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleCancelEditing={handleCancelEditing}
          handleSaveEvents={handleSaveEvents}
        />
      </div>
      {startDate && endDate && destination && (
        <div className="p-4">
          <Calendar<EventMetadata, null>
            isEditing={isEditing}
            startDate={startDate}
            endDate={endDate}
            events={events}
            setEvents={setEvents}
            sections={sections}
            getEventForDay={getEventForDay}
            onDropOnDay={onDropOnDay}
            checkIsSectionDisabled={checkIsSectionDisabled}
            getOverlayColour={getOverlayColour}
          />
        </div>
      )}
      {!destination && (
        <div className="px-4 pb-4">Please select a location.</div>
      )}
      {(!startDate || !endDate) && (
        <div className="px-4 pb-4">Please select a year, month and period.</div>
      )}
    </div>
  );
};

export default RoomCapacityCalendar;
