import { Event, CalendarSection } from '../../../calendar/types';
import { EventMetadata, SectionMetadata } from './types';

export const checkIsSectionFull = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  sectionId: string,
  capacity: number
) => {
  const eventsOnDateInSectionWithDiscipline = events.filter(
    (e) =>
      e.id !== event.id &&
      event.start_date <= e.end_date &&
      event.end_date >= e.start_date &&
      e.sectionId === sectionId &&
      e.metadata?.discipline === event.metadata?.discipline
  );
  return eventsOnDateInSectionWithDiscipline.length >= capacity;
};

export const checkIsSectionDisabled = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  section: CalendarSection<SectionMetadata>
) => {
  if (!section.metadata?.disciplines) {
    return false;
  }

  const disciplineInSection = section.metadata.disciplines.find(
    (discipline) => discipline.id === event.metadata?.discipline
  );

  if (!disciplineInSection) {
    return true;
  }

  return checkIsSectionFull(
    event,
    events,
    section.id,
    disciplineInSection.capacity
  );
};
