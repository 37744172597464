import React from 'react';

import { ReviewTripDetailsData } from './types';
import EditableDescriptionList from './EditableDescriptionList';
import { booleanToYesNo, formatHospitalDuration } from './utils';
import { formatDateToReadableString } from '../../../utils/dates';
import {
  EDUCATION_STATUS_TO_DISPLAY_NAME,
  EXPERIENCE_TYPE_TO_DISPLAY_NAME,
} from '../booking-form/labels';
import { EducationStatusType } from '../booking-form/types';

interface Props {
  data: ReviewTripDetailsData;
}

const getExperienceItem = (data: ReviewTripDetailsData) => {
  if (!data.experience_accomodation_type) {
    return [];
  }
  const label = `Would you also like to undertake the week-long ${
    EXPERIENCE_TYPE_TO_DISPLAY_NAME[data.experience_accomodation_type.type]
  }?`;
  return [
    {
      label,
      value: 'Yes',
    },
  ];
};

const ReviewTripDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Your trip"
        items={[
          {
            label: 'What type of placement do you want?',
            value: data.discipline.label,
          },
          {
            label: 'Where would you like to go?',
            value: data.destination.label,
          },
          {
            label: 'When would you like to go?',
            value: formatDateToReadableString(data.hospital_start_date),
          },
          {
            label: 'How long do you want your hospital placement to be?',
            value: formatHospitalDuration(data.hospital_duration),
          },
          ...getExperienceItem(data),
          {
            label: 'What is your educational status?',
            value:
              EDUCATION_STATUS_TO_DISPLAY_NAME[
                data.education_status as EducationStatusType
              ],
          },
          {
            label: 'Will this placement count towards your degree?',
            value: booleanToYesNo(data.towards_degree),
          },
        ]}
      />
    </div>
  );
};

export default ReviewTripDetails;
