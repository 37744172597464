import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Tooltip: React.FC<{ id: string }> = ({ id }) => (
  <div className="custom-tooltip-wrapper">
    <ReactTooltip
      id={id}
      className="custom-tooltip"
      border="1px solid #d1d5db"
    />
  </div>
);

export default Tooltip;
