import React from 'react';

import { RoundedSmallButtonOutline } from '../../../../../framework-components/button/Button';
import useModalState from '../../../../../hooks/modals';
import ConfirmBookingModal from './ConfirmBookingModal';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../../utils/dates';

interface Props {
  bookingId: string;
}

const ConfirmBookingButton: React.FC<Props> = ({ bookingId }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const [buttonClicked, setButtonClicked] = React.useState(false);

  return (
    <div>
      {buttonClicked ? (
        formatDateToLongDateFormatWithOridinalDay(new Date().toUTCString())
      ) : (
        <>
          <ConfirmBookingModal
            isOpen={isOpen}
            closeModal={closeModal}
            onConfirm={() => setButtonClicked(true)}
            bookingId={bookingId}
          />
          <RoundedSmallButtonOutline
            label="Confirm"
            onClick={openModal}
            className="text-sm-bold"
          />
        </>
      )}
    </div>
  );
};

export default ConfirmBookingButton;
