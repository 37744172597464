import React from 'react';

import PageWrapper from '../booking-form/PageWrapper';
import { SECTIONS } from '../booking-form/constants';
import OrderSummary from './OrderSummary';

const OrderSummaryPage: React.FC = () => {
  return (
    <PageWrapper title="Order summary" section={SECTIONS.SUMMARY}>
      <OrderSummary />
    </PageWrapper>
  );
};

export default OrderSummaryPage;
