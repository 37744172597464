import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';

export interface SearchBarProps {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classNameOverrides?: string;
  icon?: React.ReactNode;
  defaultValue?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearchChange,
  classNameOverrides,
  icon,
  defaultValue,
}) => {
  const hasClassNameOverrides = Boolean(classNameOverrides);

  const defaultClassName =
    'block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pr-10 pl-3';

  return (
    <div className="flex items-center justify-start pr-2.5 pt-5 md:justify-end">
      <div className="w-full max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute flex items-center inset-y-0 right-0 pr-3">
            {icon ? (
              icon
            ) : (
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
          <input
            id="search"
            name="search"
            className={classNames(
              {
                [defaultClassName]: !hasClassNameOverrides,
              },
              classNameOverrides
            )}
            placeholder="Search"
            type="text"
            onChange={onSearchChange}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
