import React from 'react';

import CardDetail, {
  StyleGuideCardDetail,
} from '../../framework-components/card/CardDetail';

const CardDetailExample: React.FC = () => {
  const data = {
    Name: 'John Doe',
    Age: '',
    Location: 'London',
  };

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h1 className="text-2xl font-bold">CardDetail</h1>

      <h2 className="text-lg font-bold mb-4 mt-8">
        {' '}
        CardDetail without background{' '}
      </h2>
      <CardDetail data={data} />

      <h2 className="text-lg font-bold mb-4 mt-8">
        {' '}
        CardDetail with background{' '}
      </h2>
      <CardDetail data={data} backgroundColor="bg-gray-100" />

      <h2 className="text-lg font-bold mb-4 mt-8"> CardDetail with image </h2>
      <CardDetail
        data={data}
        imageSource="https://picsum.photos/id/237/200/300"
      />

      <StyleGuideCardDetail
        imageSource="https://picsum.photos/id/237/200/300"
        data={data}
      />
    </div>
  );
};

export default CardDetailExample;
