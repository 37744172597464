import React, { useCallback, useState } from 'react';
import {
  FastField,
  useFormikContext,
  FieldInputProps,
  FormikProps,
} from 'formik';

import { FormValues, Experience, EducationStatusType } from './types';
import { EDUCATION_STATUS } from './constants';
import {
  EXPERIENCE_TYPE_TO_DISPLAY_NAME,
  EDUCATION_STATUS_TO_DISPLAY_NAME,
} from './labels';
import LocalDropdown from '../../../framework-components/dropdown/LocalDropdown';
import { Option } from '../../../types';
import { useAPI } from '../../../api/api';
import { FormLabel } from './FormLabel';
import ErrorText from '../../error-text';
import { dateToYearMonthDayFormat } from '../../../utils/dates';
import { getFormikErrors } from '../../../utils/forms';
import UKFormattedDatePicker from '../../../framework-components/date-picker/UKFormattedDatePicker';

const durationOptions: ReadonlyArray<Option<number>> = [
  { value: 1, label: '1 week' },
  { value: 2, label: '2 weeks' },
  { value: 3, label: '3 weeks' },
  { value: 4, label: '4 weeks' },
  { value: 5, label: '5 weeks' },
  { value: 6, label: '6 weeks' },
  { value: 7, label: '7 weeks' },
  { value: 8, label: '8 weeks' },
  { value: 9, label: '9 weeks' },
  { value: 10, label: '10 weeks' },
];

export const experienceOptions: ReadonlyArray<Option<boolean>> = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const educationStatusOptions: ReadonlyArray<Option<EducationStatusType>> = [
  {
    value: EDUCATION_STATUS.STUDENT,
    label: EDUCATION_STATUS_TO_DISPLAY_NAME[EDUCATION_STATUS.STUDENT],
  },
  {
    value: EDUCATION_STATUS.PROFESSIONAL,
    label: EDUCATION_STATUS_TO_DISPLAY_NAME[EDUCATION_STATUS.PROFESSIONAL],
  },
];

const towardsDegreeOptions: ReadonlyArray<Option<boolean>> = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const SundayDatePicker: React.FC<{
  initialDate: Date | null;
  updateFormHandler: (date: Date) => void;
}> = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    props.initialDate
  );

  const isSunday = useCallback((date: Date) => {
    const today = new Date();
    return date > today && date.getDay() === 0;
  }, []);

  return (
    <UKFormattedDatePicker
      selected={selectedDate}
      onChange={(date: Date) => {
        setSelectedDate(date);
        props.updateFormHandler(date);
      }}
      filterDate={isSunday}
      placeholderText="Please select"
    />
  );
};

const TripDetails: React.FC = () => {
  const formik = useFormikContext<FormValues>();

  const { data: disciplineData } = useAPI<ReadonlyArray<Option>>(
    '/api/booking/disciplines/',
    {
      destination: formik.values.destination?.value || '',
    }
  );

  const { data: destinationData } = useAPI<ReadonlyArray<Option>>(
    '/api/booking/destinations/',
    {
      discipline: formik.values.discipline?.value || '',
    }
  );

  const { data: experienceData } = useAPI<Experience>(
    formik.values.destination?.value
      ? `/api/booking/experience/${formik.values.destination.value}/`
      : null
  );

  return (
    <div className="space-y-8">
      <h2 className="text-mob-h3 xs:text-h3">Your trip</h2>
      <LocalDropdown
        id="discipline"
        label={
          <FormLabel label="What type of placement do you want?" required />
        }
        options={disciplineData}
        name="discipline"
        value={formik.values.discipline}
        onSelect={(option: Option | null) => {
          formik.setFieldValue('discipline', option);
        }}
        errors={getFormikErrors(formik, 'discipline')}
        row
      />
      <LocalDropdown
        id="destination"
        label={<FormLabel label="Where would you like to go?" required />}
        options={destinationData}
        name="destination"
        value={formik.values.destination}
        onSelect={(option: Option | null) => {
          formik.setFieldValue('destination', option);
          formik.setFieldValue('experience', null);
          formik.setFieldValue('confirm_experience', null);
        }}
        errors={getFormikErrors(formik, 'destination')}
        row
      />
      <FastField name="start_date">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<Date>;
          form: FormikProps<FormValues>;
        }) => (
          <div>
            <div className="flex flex-col sm:flex-row sm:gap-2 sm:items-center">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
              >
                <FormLabel label="When would you like to go?" required />
              </label>
              <SundayDatePicker
                initialDate={field.value}
                updateFormHandler={(date: Date | null) => {
                  form.setFieldValue(
                    field.name,
                    date ? dateToYearMonthDayFormat(date) : null
                  );
                }}
              />
            </div>
            {form.touched.start_date && form.errors.start_date && (
              <ErrorText className="text-sm">
                {form.errors.start_date}
              </ErrorText>
            )}

            <p className="mt-2 md:w-1/2 text-wtw-secondary-text text-sm">
              Please note: Our placements run Monday to Friday. Accommodation is
              available from the Sunday before starting the placement, through
              midday on the Saturday after the placement finishes.
            </p>
          </div>
        )}
      </FastField>
      <FastField name="duration">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<number>;
          form: FormikProps<FormValues>;
        }) => (
          <LocalDropdown
            id="duration"
            label={
              <FormLabel
                label="How long do you want your hospital placement to be?"
                required
              />
            }
            options={durationOptions}
            name={field.name}
            value={
              field.value
                ? durationOptions.find((option) => option.value === field.value)
                : null
            }
            onSelect={(option: Option<number> | null) => {
              form.setFieldValue(field.name, option?.value);
            }}
            errors={getFormikErrors(form, field.name)}
            row
          />
        )}
      </FastField>
      {experienceData && (
        <LocalDropdown
          id="experience"
          label={
            <FormLabel
              label={`Would you like to undertake the week long ${
                EXPERIENCE_TYPE_TO_DISPLAY_NAME[experienceData.type]
              }`}
              required
            />
          }
          options={experienceOptions}
          name="confirm_experience"
          value={formik.values.confirm_experience}
          onSelect={(option: Option<boolean> | null) => {
            formik.setFieldValue('confirm_experience', option);
            if (option?.value) {
              formik.setFieldValue('experience', experienceData);
            } else {
              formik.setFieldValue('experience', null);
            }
          }}
          errors={getFormikErrors(formik, 'confirm_experience')}
          row
        />
      )}
      <FastField name="education_status">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<EducationStatusType>;
          form: FormikProps<FormValues>;
        }) => (
          <LocalDropdown
            id="education_status"
            label={
              <FormLabel label="What is your educational status?" required />
            }
            options={educationStatusOptions}
            name={field.name}
            onSelect={(option: Option<EducationStatusType> | null) => {
              form.setFieldValue(field.name, option?.value);
              form.setFieldValue('year_of_graduation', null);
              form.setFieldValue('year_of_university_study', null);
            }}
            errors={getFormikErrors(form, field.name)}
            value={
              field.value
                ? educationStatusOptions.find(
                    (option) => option.value === field.value
                  )
                : null
            }
            row
          />
        )}
      </FastField>
      {formik.values.education_status === EDUCATION_STATUS.STUDENT && (
        <FastField name="towards_degree">
          {({
            field,
            form,
          }: {
            field: FieldInputProps<boolean>;
            form: FormikProps<FormValues>;
          }) => (
            <LocalDropdown
              id="towards_degree"
              label={
                <FormLabel
                  label="Will this placement count towards your degree?"
                  required
                />
              }
              options={towardsDegreeOptions}
              name={field.name}
              value={towardsDegreeOptions.find(
                (option) => option.value === field.value
              )}
              onSelect={(option: Option<boolean> | null) => {
                form.setFieldValue(field.name, option?.value);
              }}
              errors={getFormikErrors(form, field.name)}
              row
            />
          )}
        </FastField>
      )}
    </div>
  );
};

export default TripDetails;
