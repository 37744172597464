import React from 'react';

import { useAPI } from '../../../api/api';
import Spinner from '../../spinner';
import { Note, Notes } from './types';

interface Props {
  apiURL: string;
}

const NotesContainer: React.FC<Props> = ({ apiURL }) => {
  const { data, error, isValidating } = useAPI<Note | Notes>(apiURL);

  if (isValidating) {
    return <Spinner className="w-6 h-6" />;
  }

  if (error || !data) {
    return <p className="text-xs">Failed to load note</p>;
  }

  if ('results' in data) {
    return (
      <>
        {data.results.map((note) => {
          return (
            <p key={note.id} className="text-xs">
              {note.content}
            </p>
          );
        })}
      </>
    );
  }

  return <p className="text-xs">{data.content}</p>;
};

export default NotesContainer;
