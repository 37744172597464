import React from 'react';
import { DragOverEvent } from '@dnd-kit/core';

import { Event } from './types';
import { addDays, calculateDaysBetweenDates } from '../../utils/dates';

export const getDaysBetweenClickAndEventStart = (dragEvent: DragOverEvent) => {
  const eventClickedXPos = (dragEvent.activatorEvent as unknown as MouseEvent)
    .clientX;
  const eventOverRectStartXPos = dragEvent.over?.rect.left;

  if (!eventOverRectStartXPos) return;

  const daysBetweenClickAndEventStart = Math.floor(
    (eventClickedXPos - eventOverRectStartXPos) / 32
  );
  return daysBetweenClickAndEventStart;
};

export const splitEvent = <T>(
  event: Event<T>,
  daysBetweenClickAndEventStart: number
): Event<T>[] => {
  const events: Event<T>[] = [];

  const bookingId = event.bookingId;
  const sectionId = event.sectionId;

  const createNewEvents = (numDays: number) => {
    const event1_startDate = new Date(event.start_date);
    const event1_endDate = addDays(event.start_date, numDays - 1);
    events.push({
      ...event,
      start_date: event1_startDate,
      end_date: event1_endDate,
    });

    const event2_startDate = addDays(event1_endDate, 3);
    const event2_endDate = new Date(event.end_date);
    const event2_id = `${bookingId}|${event2_startDate.toISOString()}|${sectionId}`;
    events.push({
      ...event,
      id: event2_id,
      start_date: event2_startDate,
      end_date: event2_endDate,
    });
    return events;
  };

  const splitAtDay =
    Math.floor((daysBetweenClickAndEventStart + 2) / 7) * 7 + 5;

  createNewEvents(splitAtDay);

  return events;
};

export const splitAndUpdateEvents = <T>(
  dragEvent: DragOverEvent,
  activeEvent: Event<T>,
  events: ReadonlyArray<Event<T>>,
  setEvents: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Event<T>> | null>
  >
) => {
  const daysBetweenClickAndEventStart =
    getDaysBetweenClickAndEventStart(dragEvent);

  const totalDaysInEvent = calculateDaysBetweenDates(
    activeEvent.start_date,
    activeEvent.end_date
  );

  if (
    daysBetweenClickAndEventStart &&
    daysBetweenClickAndEventStart < totalDaysInEvent - 6
  ) {
    const splitEvents = splitEvent(activeEvent, daysBetweenClickAndEventStart);
    activeEvent.start_date = splitEvents[0].start_date;
    activeEvent.end_date = splitEvents[0].end_date;
    activeEvent.id = splitEvents[0].id;
    setEvents([...events, splitEvents[1]]);
  }
};
