import React from 'react';
import { useParams } from 'react-router-dom';

import { useAPI } from '../../../api/api';
import BookingForm from './BookingForm';
import { GetEnquiryData } from './types';
import Spinner from '../../spinner';
import PageWrapper from './PageWrapper';
import { SECTIONS } from './constants';
import { SUBTITLE_TEXT } from '.';

const UpdateEnquiryPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isValidating } = useAPI<GetEnquiryData>(
    `/api/booking/enquiry/${id}/`
  );

  const renderData = () => {
    if (isValidating) {
      return (
        <div className="p-4 flex justify-center">
          <Spinner className="h-6 w-6" />
        </div>
      );
    }
    if (error) {
      return (
        <div className="p-4 text-center">
          There was an error getting your enquiry
        </div>
      );
    }
    return <BookingForm initialData={data} />;
  };

  return (
    <PageWrapper
      title="Booking Form"
      subtitle={SUBTITLE_TEXT}
      section={SECTIONS.YOUR_DETAILS}
    >
      {renderData()}
    </PageWrapper>
  );
};

export default UpdateEnquiryPage;
