import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import Loading from '../loading/Loading';
import {
  ColumnConfig,
  FilterOption,
  FilterType,
  OrderingDirection,
  PartialFilterOptions,
  TableData,
  TableProps,
} from './types';
import PaginationBar from '../../components/table/pagination-bar';
import FullWidthTableCell from './FullWidthTableCell';
import SearchBar from '../searchbar/SearchBar';
import LocalDropdown from '../dropdown/LocalDropdown';
import { Option } from '../../types';
import { dateToYearMonthDayFormat } from '../../utils/dates';
import Toggle, { ToggleSize } from '../toggle/Toggle';
import UKFormattedDatePicker from '../date-picker/UKFormattedDatePicker';
import CustomSortIcon from '../../components/admin/dashboard/CustomSortIcon';
import TableSearchIcon from '../../components/admin/dashboard/TableSearchIcon';
import TableCell from './TableCell';
import RemoteDropdown from '../dropdown/RemoteDropdown';
import withEditableProps from '../../context-providers/withEditableProps';
import { RowData } from '../../components/styleguide/types';
import {
  ObjectAsURLEncodedParam,
  OrderingParam,
  orderingToString,
} from './queryParams';

const PAGE_SIZE = 10;
const ordering_directions: OrderingDirection[] = ['desc', 'asc', 'not_sorted'];

const Table = <Row,>({
  columnsConfig,
  enableSearch,
  renderActions,
  getFilterOptions,
  getData,
  enablePagination,
  debounceTime = 250,
  additionalFiltersConfig,
  compact = false,
  isRounded = true,
  hasShadow = true,
}: TableProps<Row>): React.JSX.Element => {
  const [data, setData] = useState<TableData<Row>>({
    count: 0,
    results: [],
  });

  const [search, setSearch] = useQueryParam('search', StringParam);

  const [selectedFilters, setSelectedFilters] = useQueryParam(
    'filters',
    ObjectAsURLEncodedParam
  );

  const [filterOptions, setFilterOptions] = useState<PartialFilterOptions<Row>>(
    {}
  );

  const [ordering, setOrdering] = useQueryParam('ordering', OrderingParam);
  const [page, setPage] = useQueryParam('page', NumberParam);

  useEffect(() => {
    if (!search) {
      setSearch('');
    }
    if (!page) {
      setPage(1);
    }
    if (!ordering) {
      setOrdering(null);
    }
  }, [search, page, ordering, setSearch, setPage, setOrdering]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const numberOfColumns = Object.keys(columnsConfig).length;

  const combineFilters = useMemo(() => {
    return () => {
      return {
        ..._.object(
          _.map(Object.entries(selectedFilters), ([key, filterOption]) => [
            key,
            (filterOption as FilterOption).value,
          ])
        ),
        search: search ?? '',
        ordering: orderingToString(ordering),
        page: `${page}`,
      };
    };
  }, [ordering, page, search, selectedFilters]);

  const onSearchChange = useMemo(() => {
    return debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value.trim());
      setPage(1);
    }, debounceTime);
  }, [debounceTime, setPage, setSearch]);

  useEffect(() => {
    const filterPromise =
      getFilterOptions &&
      getFilterOptions(combineFilters()).then(setFilterOptions);

    const dataPromise = getData(combineFilters()).then((response) => {
      setData({
        count: response.count || 0,
        results: response.results || [],
      });
    });

    Promise.all([getFilterOptions ? filterPromise : null, dataPromise]).then(
      () => {
        setIsLoading(false);
      }
    );

    return () => {
      onSearchChange.cancel();
    };
  }, [
    combineFilters,
    getFilterOptions,
    getData,
    onSearchChange,
    selectedFilters,
  ]);

  const renderLoading = () => {
    if (!isLoading) return null;

    return (
      <FullWidthTableCell colSpan={numberOfColumns} content={<Loading />} />
    );
  };

  const renderSearchBar = () => {
    if (!enableSearch) {
      return null;
    }

    return (
      <SearchBar
        onSearchChange={onSearchChange}
        classNameOverrides="block w-[262px] h-[46px] rounded-md border-0 bg-wtw-gray-2 pt-3 pb-3 pl-5 pr-5 text-black placeholder:text-black text-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        icon={<TableSearchIcon />}
        defaultValue={search ?? ''}
      />
    );
  };

  const renderCustomActions = (row: Row) => {
    if (!renderActions || data.results.length === 0) return null;

    return (
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
        {renderActions(row)}
      </td>
    );
  };

  const updateOrderingWithColumnKey = (key: string) => () => {
    if (!ordering) {
      setOrdering({ name: String(key), direction: 'desc' });
      return;
    }

    const index = ordering_directions.indexOf(ordering.direction);

    const nextIndex = (index + 1) % ordering_directions.length;

    if (ordering.name === key) {
      if (ordering_directions[nextIndex] === 'not_sorted') {
        setOrdering(null);
        return;
      }

      setOrdering({ name: key, direction: ordering_directions[nextIndex] });
    } else {
      setOrdering({ name: String(key), direction: 'desc' });
    }
  };

  const renderAdditionalText = (columnConfig: ColumnConfig<Row>) => {
    if (!columnConfig.additionalText) return null;

    const tooltipId = `my-tooltip-${String(columnConfig.key)}`;

    return (
      <div
        className={classNames(
          'tracker-item flex items-center justify-center rounded-full mb-1.5 ml-2'
        )}
        data-tooltip-id={tooltipId}
        data-tooltip-content={columnConfig.additionalText}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="text-gray-500 text-sm text-wtw-logo-blue"
        />
        <Tooltip id={tooltipId} />
      </div>
    );
  };

  const renderTableHeaderWithSort = (columnConfig: ColumnConfig<Row>) => {
    if (!columnConfig.enableSort) {
      return (
        <div className="flex items-end flex-row-reverse justify-end mb-2">
          {renderAdditionalText(columnConfig)}
          <label htmlFor={String(columnConfig.key)}>
            {columnConfig.header}
          </label>
        </div>
      );
    }
    return (
      <div
        className={classNames('flex items-end flex-row-reverse justify-end', {
          'mb-2': columnConfig.enableSort && columnConfig.filterType,
        })}
      >
        {renderAdditionalText(columnConfig)}
        <label
          htmlFor={String(columnConfig.key)}
          className="overflow-hidden text-ellipsis line-clamp-2"
        >
          {columnConfig.header}
        </label>
        <button
          className="group inline-flex"
          onClick={updateOrderingWithColumnKey(columnConfig.key)}
        >
          <span
            className={classNames(
              'flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200 mr-2'
            )}
          >
            <CustomSortIcon />
          </span>
        </button>
      </div>
    );
  };

  const onFilterSelect =
    (filterKey: string) => (option: Option<string | boolean> | null | Date) => {
      if (!option) {
        const newFilters = Object.assign({}, selectedFilters);
        delete newFilters[filterKey];
        setSelectedFilters(newFilters);
        return;
      }

      if (option instanceof Date) {
        setSelectedFilters(
          Object.assign({}, selectedFilters, {
            [filterKey]: {
              label: option.toDateString(),
              value: dateToYearMonthDayFormat(option),
            },
          })
        );
        return;
      }
      setSelectedFilters(
        Object.assign({}, selectedFilters, {
          [filterKey]: option,
        })
      );
    };

  const renderFilter = (filterConfig: {
    key: string;
    filterType: FilterType;
    label: string;
    remoteDropDownDataURL?: string;
    size?: ToggleSize;
  }) => {
    if (!filterConfig.filterType) return null;

    const options = filterOptions[filterConfig.key] ?? [];

    switch (filterConfig.filterType) {
      case 'local-dropdown': {
        const value = selectedFilters
          ? selectedFilters[filterConfig.key]
          : null;

        return (
          <div className="min-w-[140px]">
            <LocalDropdown
              options={options}
              value={value}
              label={filterConfig.label}
              onSelect={onFilterSelect(filterConfig.key)}
              name={String(filterConfig.key)}
              id={String(filterConfig.key)}
            />
          </div>
        );
      }
      case 'remote-dropdown': {
        if (!filterConfig.remoteDropDownDataURL) return null;

        const value = selectedFilters
          ? selectedFilters[filterConfig.key]
          : null;

        return (
          <div className="min-w-[140px]">
            <RemoteDropdown
              path={filterConfig.remoteDropDownDataURL}
              onSelect={onFilterSelect(filterConfig.key)}
              label={filterConfig.label}
              name={String(filterConfig.key)}
              id={String(filterConfig.key)}
              value={value ? value : null}
            />
          </div>
        );
      }
      case 'calendar': {
        const selectedFilter = selectedFilters[filterConfig.key];

        if (!selectedFilter) {
          return null;
        }

        const selected = selectedFilter ? new Date(selectedFilter.value) : null;

        return (
          <div>
            <label className="text-sm text-gray-900 pb-4">
              {filterConfig.label}
            </label>
            <div className="mt-1">
              <UKFormattedDatePicker
                id={String(filterConfig.key)}
                selected={selected}
                onChange={onFilterSelect(filterConfig.key)}
                placeholderText="Please select"
                showYearDropdown
                dropdownMode="select"
                isClearable
                className="w-full"
              />
            </div>
          </div>
        );
      }
      case 'toggle': {
        const toggleValue = selectedFilters
          ? selectedFilters[filterConfig.key]
          : null;

        return (
          <div className="flex flex-col items-start gap-0.5">
            <label className="text-sm text-gray-900 pb-2">
              {filterConfig.label}
            </label>
            <Toggle
              onChange={(checked: boolean) =>
                onFilterSelect(filterConfig.key)({
                  label: '',
                  value: checked,
                })
              }
              enabled={Boolean(toggleValue?.value)}
              size={filterConfig.size || 'sm'}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderAdditionalFilters = () => {
    if (!additionalFiltersConfig) return null;

    return Object.values(additionalFiltersConfig).map((filter) =>
      renderFilter(filter)
    );
  };

  const renderTableHeaders = () => {
    const headers = Object.values(columnsConfig).map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (columnConfig: any) => (
        <th
          key={columnConfig.key}
          scope="col"
          className={classNames(
            'text-left text-sm font-medium p-2',
            { 'text-gray-900 w-[144px]': !compact },
            { 'text-black': compact }
          )}
        >
          {renderTableHeaderWithSort(columnConfig)}
          {renderFilter(columnConfig)}
        </th>
      )
    );

    if (renderActions && data.results.length > 0) {
      headers.push(
        <th
          key="actions"
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          <span className="sr-only">Actions</span>
        </th>
      );
    }

    return headers;
  };

  const renderTableData = () => {
    if (data.results.length === 0) {
      if (isLoading) {
        return renderLoading();
      }

      return (
        <FullWidthTableCell
          colSpan={numberOfColumns}
          content={'No results found for the following criteria'}
        />
      );
    }

    return (
      <>
        <tr
          className={classNames(
            'absolute top-0 left-0 w-full h-full bg-white flex items-center justify-center z-10 border-none',
            { 'bg-opacity-50': isLoading },
            { 'bg-opacity-0 hidden': !isLoading }
          )}
        >
          <FullWidthTableCell colSpan={numberOfColumns} content={<Loading />} />
        </tr>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {data.results.map((row: any) => (
          <tr key={row.id} className="border-none">
            {Object.values(columnsConfig).map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (columnConfig: any, index: number) => {
                if (columnConfig.renderCell) {
                  const renderedCell = columnConfig.renderCell(
                    row,
                    columnConfig.key
                  );
                  const title =
                    typeof renderedCell === 'string' ? renderedCell : undefined;
                  return (
                    <TableCell
                      key={columnConfig.key}
                      className={classNames(
                        'overflow-hidden whitespace-nowrap text-ellipsis',
                        columnConfig.cellClassName
                      )}
                      title={title}
                    >
                      {columnConfig.renderCell(row, columnConfig.key)}
                    </TableCell>
                  );
                }

                return (
                  <td
                    key={columnConfig.key}
                    className={classNames(
                      'p-[6.6px] text-sm text-black text-wrap break-words',
                      {
                        'pl-2': index === 0,
                      }
                    )}
                    title={row[columnConfig.key]}
                  >
                    {row[columnConfig.key]}
                  </td>
                );
              }
            )}
            {renderCustomActions(row)}
          </tr>
        ))}
      </>
    );
  };

  const renderPagination = () => {
    if (!enablePagination) return null;

    const totalPages = Math.ceil(data.count / PAGE_SIZE);

    return (
      <div className="flex flex-1 items-center justify-start py-4 md:justify-end lg:ml-6">
        <PaginationBar
          currentPage={page || 1}
          totalPages={totalPages}
          onChangePage={setPage}
          totalCount={data.count}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  };

  return (
    <div className="flow-root">
      <div
        className={classNames('overflow-x-auto bg-white p-6', {
          'rounded-lg': isRounded && !compact,
          'shadow-md': hasShadow && !compact,
        })}
      >
        <div className="inline-block w-full align-middle">
          <div
            className={classNames('overflow-hidden', { 'space-y-6': !compact })}
          >
            <div
              className={classNames(
                'flex gap-6 justify-start items-end relative justify-between',
                { 'pb-6': !compact }
              )}
            >
              <div className="flex flex-wrap gap-6">
                {renderAdditionalFilters()}
              </div>
              {renderSearchBar()}
            </div>
            <table
              className={classNames('w-full table-fixed rounded-md', {
                'bg-wtw-gray-2': !compact,
              })}
            >
              <thead
                className={classNames({
                  'bg-wtw-gray-2': !compact,
                })}
              >
                <tr className="align-top">{renderTableHeaders()}</tr>
              </thead>

              <tbody
                className={classNames('divide-y divide-gray-200 relative', {
                  'bg-white': !compact,
                })}
              >
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {renderPagination()}
        </div>
      </div>
    </div>
  );
};

export default Table;

export const StyleGuideEditableTable = withEditableProps<TableProps<RowData>>(
  Table,
  {
    compact: {
      displayName: 'Compact',
      type: 'boolean',
      defaultValue: false,
    },
    isRounded: {
      displayName: 'Rounded',
      type: 'boolean',
      defaultValue: true,
    },
    hasShadow: {
      displayName: 'Shadow',
      type: 'boolean',
      defaultValue: true,
    },
    enablePagination: {
      displayName: 'Enable Pagination',
      type: 'boolean',
      defaultValue: true,
    },
    enableSearch: {
      displayName: 'Enable Search',
      type: 'boolean',
      defaultValue: true,
    },
  }
);
