import React from 'react';

import AdminStandardPage from '../../admin/common/AdminStandardPage';
import BlockTabs from '../../../framework-components/blocktabs/BlockTabs';

const AlumniAggregateSurveyResults: React.FC = () => {
  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Alumni',
          href: null,
        },
        {
          label: 'Aggregate Survey Results',
          href: null,
        },
      ]}
      title="Aggregate Survey Results"
      enableHome={false}
    >
      <div className="w-1/3 mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Survey Results',
              href: '/admin/alumni/survey-results',
              current: false,
            },
            {
              label: 'Aggregates',
              href: '/admin/alumni/aggregate-survey-results',
              current: true,
            },
          ]}
          variant="underlined"
        />
      </div>
    </AdminStandardPage>
  );
};

export default AlumniAggregateSurveyResults;
