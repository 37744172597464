import React from 'react';

const Loading = () => (
  <div
    className="h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-current motion-reduce:animate-[spin_1.5s_linear_infinite] pointer-events-none mr-2"
    role="status"
  >
    <span className="sr-only">Loading...</span>
  </div>
);

export default Loading;
