import React from 'react';

interface Props {
  percentage: number;
}

const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <div
      className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden"
      role="progressbar"
      aria-label="progressbar"
      aria-valuemin={0}
      aria-valuenow={percentage}
      aria-valuemax={100}
    >
      <div
        style={{
          width: `${percentage}%`,
        }}
        className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 transition ease-linear"
      />
    </div>
  );
};

export default ProgressBar;
