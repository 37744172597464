import React from 'react';
import { useParams } from 'react-router';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import ImageGallery from '../../common/ImageGallery/ImageGallery';
import {
  getImages,
  setMainImage,
  deleteImage,
  updateOccupancyDetails,
  updateQuickFacts,
  updateRoom,
  addRoom,
  deleteRoom,
  updatePublicDescription,
  updateOtherNotes,
} from './actions';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import CardDetail from '../../../../framework-components/card/CardDetail';
import ThreeColumns from '../../common/layout/ThreeColumns';
import QuickEditCreate from '../../../quick-edit-create/QuickEditCreate';
import QuickListEdit from '../../../quick-list-edit/QuickListEdit';
import {
  BasicInformation,
  OccupancyDetails,
  OtherNotes,
  PublicDescription,
  QuickFacts,
  Room,
} from './types';
import TwoColumns from '../../common/layout/TwoColumns';

const HouseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: houseInformation,
    isValidating,
    error,
  } = useAPI<BasicInformation>(`/api/admin/house/${id}/basic-information/`);

  const renderHouseCard = (data: Record<string, string | undefined>) => {
    return (
      <CardDetail
        backgroundColor="bg-wtw-gray-2"
        className="h-full"
        data={data}
      />
    );
  };

  const renderFirstColumn = () => {
    return renderHouseCard({
      Name: houseInformation?.name,
      'Accomodation Manager': houseInformation?.manager,
      Location: houseInformation?.destination?.name,
    });
  };

  const renderSecondColumn = () => {
    return renderHouseCard({
      'House telephone': houseInformation?.telephone,
      'House email': houseInformation?.email,
    });
  };

  const renderThirdColumn = () => {
    return renderHouseCard({
      Address: houseInformation?.address,
    });
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Houses',
          href: '/admin/directories/houses',
        },
        {
          label: houseInformation ? houseInformation.name : '',
          href: null,
        },
      ]}
      title={houseInformation ? houseInformation.name : ''}
      enableHome={false}
    >
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {houseInformation && (
        <div className="mt-8">
          <div className="pb-16">
            <ThreeColumns
              leftColumn={renderFirstColumn()}
              middleColumn={renderSecondColumn()}
              rightColumn={renderThirdColumn()}
            />
          </div>
          <TwoColumns
            leftColumn={
              <>
                <QuickEditCreate<OccupancyDetails>
                  title="Occupancy details:"
                  getUrl={`/api/admin/house/${id}/occupancy-stats/`}
                  submitItem={updateOccupancyDetails}
                  labels={{
                    maximum_occupants: 'Max occupancy',
                    warn_at_occupants: 'Warn level',
                    number_of_rooms: 'Rooms',
                  }}
                  fields={[
                    {
                      type: 'smalltext',
                      name: 'maximum_occupants',
                      label: 'Max occupancy',
                      extraProps: { type: 'number' },
                    },
                    {
                      type: 'smalltext',
                      name: 'warn_at_occupants',
                      label: 'Warn level',
                      extraProps: { type: 'number' },
                    },
                    {
                      type: 'smalltext',
                      name: 'number_of_rooms',
                      label: 'Rooms',
                      extraProps: { type: 'number' },
                    },
                  ]}
                />
                <QuickListEdit<Room>
                  title="Rooms:"
                  getUrl={`/api/admin/house/${id}/rooms/`}
                  tableFields={[
                    {
                      name: 'name',
                      header: 'Name',
                      render: (item: Room) => item['name'],
                    },
                    {
                      name: 'location',
                      header: 'Location',
                      render: (item: Room) => item['location'],
                    },
                    {
                      name: 'capacity',
                      header: 'Capacity',
                      render: (item: Room) => item['capacity'],
                    },
                  ]}
                  quickFormFields={[
                    {
                      type: 'smalltext',
                      name: 'name',
                      label: 'Name',
                    },
                    {
                      type: 'smalltext',
                      name: 'location',
                      label: 'Location',
                    },
                    {
                      type: 'smalltext',
                      name: 'capacity',
                      label: 'Capacity',
                      extraProps: { type: 'number' },
                    },
                  ]}
                  addHandler={addRoom}
                  editHandler={updateRoom}
                  deleteHandler={deleteRoom}
                />
              </>
            }
            rightColumn={
              <>
                <QuickEditCreate<PublicDescription>
                  title="Public Description:"
                  getUrl={`/api/admin/house/${id}/description/`}
                  submitItem={updatePublicDescription}
                  labels={{
                    description: 'Description',
                  }}
                  fields={[
                    {
                      type: 'largetext',
                      name: 'description',
                      label: 'Description',
                      extraProps: {
                        placeholder: 'Enter a description',
                        rows: 6,
                      },
                    },
                  ]}
                  simple
                />
                <QuickEditCreate<QuickFacts>
                  title="Quick facts:"
                  getUrl={`/api/admin/house/${id}/quick-facts/`}
                  submitItem={updateQuickFacts}
                  labels={{
                    population: 'Population',
                    distance: 'Distance to town',
                    travel_time: 'Travel time',
                    travel_method: 'Travel method',
                    language: 'Local language',
                    activity: 'Main local activity',
                  }}
                  fields={[
                    {
                      type: 'smalltext',
                      name: 'population',
                      label: 'Population',
                      extraProps: { type: 'number' },
                    },
                    {
                      type: 'smalltext',
                      name: 'distance',
                      label: 'Distance to town',
                    },
                    {
                      type: 'smalltext',
                      name: 'travel_time',
                      label: 'Travel time',
                    },
                    {
                      type: 'smalltext',
                      name: 'travel_method',
                      label: 'Travel method',
                    },
                    {
                      type: 'smalltext',
                      name: 'language',
                      label: 'Local language',
                    },
                    {
                      type: 'smalltext',
                      name: 'activity',
                      label: 'Main local activity',
                    },
                  ]}
                />
                <QuickEditCreate<OtherNotes>
                  title="Other Notes:"
                  getUrl={`/api/admin/house/${id}/notes/`}
                  submitItem={updateOtherNotes}
                  labels={{
                    notes: 'Notes',
                  }}
                  fields={[
                    {
                      type: 'largetext',
                      name: 'notes',
                      label: 'Other Notes',
                      extraProps: {
                        placeholder: 'Enter any other notes',
                        rows: 6,
                      },
                    },
                  ]}
                  simple
                />
                <ImageGallery
                  title="Main house photo:"
                  getImages={getImages}
                  setMainImage={setMainImage}
                  deleteImage={deleteImage}
                  onSuccessfulUpload={(file_id: string) => {
                    console.log('File uploaded:', file_id); // eslint-disable-line no-console
                    // TODO: Call API to add the image to the house
                  }}
                />
              </>
            }
          />
        </div>
      )}
    </AdminStandardPage>
  );
};

export default HouseDetail;
