import { FormikProps } from 'formik';

export const getFormikErrors = (
  formik: FormikProps<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  field: string
): string[] => {
  return formik.touched[field] && formik.errors[field]
    ? ([formik.errors[field]] as string[])
    : [];
};
