import classNames from 'classnames';
import React from 'react';

export interface CardProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  hasShadow?: boolean;
  hasBorder?: boolean;
  backgroundColor?: string;
}

const Card: React.FC<CardProps> = ({
  children,
  className,
  hasShadow = true,
  hasBorder = true,
  backgroundColor,
}) => {
  return (
    <div
      className={classNames(
        'rounded-lg p-4',
        { 'shadow-md': hasShadow },
        { 'bg-white': !backgroundColor },
        { 'border border-wtw-gray-4': hasBorder },
        backgroundColor,
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
