import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { apiRequest, useAPI } from '../../../../api/api';
import {
  DashboardEnquiryListingCountData,
  EnquiryTableData,
} from '../../dashboard/types';
import Spinner from '../../../spinner';
import Table from '../../../../framework-components/table/Table';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../../framework-components/table/types';
import QuickSetEnquiryPriority from '../common/QuickSetEnquiryPriority';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../utils/dates';
import AddNewIconOutlined from '../../common/AddNewIconOutlined';

const DashboardNewEnquiryPage: React.FC = () => {
  const { data, error, isValidating } =
    useAPI<DashboardEnquiryListingCountData>('/api/admin/enquiries/count/');

  const getFilterOptions = useCallback((): Promise<
    Record<string, FilterOption[]>
  > => {
    return fetch('/api/admin/enquiries/enquiries-table-filters/').then(
      (response) => response.json()
    );
  }, []);

  const getDataPromise = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<EnquiryTableData>>(
      '/api/admin/enquiries/?' +
        new URLSearchParams({
          ...selectedFilters,
          type: 'new',
        }).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const renderTabs = useCallback(() => {
    if (isValidating) {
      return <Spinner className="h-6 w-6" />;
    }

    if (error || !data) {
      return (
        <div className="p-4 text-center">
          There was an error getting your order
        </div>
      );
    }

    return (
      <BlockTabs
        tabs={[
          {
            label: `New (${data.new})`,
            href: '/admin/enquiries/new',
            current: true,
          },
          {
            label: `Ongoing (${data.ongoing})`,
            href: '/admin/enquiries/ongoing',
          },
        ]}
        variant="pilled_tab"
      />
    );
  }, [isValidating, error, data]);

  const additionalHeaderContent = () => (
    <div className="flex justify-end">
      <PrimaryButton>
        <span className="flex items-center">
          <AddNewIconOutlined className="mr-3" />
          Add new
        </span>
      </PrimaryButton>
    </div>
  );

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Enquiries',
          href: '/admin/enquiries/new',
        },
        {
          label: 'New enquiries',
          href: null,
        },
      ]}
      title={'New enquiries'}
      additionalHeaderContent={additionalHeaderContent()}
      enableHome={false}
    >
      <div className="w-full">{renderTabs()}</div>

      <Table<EnquiryTableData>
        columnsConfig={{
          name: {
            key: 'name',
            header: 'Name',
            enableSort: true,
            renderCell: (enquiry) => {
              if (enquiry.holiday && enquiry.holiday.bookings) {
                return (
                  <a
                    href={`/admin/bookings/${enquiry.holiday.bookings[0].id}/applicant-profile/`}
                    title={enquiry.name}
                  >
                    {enquiry.name}
                  </a>
                );
              }

              return enquiry.name;
            },
          },
          created: {
            key: 'created',
            header: 'Enquiry Date',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (enquiry) =>
              formatDateToLongDateFormatWithOridinalDay(enquiry.created),
          },
          university: {
            key: 'university',
            header: 'University',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (enquiry) => {
              return (
                <a
                  href={`/admin/university/${enquiry.university.id}/`}
                  title={enquiry.university.name}
                >
                  {enquiry.university.name}
                </a>
              );
            },
          },
          university_course: {
            key: 'university_course',
            header: 'Course title',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          destination: {
            key: 'destination',
            header: 'Destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (enquiry) => enquiry.destination.name,
          },
          source: {
            key: 'source',
            header: 'Source',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          start_date: {
            key: 'start_date',
            header: 'Travel date',
            additionalText: "The date you're looking to travel on.",
            enableSort: true,
            filterType: 'calendar',
            renderCell: (enquiry) =>
              formatDateToLongDateFormatWithOridinalDay(enquiry.start_date),
          },
          priority: {
            key: 'priority',
            header: 'Priority',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (row: EnquiryTableData) => (
              <QuickSetEnquiryPriority
                enquiryId={row.id}
                initialValue={row.priority || undefined}
              />
            ),
          },
          capacity_details: {
            key: 'capacity_details',
            header: 'Capacity Details',
            renderCell: () => (
              <div className="flex justify-center">
                <FontAwesomeIcon
                  icon={faHouse}
                  className="text-gray-400 text-h5"
                />
              </div>
            ),
          },
        }}
        getFilterOptions={getFilterOptions}
        getData={getDataPromise}
        enablePagination
        enableSearch
        additionalFiltersConfig={{
          show_clashes: {
            key: 'show_clashes',
            label: 'Show clashes',
            filterType: 'toggle',
          },
          is_active: {
            key: 'is_active',
            label: 'Active/Archived',
            filterType: 'local-dropdown',
          },
        }}
      />
    </AdminStandardPage>
  );
};

export default DashboardNewEnquiryPage;
