import React from 'react';
import classNames from 'classnames';

import { SECTIONS } from './constants';
import { SectionType } from './types';

export const SECTION_LABEL_MAP = {
  [SECTIONS.YOUR_DETAILS]: 'Your details',
  [SECTIONS.REVIEW]: 'Review',
  [SECTIONS.SUMMARY]: 'Summary',
};

const SectionItem: React.FC<{ label: string; selected: boolean }> = ({
  label,
  selected,
}) => {
  return (
    <div className="w-full space-y-2">
      <div
        className={classNames('h-1', {
          'bg-wtw-logo-blue': selected,
          'bg-wtw-gray-3': !selected,
        })}
      />
      <p className="text-xs-semibold text-center">{label}</p>
    </div>
  );
};

const SectionBar: React.FC<{ selectedSection: SectionType }> = ({
  selectedSection,
}) => {
  return (
    <div className="pt-md pb-sm px-md border border-wtw-gray-3 rounded-md flex items-center justify-center">
      <div className="flex w-full max-w-[768px]">
        {Object.keys(SECTIONS).map((section) => (
          <SectionItem
            key={section}
            label={SECTION_LABEL_MAP[section as SectionType]}
            selected={section === selectedSection}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionBar;
