import React from 'react';
import { FastField, FieldInputProps, FormikProps } from 'formik';

import { FormValues } from './types';
import { FormLabel } from './FormLabel';
import Input from '../../../framework-components/input/Input';
import { getFormikErrors } from '../../../utils/forms';

const PersonalDetails: React.FC = () => {
  return (
    <div className="space-y-8">
      <h2 className="text-mob-h3 xs:text-h3">Your personal details</h2>
      <FastField name="first_name">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="first_name"
            label={<FormLabel label="First name" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter first name"
            row
          />
        )}
      </FastField>
      <FastField name="last_name">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="last_name"
            label={<FormLabel label="Last name" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter last name"
            row
          />
        )}
      </FastField>
      <FastField name="email">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="email"
            label={<FormLabel label="Email address" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter email"
            row
          />
        )}
      </FastField>
    </div>
  );
};

export default PersonalDetails;
