import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { AccomodationDetailsRequestData } from './types';
import TabbedWidgetSection from '../../../../framework-components/tabbedContainer/TabbedWidgetSection';
import Table from '../../../../framework-components/table/Table';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../utils/dates';
import NotesContainer from '../../common/NotesContainer';

interface Props {
  bookingId?: string;
}

const AccomodationPlacementDetailsSection: React.FC<Props> = ({
  bookingId,
}) => {
  const getAccomodationDetails = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-accomodation-details/`
    );

    return request.then((response) => response.json());
  };

  return (
    <TabbedWidgetSection className="bg-wtw-gray-2">
      <h3 className="text-h5">Accommodation</h3>
      <Table<AccomodationDetailsRequestData>
        columnsConfig={{
          accomodation_type: {
            key: 'accomodation_type',
            header: 'Accomodation',
            renderCell: (data) => data.accomodation_type.name,
          },
          house: {
            key: 'house',
            header: 'House',
            renderCell: (data) => data.house?.name,
          },
          start_date: {
            key: 'start_date',
            header: 'Start',
            renderCell: (data) =>
              formatDateToLongDateFormatWithOridinalDay(data.start_date),
          },
          number_of_weeks: {
            key: 'number_of_weeks',
            header: 'Weeks',
          },
          conflict: {
            key: 'conflict',
            header: 'Conflict',
            renderCell: (data) => (
              <FontAwesomeIcon
                icon={faHouse}
                className={classNames({ 'text-red-500': data.conflict })}
              />
            ),
          },
        }}
        getData={getAccomodationDetails}
        compact
      />
      <div className="flex gap-x-3.5">
        <p className="text-xs-bold flex-grow-0 flex-shrink-0 w-[134px]">
          Accomodation Notes:
        </p>
        <NotesContainer
          apiURL={`/api/admin/bookings/${bookingId}/notes/accomodation/`}
        />
      </div>
    </TabbedWidgetSection>
  );
};

export default AccomodationPlacementDetailsSection;
