import React, { useCallback } from 'react';

import TextArea from '../../framework-components/textarea/TextArea';

const TextAreas = () => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      console.log(event); // eslint-disable-line no-console
    },
    []
  );
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Text Areas</h2>

      <div className="w-full max-w-xs">
        <div className="mb-4">
          <TextArea
            label="Description"
            name="description"
            placeholder="Enter a description"
            onChange={onChange}
          />
        </div>

        <div className="mb-4">
          <TextArea
            label="Disabled Description"
            name="description"
            placeholder="Enter a description"
            disabled
            onChange={onChange}
          />
        </div>

        <div className="mb-4">
          <TextArea
            label="Auto Grow Description"
            name="description"
            placeholder="Enter a description"
            autoGrow
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TextAreas;
