import React, { useState } from 'react';

type Option = {
  label: string;
  value: string;
};

type EditorInputTypes = 'text' | 'boolean' | 'select';
type ValueType = string | boolean;

interface EditableConfig {
  displayName: string;
  defaultValue?: ValueType;
  type: EditorInputTypes;
  options?: Option[];
}

const withEditableProps = <P extends object>(
  Component: React.ComponentType<P>,
  config: { [K in keyof P]?: EditableConfig },
  title?: string,
  description?: string
) => {
  return (props: P) => {
    const [editableProps, setEditableProps] = useState(props);

    const handlePropsChange = (key: string, value: ValueType) => {
      setEditableProps({
        ...editableProps,
        [key]: value,
      });
    };

    const renderOptionInput = (
      key: string,
      inputType: EditorInputTypes,
      value: ValueType,
      options?: Option[]
    ) => {
      switch (inputType) {
        case 'text':
          return (
            <input
              type="text"
              value={value as string}
              onChange={(e) => handlePropsChange(key, e.target.value)}
            />
          );
        case 'boolean':
          return (
            <input
              type="checkbox"
              checked={value as boolean}
              onChange={(e) => handlePropsChange(key, e.target.checked)}
            />
          );
        case 'select':
          return (
            <select
              value={value as string}
              onChange={(e) => handlePropsChange(key, e.target.value)}
            >
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          );
        default:
          return null;
      }
    };

    const renderLabel = (content: string) => {
      return <label className="text-sm-bold">{content}</label>;
    };

    const renderControls = () => {
      return Object.keys(config).map((key) => {
        const option = config[key as keyof P];

        if (!option) {
          return null;
        }

        const value = editableProps[key as keyof P];

        return (
          <div key={key} className="flex flex-wrap space-x-2 items-center">
            {option.displayName && renderLabel(option.displayName)}

            {renderOptionInput(
              key,
              option.type,
              (value as ValueType) ?? option.defaultValue,
              option.options
            )}
          </div>
        );
      });
    };

    return (
      <div>
        {title && <h1 className="text-h5">{title}</h1>}
        {description && <p className="text-sm">{description}</p>}

        <Component {...(editableProps as P)} />
        <div className="pt-6">{renderControls()}</div>
      </div>
    );
  };
};

export default withEditableProps;
