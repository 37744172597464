import { useCallback, useState } from 'react';

export type ModalState = Readonly<{
  isOpen: boolean;
  openModal(): void;
  closeModal(): void;
  setIsOpen(isOpen: boolean): void;
}>;

function useModalState(defaultIsOpen = false): ModalState {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    isOpen,
    openModal,
    closeModal,
    setIsOpen,
  };
}

export default useModalState;
