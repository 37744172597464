import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PhotoIcon } from '@heroicons/react/20/solid';
import _ from 'underscore';

import { apiRequest } from '../../api/api';
import ProgressBar from '../progressbar/ProgressBar';

interface PresignedPOSTParams {
  acl: string;
  key: string;
  policy: string;
  signature: string;
  AWSAccessKeyId: string;
}

interface PresignedURLData {
  url: string;
  fields: PresignedPOSTParams;
}

interface FileInfo {
  file_id: string;
  upload_to: PresignedURLData;
  file_uploaded_confirmation_url: string;
}

interface Props {
  accepted_extensions: string[];
  max_file_size: number;
  onSuccessfulUpload?: (file_id: string) => void;
}

const APPROX_TEN_MEGABYTES_IN_BYTES = 10000000;

function getExtensionFromFileName(filename: string): string {
  return filename.substr(filename.lastIndexOf('.') + 1, filename.length);
}

export async function createNewFile(fileName: string): Promise<FileInfo> {
  const fileExtension = getExtensionFromFileName(fileName);
  return await apiRequest<FileInfo>('/api/files/create-file-record/', {
    method: 'POST',
    body: JSON.stringify({
      file_extension: fileExtension,
    }),
  });
}

export async function uploadFileToS3(
  fileInfo: FileInfo,
  selectedFile: File
): Promise<void> {
  const uploadBodyData = new FormData();

  _.pairs(fileInfo.upload_to.fields).map((fieldNameAndValue) => {
    const fieldName = fieldNameAndValue[0];
    const fieldValue = fieldNameAndValue[1];

    uploadBodyData.append(fieldName, fieldValue);
  });

  uploadBodyData.append('file', selectedFile);

  const uploadResponse = await fetch(fileInfo.upload_to.url, {
    method: 'POST',
    body: uploadBodyData,
  });
  if (!uploadResponse.ok) {
    const responseText = await uploadResponse.text();
    throw new Error(`Failed to upload file to S3: ${responseText}`);
  }
}

export async function markFileUploadAsComplete(
  fileUploadConfirmationUrl: string
): Promise<void> {
  await apiRequest(fileUploadConfirmationUrl, { method: 'POST' });
}

const FileUpload: React.FC<Props> = ({
  accepted_extensions,
  max_file_size,
  onSuccessfulUpload,
}) => {
  const [fileId, setFileId] = useState('');
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);
  const [progress, setProgress] = useState(0);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      setFileUploadInProgress(true);
      setUploadError(null);
      const selectedFile = files[0];

      if (selectedFile.size > APPROX_TEN_MEGABYTES_IN_BYTES) {
        setUploadError(
          'This file is too large. The maximum file size accepted is 10MB.'
        );
        setFileUploadInProgress(false);
        return;
      }

      if (selectedFile.size === 0) {
        setUploadError('This file is empty');
        setFileUploadInProgress(false);
        return;
      }

      try {
        const fileInfo = await createNewFile(selectedFile.name);
        setProgress(25);
        await uploadFileToS3(fileInfo, selectedFile);
        setProgress(50);
        await markFileUploadAsComplete(fileInfo.file_uploaded_confirmation_url);
        setProgress(75);

        if (onSuccessfulUpload) {
          await onSuccessfulUpload(fileInfo.file_id);
        }
        setProgress(100);

        setTimeout(() => {
          setFileId(fileInfo.file_id);
          setFileUploadInProgress(false);
          setProgress(0);
        }, 1000);
      } catch (e) {
        setFileUploadInProgress(false);
        setUploadError('Sorry, there was a problem uploading the file.');
        // eslint-disable-next-line no-console
        console.error('File upload error', { error: e });
      }
    },
    [onSuccessfulUpload]
  );

  const onDrop = useCallback(
    (files: File[]) => {
      if (files) {
        uploadFiles(files);
      }
    },
    [uploadFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const reset = useCallback(() => {
    setFileId('');
  }, []);

  const render = () => {
    if (fileUploadInProgress) {
      return (
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="flex flex-col items-center justify-center w-full gap-2">
            <ProgressBar percentage={progress} />
            <div className="text-sm leading-6 text-gray-600">Uploading...</div>
          </div>
        </div>
      );
    }

    if (fileId) {
      return (
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="flex flex-col items-center justify-center w-full gap-2">
            <PhotoIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <p className="text-sm text-gray-600">File uploaded</p>
            <p
              onClick={reset}
              className=" relative cursor-pointer text-sm rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              Reset
            </p>
          </div>
        </div>
      );
    }

    return (
      <div
        {...getRootProps()}
        className={`
      mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10
      ${isDragActive ? 'opacity-50' : ''}
    `}
      >
        <div className="text-center flex flex-col items-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <input
            {...getInputProps()}
            id="file-upload"
            type="file"
            className="sr-only"
            accept="image/png, image/jpeg, application/pdf"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <span className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
              Upload a file
            </span>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">
            {`${accepted_extensions.join(', ')} up to ${max_file_size}MB`}
          </p>
          {uploadError && (
            <p className="mt-5 text-xs leading-5 text-red-600">{uploadError}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-full">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        File upload
      </label>
      {render()}
    </div>
  );
};

export default FileUpload;
