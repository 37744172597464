import React from 'react';

import { Option } from '../../../../types';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Calendar from '../../../calendar/Calendar';
import SelectDates from '../SelectDates';
import { useCapacityCalendar } from '../hooks';
import { getEventForDay } from '../utils';

interface Props {
  month: string | null;
  year: string | null;
  destination: Option | null;
}

const HouseVillageCapacityCalendar: React.FC<Props> = ({
  month,
  year,
  destination,
}) => {
  const {
    selectedDates,
    setSelectedDates,
    events,
    sections,
    errors,
    startDate,
    endDate,
  } = useCapacityCalendar<null, null>(
    destination,
    month,
    year,
    `/api/admin/calendar/house-bookings/${destination?.value}/`,
    `/api/admin/calendar/house-sections/${destination?.value}/`,
    false
  );

  if (errors.events || errors.sections) {
    return <ErrorOverlay detail={errors.events || errors.sections} />;
  }

  return (
    <div className="border border-gray-300 bg-white rounded-lg">
      <div className="flex flex-wrap justify-between items-center p-4">
        <SelectDates
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          isDisabled={false}
        />
      </div>
      {startDate && endDate && destination && (
        <div className="p-4">
          <Calendar
            isEditing={false}
            startDate={startDate}
            endDate={endDate}
            events={events}
            sections={sections}
            getEventForDay={getEventForDay}
            hideEmptyRows
          />
        </div>
      )}
      {!destination && (
        <div className="px-4 pb-4">Please select a location.</div>
      )}
      {(!startDate || !endDate) && (
        <div className="px-4 pb-4">Please select a year, month and period.</div>
      )}
    </div>
  );
};

export default HouseVillageCapacityCalendar;
