import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const TabbedWidgetSection: React.FC<Props> = ({ children, className }) => {
  return (
    <section
      className={classNames(
        'border pl-3 pr-3 pt-3 pb-4 space-y-6 rounded-lg',
        className
      )}
    >
      {children}
    </section>
  );
};

export default TabbedWidgetSection;
