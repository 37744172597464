import { apiRequest } from '../../../api/api';
import { formatUrlWithParams } from '../../../utils/urls';
import { GetBookingsData, GetSectionsData } from './types';
import {
  transformResponseToEvents,
  transformResponseToSections,
  prepareEventsForSave,
} from './utils';
import { Event } from '../../calendar/types';

export const getEvents = async <T>(
  url: string,
  startDate: Date,
  endDate: Date
) => {
  const formattedUrl = formatUrlWithParams(url, {
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  });
  const response = await apiRequest<GetBookingsData<T>>(formattedUrl);

  return transformResponseToEvents(response);
};

export const getSections = async <T, U>(
  url: string,
  events: ReadonlyArray<Event<T>>,
  addUnallocatedSection = true
) => {
  const response = await apiRequest<GetSectionsData<U>>(url);

  return transformResponseToSections<T, U>(
    response,
    events,
    addUnallocatedSection
  );
};

export const saveEvents = async <T>(
  url: string,
  events: ReadonlyArray<Event<T>> | null
) => {
  if (!events) return;

  const data = prepareEventsForSave(events);
  const response = await apiRequest(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
};
