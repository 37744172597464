import React from 'react';
import { useParams } from 'react-router';

import { useAPI } from '../../../api/api';
import Spinner from '../../spinner';

const UnsubscribeReminder: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isValidating, error } = useAPI(
    `/api/booking/unsubscribe-enquiry-reminder/${id}/`
  );

  const renderData = () => {
    if (isValidating) {
      return <Spinner className="h-6 w-6" />;
    }
    if (error || !data) {
      return <div>There was an error completing your request</div>;
    }
    return <p>You have been successfully unsubscribed</p>;
  };

  return (
    <div className="space-y-4 flex flex-col justify-center p-8">
      <h1 className="text-2xl font-bold">Unsubscribe</h1>
      {renderData()}
    </div>
  );
};

export default UnsubscribeReminder;
