import React from 'react';
import { FormikValues } from 'formik';
import classNames from 'classnames';

import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';
import Row from './Row';

interface Props<T> {
  data: ReadonlyArray<T>;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields: ReadonlyArray<FieldType>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onEditSuccess?: (data: T) => void;
  onDeleteSuccess?: (data: T) => void;
}

const Table = <T extends FormikValues>({
  data,
  tableFields,
  quickFormFields,
  editHandler,
  deleteHandler,
  onEditSuccess,
  onDeleteSuccess,
}: Props<T>) => {
  const numberOfTableFields = tableFields.length - 1;
  return (
    <div className="bg-wtw-gray-2 w-full overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="items-center h-[44px] bg-wtw-table-blue">
          <tr>
            {tableFields.map(({ header, name }, index) => (
              <th
                key={name}
                className={classNames(
                  'text-table-xs-bold text-left min-w-[150px] max-w-[250px] overflow-hidden text-ellipsis break-words py-1 align-middle',
                  { 'pl-3': index === 0 },
                  { 'pr-3': index === numberOfTableFields },
                  { 'pr-6': index !== numberOfTableFields }
                )}
              >
                <div className="w-full overflow-hidden line-clamp-2">
                  {header}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item: T, index: number) => (
            <Row
              key={`${item}-${index}`}
              item={item}
              tableFields={tableFields}
              quickFormFields={quickFormFields}
              editHandler={editHandler}
              deleteHandler={deleteHandler}
              onEditSuccess={onEditSuccess}
              onDeleteSuccess={onDeleteSuccess}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
