import { useEffect } from 'react';

export const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const handleClickEventHandler = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as HTMLDivElement)
      ) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickEventHandler);
    return () => {
      document.removeEventListener('mousedown', handleClickEventHandler);
    };
  }, [ref, callback]);
};
