import React from 'react';
import { useNavigate } from 'react-router';

import Logo from '../Logo';
import Navbar from '../../framework-components/navbar/Navbar';
import PrimaryButton from '../../framework-components/button/Button';
import PageContainer from '../layout/PageContainer';

const Landing: React.FC = () => {
  const paths = {
    login: {
      label: 'Login',
      href: '/login',
    },
    admin: {
      label: 'Admin',
      href: '/admin/enquiries/new',
    },
    resetPassword: {
      label: 'Reset password',
      href: '/forgot-password',
    },
    bookingForm: {
      label: 'Booking form',
      href: '/booking-form',
    },
    enquiryListing: {
      label: 'Enquiry listing',
      href: '/admin/enquiries/new',
    },
  };

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <Navbar
        appLogo={
          <Logo
            width={200}
            height={48}
            className="transform translate-y-[-10px] md:translate-y-[-15px]"
          />
        }
        className="min-h-[120px] flex flex-wrap items-center"
        navigation={[]}
      />
      <PageContainer>
        <h1 className="text-h3">Welcome to Landing Page</h1>
        {Object.entries(paths).map(([key, value]) => (
          <PrimaryButton
            key={key}
            className="mt-4 w-1/3"
            onClick={() => navigate(value.href)}
          >
            {value.label}
          </PrimaryButton>
        ))}
      </PageContainer>
    </div>
  );
};

export default Landing;
