import React from 'react';

import DescriptionList, {
  DescriptionListProps,
} from '../../../framework-components/descriptionList/DescriptionList';
import { PencilEditLink } from '../../../framework-components/button/PencilEditButton';

type PickedDescriptionListProps = Pick<
  DescriptionListProps,
  'items' | 'title' | 'subtitle'
>;

interface Props extends PickedDescriptionListProps {
  enquiryId?: string;
}

const EditableDescriptionList: React.FC<Props> = ({ enquiryId, ...props }) => {
  return (
    <>
      <DescriptionList {...props} />
      <PencilEditLink to={`/booking-form/${enquiryId}`} />
    </>
  );
};

export default EditableDescriptionList;
