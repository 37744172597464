import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './login-form';

const Login: React.FC = () => (
  <div className="space-y-6">
    <LoginForm />
    <p className="text-center">
      <Link
        className="text-blue-600 underline hover:text-blue-900"
        to="/forgot-password"
      >
        Forgot Password?
      </Link>
    </p>
    <p className="text-center">
      <Link
        className="text-blue-600 underline hover:text-blue-900"
        to="/register"
      >
        Register
      </Link>
    </p>
  </div>
);

export default Login;
