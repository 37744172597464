import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => (
  <div className="space-y-6 text-center">
    <h1 className="font-semibold tracking-wide text-indigo-600 uppercase">
      404 - Page not found.
    </h1>
    <p className="text-xl text-gray-500">
      Sorry, the page you are looking for does not exist.
    </p>
    <p className="text-l">
      <Link className="text-blue-600 underline hover:text-blue-900" to="/">
        Back to the app home
      </Link>
    </p>
  </div>
);

export default NotFound;
