import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoggedInUserContext } from '../context-providers/logged-in-user';

const LoggedOutLayout: React.FC = () => {
  const user = useContext(LoggedInUserContext);

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container px-6 py-12 mx-auto bg-white">
      <Outlet />
    </div>
  );
};

export default LoggedOutLayout;
