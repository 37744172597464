import React from 'react';

import PageWrapper from '../booking-form/PageWrapper';
import ReviewBookingForm from './ReviewBookingForm';
import { SECTIONS } from '../booking-form/constants';

const BookingReview: React.FC = () => {
  return (
    <PageWrapper
      title="Booking Form"
      subtitle={
        "Check you've entered your details correctly, before proceeding to the next step."
      }
      section={SECTIONS.REVIEW}
    >
      <ReviewBookingForm />
    </PageWrapper>
  );
};

export default BookingReview;
