import React from 'react';

import { ReviewContactDetailsData } from './types';
import EditableDescriptionList from './EditableDescriptionList';
import { GENDER_TO_DISPLAY_NAME } from '../booking-form/labels';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../utils/dates';

interface Props {
  data: ReviewContactDetailsData;
}

const ReviewContactDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Your contact details"
        items={[
          {
            label: 'Date of birth',
            value: formatDateToLongDateFormatWithOridinalDay(
              data.date_of_birth
            ),
          },
          {
            label: 'Gender',
            value: GENDER_TO_DISPLAY_NAME[data.gender],
          },
          {
            label: 'Nationality',
            value: data.nationality.label,
          },
          {
            label: 'Phone number',
            value: data.phone,
          },
          {
            label: 'Postcode/ZIP',
            value: data.postcode,
          },
          {
            label: 'Address line 1',
            value: data.line1,
          },
          {
            label: 'Address line 2',
            value: data.line2 || '',
          },
          {
            label: 'City',
            value: data.city,
          },
          {
            label: 'County/State',
            value: data.county || '',
          },
          {
            label: 'Country',
            value: data.country.label,
          },
        ]}
      />
    </div>
  );
};

export default ReviewContactDetails;
