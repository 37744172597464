import React from 'react';

const CustomSortIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00004 12C5.00004 12.2652 5.1054 12.5196 5.29293 12.7071C5.48047 12.8946 5.73482 13 6.00004 13C6.26526 13 6.51961 12.8946 6.70715 12.7071C6.89468 12.5196 7.00004 12.2652 7.00004 12V6.414L8.29304 7.707C8.48164 7.88916 8.73425 7.98995 8.99644 7.98767C9.25864 7.9854 9.50945 7.88023 9.69486 7.69482C9.88027 7.50941 9.98544 7.2586 9.98771 6.9964C9.98999 6.7342 9.8892 6.4816 9.70704 6.293L6.70704 3.293C6.51951 3.10553 6.26521 3.00021 6.00004 3.00021C5.73488 3.00021 5.48057 3.10553 5.29304 3.293L2.29304 6.293C2.11088 6.4816 2.01009 6.7342 2.01237 6.9964C2.01465 7.2586 2.11981 7.50941 2.30522 7.69482C2.49063 7.88023 2.74144 7.9854 3.00364 7.98767C3.26584 7.98995 3.51844 7.88916 3.70704 7.707L5.00004 6.414V12ZM15 8C15 7.73478 14.8947 7.48043 14.7071 7.29289C14.5196 7.10536 14.2653 7 14 7C13.7348 7 13.4805 7.10536 13.2929 7.29289C13.1054 7.48043 13 7.73478 13 8V13.586L11.707 12.293C11.5184 12.1108 11.2658 12.01 11.0036 12.0123C10.7414 12.0146 10.4906 12.1198 10.3052 12.3052C10.1198 12.4906 10.0146 12.7414 10.0124 13.0036C10.0101 13.2658 10.1109 13.5184 10.293 13.707L13.293 16.707C13.4806 16.8945 13.7349 16.9998 14 16.9998C14.2652 16.9998 14.5195 16.8945 14.707 16.707L17.707 13.707C17.8892 13.5184 17.99 13.2658 17.9877 13.0036C17.9854 12.7414 17.8803 12.4906 17.6949 12.3052C17.5095 12.1198 17.2586 12.0146 16.9964 12.0123C16.7342 12.01 16.4816 12.1108 16.293 12.293L15 13.586V8Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};

export default CustomSortIcon;
