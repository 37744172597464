import React, { useCallback } from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';
import { apiRequest } from '../../../../api/api';
import Table from '../../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../../framework-components/table/types';
import { Uuid } from '../../../../types';

interface UniversityTableData {
  id: Uuid;
  name: string;
  country: {
    name: string;
  } | null;
  number_of_confirmed: number;
  number_of_alumni: number;
  number_of_enquiries: number;
}

const UniversityDirectories: React.FC = () => {
  const getUniversityTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<UniversityTableData>>(
        '/api/admin/university/list/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback(() => {
    return fetch('/api/admin/university/filters/')
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Universities',
          href: null,
        },
      ]}
      title="Universities"
      enableHome={false}
    >
      <div className="mt-4">
        <Table<UniversityTableData>
          columnsConfig={{
            name: {
              key: 'name',
              header: 'Name',
              enableSort: true,
              renderCell: (university) => (
                <a
                  href={`/admin/directories/universities/${university.id}/`}
                  title={university.name}
                >
                  {university.name}
                </a>
              ),
            },
            country: {
              key: 'country',
              header: 'Name',
              filterType: 'local-dropdown',
              renderCell: (university) => university.country?.name,
            },
            number_of_confirmed: {
              key: 'number_of_confirmed',
              header: 'Confirmed',
              renderCell: (university) => university.number_of_confirmed,
            },
            number_of_alumni: {
              key: 'number_of_alumni',
              header: 'Alumni',
              renderCell: (university) => university.number_of_alumni,
            },
            number_of_enquiries: {
              key: 'number_of_enquiries',
              header: 'Enquiries',
              renderCell: (university) => university.number_of_enquiries,
            },
          }}
          getData={getUniversityTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
        />
      </div>
    </AdminStandardPage>
  );
};

export default UniversityDirectories;
