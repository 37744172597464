import React from 'react';

const ForgotPasswordEmailSent: React.FC = () => (
  <div className="space-y-6 text-center">
    <h1 className="font-semibold tracking-wide text-indigo-600 uppercase">
      Forgot password email sent
    </h1>
    <div className="text-l text-gray-500">
      <p>We've sent an email to the address you provided.</p>
      <p>Click on the link in the email to set a new password.</p>
    </div>
  </div>
);

export default ForgotPasswordEmailSent;
