import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  className?: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  textToCopy,
  className,
}) => {
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success('Copied to Clipboard');
    } catch (err) {
      toast.error('Failed to copy');
    }
  }, [textToCopy]);

  return (
    <button
      className={classNames('hover:ring', className)}
      onClick={copyToClipboard}
    >
      <FontAwesomeIcon icon={faCopy} />
    </button>
  );
};

export default CopyToClipboardButton;
