import React, { useMemo, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { FormikValues } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import Modal from '../modal';
import QuickForm from '../../framework-components/quickform/QuickForm';
import { FieldType } from '../../framework-components/quickform/Field';

interface Props<T> {
  isOpen: boolean;
  closeModal: () => void;
  submitItem: (data: T) => Promise<T>;
  onSubmitSuccess?: (data: T) => void;
  data: T | null;
  fields: ReadonlyArray<FieldType> | Array<FieldType>;
  labels: Record<string, string>;
}

const EditModal = <T extends FormikValues>({
  isOpen,
  closeModal,
  submitItem,
  onSubmitSuccess,
  data,
  fields,
  labels,
}: Props<T>) => {
  const focusRef = React.useRef(null);

  const handleSubmit = useCallback(
    async (values: T) => {
      return submitItem(values);
    },
    [submitItem]
  );

  const updateValues = useCallback(
    (values: T) => {
      if (onSubmitSuccess) {
        onSubmitSuccess(values);
      }
      toast.success('Item updated');
      closeModal();
    },
    [closeModal, onSubmitSuccess]
  );

  const initialValues = useMemo(
    () =>
      data
        ? data
        : Object.keys(labels).reduce((acc, key) => {
            acc[key] = '';
            return acc;
          }, {} as FormikValues),
    [data, labels]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      initialFocus={focusRef}
      maxWidth="w-[1080px]"
    >
      <div className="space-y-4 relative" data-testid="edit-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Edit
        </Dialog.Title>
        <button
          onClick={closeModal}
          className="absolute -top-4 right-0 p-2 rounded-md"
        >
          <FontAwesomeIcon icon={faX} />
        </button>
        <QuickForm
          onSubmit={handleSubmit}
          initialValues={initialValues as T}
          fields={fields}
          onSubmitSuccess={updateValues}
        />
      </div>
    </Modal>
  );
};

export default EditModal;
