import React from 'react';
import { Link } from 'react-router-dom';

import ResetPasswordForm from './reset-password-form';

const ForgotPassword: React.FC = () => (
  <div className="space-y-6">
    <ResetPasswordForm />
    <p className="text-center">
      <Link className="text-blue-600 underline hover:text-blue-900" to="/login">
        Back to login
      </Link>
    </p>
  </div>
);

export default ForgotPassword;
