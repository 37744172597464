import React from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { toast } from 'react-toastify';

import { GetProfileData } from './types';
import { useAPI } from '../../../../api/api';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import CopyToClipboardButton from '../../../CopyClipboardButton';
import ThreeColumns from '../../common/layout/ThreeColumns';
import CardDetail from '../../../../framework-components/card/CardDetail';
import EditModal from '../../../quick-edit-create/EditModal';
import useModalState from '../../../../hooks/modals';
import { Option } from '../../../../types';
import { FieldType } from '../../../../framework-components/quickform/Field';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import { MAX_YEARS_OF_GRADUATION } from '../../../booking/booking-form/constants';

const yearOfGraduationOptions: Array<Option> = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < MAX_YEARS_OF_GRADUATION; i++) {
  yearOfGraduationOptions.push({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString(),
  });
}

export const yearOfStudyOptions: Array<Option> = [
  { value: '1', label: '1st' },
  { value: '2', label: '2nd' },
  { value: '3', label: '3rd' },
  { value: '4', label: '4th' },
  { value: '5', label: '5th' },
  { value: '6', label: '6th' },
  { value: '7', label: '7th' },
];

interface Data {
  name: string;
  reference: string;
  nationality: Option | null;
  mobile: string;
  email: string;
  university: Option;
  university_course: string;
  year_of_university_study?: Option;
  year_of_graduation?: Option;
}

// TODO: swap for a real action to send request to update API endpoint
const submitItem = async (data: Data) => {
  return Promise.resolve(data);
};

const ApplicantContactBox = () => {
  const params = useParams();
  const { bookingId } = params;
  const url = `/api/admin/bookings/${bookingId}/profile-page-contact-info/`;

  const { data, error, isValidating } = useAPI<GetProfileData>(url);
  const { isOpen, openModal, closeModal } = useModalState();

  const applicantName = (function () {
    if (!data) {
      return '';
    }

    return `${data.first_name} ${data.last_name}`;
  })();

  const mobileCopyText = CopyToClipboardButton({
    textToCopy: data ? data.phone : '',
  });
  const mobileEmailText = CopyToClipboardButton({
    textToCopy: data ? data.email : '',
  });

  if (!data && (isValidating || error)) {
    return (
      <div className="p-4 space-y-8">
        <>
          {isValidating && <LoadingOverlay />}
          {error && (
            <ErrorOverlay
              detail={error ? 'Failed to load page data' : undefined}
            />
          )}
        </>
      </div>
    );
  } else if (!data) {
    return null;
  }

  const onSubmitSuccessHandler = () => {
    mutate(url);
    toast.success('Item updated');
  };

  const fields: Array<FieldType> = [
    {
      type: 'smalltext',
      name: 'name',
      label: 'Name',
    },
    {
      type: 'smalltext',
      name: 'reference',
      label: 'Reference',
    },
    {
      type: 'remote-dropdown',
      name: 'nationality',
      label: 'Nationality',
      path: '/api/booking/nationalities/',
    },
    {
      type: 'smalltext',
      name: 'mobile',
      label: 'Mobile',
    },
    {
      type: 'smalltext',
      name: 'email',
      label: 'Email',
    },
    {
      type: 'remote-dropdown',
      name: 'university',
      label: 'University',
      path: '/api/booking/universities/',
    },
    {
      type: 'smalltext',
      name: 'university_course',
      label: 'Course',
    },
  ];

  const labels: Record<string, string> = {
    name: 'Name',
    reference: 'Reference',
    nationality: 'Nationality',
    mobile: 'Mobile',
    email: 'Email',
    university: 'University',
    university_course: 'University course',
  };

  const initialValues: Data = {
    name: applicantName,
    reference: data.reference,
    nationality: data.nationality
      ? { label: data.nationality.name, value: data.nationality.id }
      : null,
    mobile: data.phone,
    email: data.email,
    university: { label: data.university.name, value: data.university.id },
    university_course: data.university_course,
  };

  const universityData: Record<string, string> = {
    University: data.university.name,
    Course: data.university_course,
  };
  if (data.year_of_university_study) {
    universityData['Year of Study'] = data.year_of_university_study.toString();
    initialValues.year_of_university_study = yearOfStudyOptions.find(
      (options) => options.value === data.year_of_university_study.toString()
    );
    fields.push({
      type: 'local-dropdown',
      name: 'year_of_university_study',
      label: 'Year of Study',
      options: yearOfStudyOptions,
    });
    labels.year_of_university_study = 'Year of study';
  }
  if (data.year_of_graduation) {
    universityData['Year of Graduation'] = data.year_of_graduation.toString();
    initialValues.year_of_graduation = yearOfStudyOptions.find(
      (options) => options.value === data.year_of_graduation.toString()
    );
    fields.push({
      type: 'local-dropdown',
      name: 'year_of_graduation',
      label: 'Year of Graduation',
      options: yearOfGraduationOptions,
    });
    labels.year_of_graduation = 'Year of graduation';
  }

  return (
    <div className="p-4 space-y-4">
      <ThreeColumns
        leftColumn={
          <CardDetail
            data={{
              Name: applicantName,
              Reference: data.reference,
              Status: 'Confirmed',
            }}
            imageSource="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            imageAlt="Profile"
            labelWidth="w-24"
          />
        }
        middleColumn={
          <CardDetail
            data={{
              Nationality: data.nationality ? data.nationality.name : '',
              Mobile: (
                <>
                  {data.phone} {mobileCopyText}
                </>
              ),
              Email: (
                <>
                  {data.email} {mobileEmailText}
                </>
              ),
            }}
            labelWidth="w-24"
          />
        }
        rightColumn={<CardDetail data={universityData} labelWidth="w-24" />}
      />
      <div className="w-full flex justify-end">
        <PencilEditButton onClick={openModal} />
      </div>
      <EditModal<Data>
        isOpen={isOpen}
        closeModal={closeModal}
        submitItem={submitItem}
        onSubmitSuccess={onSubmitSuccessHandler}
        data={initialValues}
        fields={fields}
        labels={labels}
      />
    </div>
  );
};

export default ApplicantContactBox;
