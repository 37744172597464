import React from 'react';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';

interface House {
  id: string;
  name: string;
}

const Houses: React.FC = () => {
  const { data, isValidating, error } = useAPI<ReadonlyArray<House>>(
    '/api/admin/house/list/'
  );

  return (
    <div className="relative">
      <AdminStandardPage
        breadcrumbs={[
          {
            label: 'Directories',
            href: null,
          },
          {
            label: 'Houses',
            href: null,
          },
        ]}
        title="Houses"
        enableHome={false}
      >
        {isValidating && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        {data && (
          <div>
            {data.map((house) => (
              <div key={house.id}>
                <a href={`/admin/directories/houses/${house.id}`}>
                  {house.name}
                </a>
              </div>
            ))}
          </div>
        )}
      </AdminStandardPage>
    </div>
  );
};

export default Houses;
