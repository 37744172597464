import React, { useCallback } from 'react';

import Input from '../../framework-components/input/Input';

const Inputs = () => {
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event); // eslint-disable-line no-console
  }, []);
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Inputs</h2>

      <div className="w-full max-w-xs">
        <div className="mb-4">
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            placeholder="you@example.com"
            errors={[
              'It must be a @dabapps.com email',
              'It must be at least 10 characters long.',
            ]}
            onChange={onChange}
          />
        </div>

        <div className="mb-4">
          <Input
            label="Password"
            id="password"
            name="password"
            type="password"
            placeholder="Your password"
            onChange={onChange}
          />
        </div>

        <div className="mb-4">
          <Input
            label="Telephone"
            id="telephone"
            name="telephone"
            type="tel"
            placeholder="123-456-7890"
            onChange={onChange}
          />
        </div>

        <div className="mb-4">
          <Input
            label="Disabled"
            name="disabled"
            disabled
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Inputs;
