import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

type LabelDirection = 'left' | 'right';

export type ToggleSize = 'sm' | 'md' | 'lg';

interface Props {
  label?: string;
  enabled?: boolean;
  onChange?: (checked: boolean) => void;
  labelDirection?: LabelDirection;
  className?: string;
  size?: ToggleSize;
}

const Toggle: React.FC<Props> = ({
  label,
  labelDirection = 'right',
  enabled,
  onChange,
  className,
  size = 'md',
}) => {
  const renderSwitchLabel = () => {
    if (!label) return null;

    return (
      <Switch.Label
        as="span"
        className={classNames(
          { 'ml-3': labelDirection === 'right' },
          { 'mr-3': labelDirection === 'left' }
        )}
      >
        {label}
      </Switch.Label>
    );
  };

  return (
    <Switch.Group
      as="div"
      className={classNames('flex items-center', className)}
    >
      {labelDirection === 'left' && renderSwitchLabel()}

      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          size === 'sm' && 'w-[48px] h-[28px]',
          size === 'md' && 'w-11 h-6',
          size === 'lg' && 'w-16 h-9',
          'relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            size === 'sm' && 'w-[24px] h-[24px]',
            size === 'md' && 'h-5 w-5',
            size === 'lg' && 'h-8 w-8',
            'pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>

      {labelDirection === 'right' && renderSwitchLabel()}
    </Switch.Group>
  );
};

export default Toggle;
