import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ExpandableRow from '../../framework-components/expandable-row/ExpandableRow';
import QuickFormExample from './QuickFormExample';

const ExpandableRowExample: React.FC = () => {
  const onSaveClickPromise = () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        toast.success('Saved!');
        resolve();
      }, 500);
    });

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Expandable Row</h2>
      <ExpandableRow
        title="Outbound"
        titleIcon={<FontAwesomeIcon icon={faPlane} />}
        bodyWhenCollapsed={() => <div>05 Apr 2024 - 23:30 KL0567</div>}
        bodyWhenExpanded={() => (
          <div>
            <QuickFormExample />
          </div>
        )}
        onSaveClick={onSaveClickPromise}
      />
    </div>
  );
};

export default ExpandableRowExample;
