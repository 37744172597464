import { TAG_DEFAULT_COLOR_CLASS } from './constants';

export enum NoteTagOption {
  SALES = 'SALES',
  MARKETING = 'MARKETING',
  ON_PLACEMENT = 'ON_PLACEMENT',
  ADMIN = 'ADMIN',
  VISA = 'VISA',
  SYSTEM = 'SYSTEM',
  MONITORING = 'MONITORING',
  INCIDENTS = 'INCIDENTS',
  FLIGHTS = 'FLIGHTS',
  CLINICAL = 'CLINICAL',
}

export const NoteTagOptionToReadableTextMap: Record<NoteTagOption, string> = {
  [NoteTagOption.SALES]: 'Sales',
  [NoteTagOption.MARKETING]: 'Marketing',
  [NoteTagOption.ON_PLACEMENT]: 'On placement',
  [NoteTagOption.ADMIN]: 'Admin',
  [NoteTagOption.VISA]: 'Visa',
  [NoteTagOption.SYSTEM]: 'System',
  [NoteTagOption.MONITORING]: 'Monitoring',
  [NoteTagOption.INCIDENTS]: 'Incidents',
  [NoteTagOption.FLIGHTS]: 'Flights',
  [NoteTagOption.CLINICAL]: 'Clinical',
};

export const NoteTagToColorMap = {
  [NoteTagOption.SALES]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.MARKETING]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.ON_PLACEMENT]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.ADMIN]: 'bg-green-400',
  [NoteTagOption.VISA]: 'bg-indigo-500',
  [NoteTagOption.SYSTEM]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.MONITORING]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.INCIDENTS]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.FLIGHTS]: TAG_DEFAULT_COLOR_CLASS,
  [NoteTagOption.CLINICAL]: TAG_DEFAULT_COLOR_CLASS,
};

export enum NoteEntityType {
  APPLICANT = 'applicant',
  BOOKING = 'booking',
}
