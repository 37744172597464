import React from 'react';

import { AddonDetailsRequestData } from './types';
import TabbedWidgetSection from '../../../../framework-components/tabbedContainer/TabbedWidgetSection';
import Table from '../../../../framework-components/table/Table';

interface Props {
  bookingId?: string;
}

const AddonDetailsSection: React.FC<Props> = ({ bookingId }) => {
  const getAddonDetails = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-addon-details/`
    );

    return request.then((response) => response.json());
  };

  return (
    <TabbedWidgetSection className="bg-wtw-gray-2">
      <h3 className="text-h5">Add-Ons</h3>
      <Table<AddonDetailsRequestData>
        columnsConfig={{
          name: {
            key: 'name',
          },
          details: {
            key: 'details',
          },
        }}
        getData={getAddonDetails}
        compact
      />
    </TabbedWidgetSection>
  );
};

export default AddonDetailsSection;
