import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  title?: string;
}

const TableCell: React.FC<Props> = ({ children, className, title }) => {
  return (
    <td
      className={classNames(
        'p-[6.6px] text-sm text-black text-wrap break-words',
        className
      )}
      title={title}
    >
      {children}
    </td>
  );
};

export default TableCell;
