import React from 'react';

type FullWidthTableCellProps = {
  colSpan: number;
  content: string | React.JSX.Element;
};

const FullWidthTableCell = ({ colSpan, content }: FullWidthTableCellProps) => {
  return (
    <tr className="text-sm font-medium text-gray-900">
      <td colSpan={colSpan}>
        <span className="py-4 pl-4 pr-3 flex items-center justify-center">
          {content}
        </span>
      </td>
    </tr>
  );
};

export default FullWidthTableCell;
