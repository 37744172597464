import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import FileUpload from '../../../../framework-components/fileUpload/FileUpload';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onSuccessfulUpload: (file_id: string) => void;
}

const UploadImageModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onSuccessfulUpload,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      initialFocus={focusRef}
      maxWidth="w-[1080px]"
    >
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Upload Image
        </Dialog.Title>
        <FileUpload
          accepted_extensions={['png', 'jpg', 'webp']}
          max_file_size={5}
          onSuccessfulUpload={onSuccessfulUpload}
        />
      </div>
    </Modal>
  );
};

export default UploadImageModal;
