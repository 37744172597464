import React, { useState, useEffect, useMemo } from 'react';
import { FormikValues } from 'formik';

import { Option } from '../../types';
import useModalState from '../../hooks/modals';
import EditModal from './EditModal';
import { FieldType } from '../../framework-components/quickform/Field';
import ErrorOverlay from '../admin/common/error/ErrorOverlay';
import LoadingOverlay from '../../framework-components/loading/LoadingOverlay';
import Card from '../../framework-components/card/Card';
import { PencilEditButton } from '../../framework-components/button/PencilEditButton';

interface Props<T> {
  title: string;
  getUrl: string | null;
  submitItem: (data: T) => Promise<T>;
  onSubmitSuccess?: () => void;
  labels: Record<keyof T, string>;
  fields: ReadonlyArray<FieldType>;
  simple?: boolean;
}

const Item: React.FC<
  Option | Option<Option> | Option<boolean> | Option<number> | Option<null>
> = ({ label, value }) => {
  return (
    <div key={label} className="flex gap-6 min-h-[44px] items-center py-1">
      <dt className="text-xs-bold w-1/2">{label}</dt>
      <dd className="text-gray-900 text-xs">
        {typeof value === 'object' ? value?.value : value.toString()}
      </dd>
    </div>
  );
};

const QuickEditCreate = <T extends FormikValues>({
  title,
  getUrl,
  submitItem,
  onSubmitSuccess,
  fields,
  labels,
  simple,
}: Props<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isOpen, openModal, closeModal } = useModalState();

  useEffect(() => {
    const fetchData = async () => {
      if (!getUrl) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await fetch(getUrl);
        const responseData = (await response.json()) as T;
        setData(responseData);
      } catch {
        setError('Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [getUrl]);

  const items = useMemo(
    () =>
      Object.keys(labels).reduce(
        (
          acc: Array<Option<string | number | boolean | Option | null>>,
          key
        ) => {
          acc.push({ label: labels[key], value: data ? data[key] : '' });
          return acc;
        },
        []
      ),
    [labels, data]
  );

  const onSubmitSuccessHandler = (values: T) => {
    setData(values);
    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <Card hasBorder hasShadow>
      <div className="space-y-4 gap-6 relative w-full h-fit py-2">
        {error && <ErrorOverlay detail={error} />}
        {isLoading && <LoadingOverlay />}
        <h4 className="text-h4">{title}</h4>
        {simple ? (
          <div className="text-md">
            {items.length > 0 && typeof items[0].value === 'object'
              ? items[0].value?.value
              : items[0].value?.toString()}
          </div>
        ) : (
          <dl className="px-3 py-1 bg-wtw-gray-2 w-full rounded-lg">
            {items.map((item) => (
              <Item key={item.label} label={item.label} value={item.value} />
            ))}
          </dl>
        )}
        <PencilEditButton onClick={openModal} />
        <EditModal<T>
          isOpen={isOpen}
          closeModal={closeModal}
          submitItem={submitItem}
          onSubmitSuccess={onSubmitSuccessHandler}
          data={data}
          fields={fields}
          labels={labels}
        />
      </div>
    </Card>
  );
};

export default QuickEditCreate;
