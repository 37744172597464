import classNames from 'classnames';
import React from 'react';

interface Props {
  children?: string | string[];
  className?: string;
}

const RedText: React.FC<Props> = ({ className, children }) => (
  <p className={classNames('text-red-500', className)}>{children}</p>
);

const ErrorText: React.FC<Props> = ({ className, children }) => {
  if (typeof children === 'string') {
    return <RedText className={className}>{children}</RedText>;
  }
  return (
    <ul>
      {children &&
        children.map((child) => {
          return (
            <li key={child}>
              <RedText className={className}>{child}</RedText>
            </li>
          );
        })}
    </ul>
  );
};

export default ErrorText;
