import React from 'react';

import {
  DangerButton,
  PlainButton,
  RoundedSmallButtonOutline,
  RoundedSmallButtonPrimary,
  SecondaryButton,
  StyleGuideEditablePrimaryButton,
} from '../../framework-components/button/Button';

class ExampleButtons extends React.Component {
  render() {
    return (
      <div className="mt-10 mb-10 space-y-6">
        <h3 className="text-2xl font-bold">Buttons</h3>

        <div className="flex flex-wrap">
          <PlainButton label="Plain" className="mr-2" />
          <PlainButton label="Loading" className="mr-2" isLoading />
          <PlainButton label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <StyleGuideEditablePrimaryButton label="Confirm" />
        </div>

        <div className="flex flex-wrap">
          <SecondaryButton label="Cancel" className="mr-2" />
          <SecondaryButton label="Loading" className="mr-2" isLoading />
          <SecondaryButton label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <DangerButton label="Delete" className="mr-2" />
          <DangerButton label="Loading" className="mr-2" isLoading />
          <DangerButton label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <RoundedSmallButtonPrimary label="Small" className="mr-2" />
          <RoundedSmallButtonPrimary
            label="Loading"
            className="mr-2"
            isLoading
          />
          <RoundedSmallButtonPrimary label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <RoundedSmallButtonOutline label="Small" className="mr-2" />
          <RoundedSmallButtonOutline
            label="Loading"
            className="mr-2"
            isLoading
          />
          <RoundedSmallButtonOutline label="Disabled" isDisabled />
        </div>
      </div>
    );
  }
}
export default ExampleButtons;
