import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import Table from '../../../../framework-components/table/Table';
import { FlightDepartureDetailsRequestData } from './types';
import TextArea from '../../../../framework-components/textarea/TextArea';
import { RoundedSmallButtonOutline } from '../../../../framework-components/button/Button';

interface Props {
  bookingId?: string;
  hasConflict?: boolean;
}

const FlightDepartureTable: React.FC<Props> = ({ bookingId, hasConflict }) => {
  const [flightConflictResolutionNotes, setFlightConflictResolutionNotes] =
    useState('');

  // TODO: Remove this line when flight information has been implemented
  console.log(flightConflictResolutionNotes); // eslint-disable-line no-console

  const getFlightDepartureDetails = () => {
    const request = fetch(
      `/api/admin/bookings/${bookingId}/get-flight-departure-details/`
    );

    return request.then((response) => response.json());
  };

  return (
    <div className="space-y-6">
      <Table<FlightDepartureDetailsRequestData>
        columnsConfig={{
          departure: {
            key: 'departure',
          },
          conflict: {
            key: 'conflict',
            renderCell: (request) => (
              <FontAwesomeIcon
                icon={faPlane}
                className={classNames({ 'text-red-500': request.conflict })}
              />
            ),
          },
        }}
        getData={getFlightDepartureDetails}
        compact
      />

      {hasConflict && (
        <div className="flex flex-wrap items-center space-x-2">
          <TextArea
            name="conflict_details"
            placeholder="Enter conflict resolution notes here..."
            containerClassName="w-full lg:w-2/3"
            onChange={(e) => setFlightConflictResolutionNotes(e.target.value)}
          />
          <RoundedSmallButtonOutline className="mt-4">
            Mark as resolved
          </RoundedSmallButtonOutline>
        </div>
      )}
    </div>
  );
};

export default FlightDepartureTable;
